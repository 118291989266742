import React, { useState, useEffect } from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { withStyles } from "@material-ui/styles"

import Card from "@material-ui/core/Card"
import Select from "@material-ui/core/Select"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import MenuItem from "@material-ui/core/MenuItem"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import FormControl from "@material-ui/core/FormControl"
import NativeSelect from "@material-ui/core/NativeSelect"
import InputLabel from "@material-ui/core/InputLabel"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import SvgIcon from "@material-ui/core/SvgIcon"
import { Link } from "gatsby"
import { XCircle as RemoveCircleIcon } from "react-feather"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"

const MessagingPreferences = ({ classes }) => {
  //   useEffect(() => {
  //     updateItemPrice(id, itemPrice)
  //   }, [itemPrice])

  //   if (loading) return <Loading />
  //   if (error) return <Error />

  return (
    <>
      <Box pb={1}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={2}>
            <Typography className={classes.title} variant="body1">
              Messaging
            </Typography>
          </Grid>
          <Grid item xs={8} sm={6}>
            {/* <Box mb={2}> */}

            <Typography variant="body2">Order Updates</Typography>
            {/* </Box> */}
          </Grid>

          <Grid item container xs={4} sm={4}>
            <Grid item xs={5}>
              check
            </Grid>
            <Grid item xs={1} sm={2}></Grid>
            <Grid item xs={6} sm={5}>
              check
            </Grid>
          </Grid>
          <Grid item xs="false" sm={2}></Grid>
          <Grid item xs={8} sm={6}>
            {/* <Box mb={2}> */}
            <Typography variant="body2">Account and Billing</Typography>
            {/* </Box> */}
          </Grid>

          <Grid item container xs={4} sm={4}>
            <Grid item xs={5}>
              check
            </Grid>
            <Grid item xs={1} sm={2}></Grid>
            <Grid item xs={6} sm={5}>
              check
            </Grid>
          </Grid>
          <Grid item xs="false" sm={2}></Grid>
          <Grid item xs={8} sm={6}>
            {/* <Box mb={2}> */}
            <Typography variant="body2">New products and offers</Typography>
            {/* </Box> */}
          </Grid>

          <Grid item container xs={4} sm={4}>
            <Grid item xs={5}>
              check
            </Grid>
            <Grid item xs={1} sm={2}></Grid>
            <Grid item xs={6} sm={5}>
              check
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
    boxShadow: "none",
  },
  title: {
    fontWeight: "bold",
  },
  selected: {
    display: "none",
  },
})
export default withStyles(styles)(MessagingPreferences)
