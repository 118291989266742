import React, { useState, useEffect } from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { withStyles } from "@material-ui/styles"

import Card from "@material-ui/core/Card"
import Select from "@material-ui/core/Select"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import MenuItem from "@material-ui/core/MenuItem"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import FormControl from "@material-ui/core/FormControl"
import NativeSelect from "@material-ui/core/NativeSelect"
import InputLabel from "@material-ui/core/InputLabel"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import SvgIcon from "@material-ui/core/SvgIcon"
import { Link } from "gatsby"
import { XCircle as RemoveCircleIcon } from "react-feather"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"

const EmailPreferences = ({ classes }) => {
  //   useEffect(() => {
  //     updateItemPrice(id, itemPrice)
  //   }, [itemPrice])

  //   if (loading) return <Loading />
  //   if (error) return <Error />

  return (
    <>
      <Box pb={1}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={2} sm={2}>
            <Typography variant="body1">Email</Typography>
          </Grid>
          <Grid item xs={10} sm={4}>
            <Box mb={2}>
              {/* <Link
                style={{ textDecoration: "none" }}
                // className={classes.name}
                variant="subtitle2"
                color="textPrimary"
                to={`/my/items/${id}`}
                state={{ id: { id } }}
              > */}
              <Typography>item name link</Typography>
              {/* </Link> */}
              <Typography>category</Typography>

              {/* {isPrivate && <Typography>Custom Private Item</Typography>} */}
            </Box>
          </Grid>

          <Grid item container xs={12} sm={5}>
            <Grid item xs={5}>
              <FormControl>
                <TextField
                  id="qty"
                  fullWidth
                  select
                  label="Qty"
                  //   value={quantity}
                  //   onChange={handleUpdate}
                  variant="outlined"
                  SelectProps={{ native: true, padding: 1 }}
                  InputLabelProps={{ shrink: true }}
                >
                  <option className={classes.selected} selected>
                    qty
                  </option>
                  {/* 
                  <option key={ps.id} value={ps.id}>
                    price
                  </option> */}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={2}></Grid>
            <Grid item xs={6} sm={5}>
              price
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider />
    </>
  )
}

const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
    boxShadow: "none",
  },
  selected: {
    display: "none",
  },
})
export default withStyles(styles)(EmailPreferences)
