import React, { useState } from "react"
import { Box, Card, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import moment from "moment"

const OrderPaymentBox = ({ classes, orderDetails }) => {
  const payments = orderDetails.orderPayment
  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }
  if (!payments)
    return (
      <Card square className={classes.paper}>
        <Typography>No payments found</Typography>
      </Card>
    )
  return (
    <Card square className={classes.paper}>
      <Typography variant="body1" className={classes.title}>
        Payment
      </Typography>
      {payments.map(pmt => {
        const paymentDate = pmt.paymentDate
          ? moment(pmt.paymentDate).format("MMM Do, YYYY")
          : ""
        const pmtAmt = pmt.totalPaid ? financial(pmt.totalPaid) : ""
        const paidByName = pmt.paidBy
          ? pmt.paidBy.firstName + " " + pmt.paidBy.lastName
          : ""
        const address =
          pmt.billingAddress.address && pmt.billingAddress.address
            ? pmt.billingAddress.address
            : null
        return (
          <Box ml={1} mt={2} key={pmt.id}>
            <Typography variant="body1">Date: {paymentDate}</Typography>
            <Typography variant="body1">
              {pmt.totalPaid ? `Amt: $${pmtAmt}` : ""}{" "}
              {pmt.method ? `(${pmt.method.name})` : ""}
            </Typography>
            <Typography gutterBottom variant="body1">
              Paid By: {paidByName}
            </Typography>

            <Box ml={1} mt={2}>
              <Typography gutterBottom variant="body1">
                Billing Address:
              </Typography>
              <Typography variant="body1">
                {address && address.addressLine1 ? address.addressLine1 : ""}
              </Typography>
              {address && address.addressLine2 && (
                <Typography variant="body1">
                  {address && address.addressLine2 ? address.addressLine2 : ""}
                </Typography>
              )}
              {address && address.addressLine3 && (
                <Typography variant="body1">
                  {address && address.addressLine3 ? address.addressLine3 : ""}
                </Typography>
              )}
              <Typography variant="body1">
                {address && address.city ? address.city : ""}
              </Typography>
              <Typography variant="body1">
                {address && address.zone ? address.zone : ""}
              </Typography>
              <Typography variant="body1">
                {address && address.postalCode ? address.postalCode : ""}
              </Typography>
              {address && address.region && (
                <Typography variant="body1">
                  {address && address.region ? address.region.name : ""}
                </Typography>
              )}
            </Box>
          </Box>
        )
      })}
      {/* {JSON.stringify(payments)} */}
    </Card>
  )
}

const styles = theme => ({
  root: {},
  title: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(18),
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
})
export default withStyles(styles)(OrderPaymentBox)
