import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
  colors,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { Rating } from "@material-ui/lab"
import FavoriteIcon from "@material-ui/icons/Favorite"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import { Users as UsersIcon } from "react-feather"
import getInitials from "../../utils/getInitials"
import StarsIcon from "@material-ui/icons/Stars"
import PageviewIcon from "@material-ui/icons/Pageview"

const UserOrderItemCard = ({ oi, classes, className, ...rest }) => {
  const [isLiked, setLiked] = useState(true)
  const [likes, setLikes] = useState(1)

  const handleLike = () => {
    setLiked(true)
    setLikes(prevLikes => prevLikes + 1)
  }

  const handleUnlike = () => {
    setLiked(false)
    setLikes(prevLikes => prevLikes - 1)
  }
  const itemId = parseInt(oi.item.id)
  const featuredImageThumbnail = oi.item.featuredImageThumbnailUrl
  const featuredImageCarousel = oi.item.featuredImageCarouselImageUrl
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box p={3}>
        <CardActionArea
          component={Link}
          to={`./${itemId}`}
          state={{ id: itemId }}
        >
          <CardMedia className={classes.media} image={featuredImageCarousel} />
        </CardActionArea>
        <Box display="flex" alignItems="center" mt={2}>
          {/* <Avatar alt="Author" src={project.author.avatar}> */}
          <Avatar
            variant="square"
            alt={oi.item.category.name}
            className={classes.square}
          >
            {getInitials(oi.item.category.name)}
          </Avatar>
          <Box ml={2}>
            <Link
              to={`./${itemId}`}
              state={{ id: itemId }}
              className={classes.link}
            >
              <Typography variant="h6" className={classes.buttonText}>
                {oi.item.name}
              </Typography>{" "}
            </Link>
            <Typography variant="body2" color="textSecondary">
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className={classes.buttonText}
              >
                {oi.item.category.name}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box pb={2} px={3}>
        <Typography color="textSecondary" variant="body2">
          {/* {sampleProof.status && statusNumbers.includes(sampleProof.status.id)
            ? sampleProof.status.name
            : undefined} */}{" "}
          {/* something here */}
        </Typography>
      </Box>
      <Box py={2} px={3}>
        <Grid alignItems="center" container justify="space-between" spacing={3}>
          <Grid item>
            <Typography variant="body1" color="textPrimary">
              {oi.item.isActive
                ? "Available to order online"
                : "Inactive item. Message us for information."}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {oi.item.pricingModel && oi.item.pricingModel.name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textPrimary" align="right">
              {oi.item.id}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="right">
              Item Number
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      {/* <Box py={2} pl={2} pr={3} display="flex" alignItems="center">
        {itemImage.featured && (
          <Tooltip title="Featured Image">
            <StarsIcon />
          </Tooltip>
        )}

        <Box flexGrow={1} />
        {itemImage.proof && (
          <Tooltip title="Sample Proof">
            <PageviewIcon />
          </Tooltip>
        )}
      </Box> */}
    </Card>
  )
}

UserOrderItemCard.propTypes = {
  className: PropTypes.string,
}

const styles = theme => ({
  root: {},
  media: {
    height: 200,
    backgroundColor: theme.palette.background.dark,
  },
  likedButton: {
    color: colors.red[600],
  },
  subscribersIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  square: {
    // color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: theme.palette.secondary.light,
  },
})

export default withStyles(styles)(UserOrderItemCard)
