import React, { useContext } from "react"
import CommentsContext from "../context"
import CommentCard from "./CommentCard"
import withStyles from "@material-ui/core/styles/withStyles"
import Typography from "@material-ui/core/Typography"

const CommentList = ({ classes }) => {
  const { state, dispatch } = useContext(CommentsContext)

  return (
    <div className={classes.root}>
      <Typography>
        {state.comments.length === 1
          ? `${state.comments.length} Comment`
          : `${state.comments.length} Comments`}
      </Typography>
      <ul>
        {state.comments.map(comment => (
          <div className={classes.card} key={comment.id}>
            <CommentCard comment={comment} />
          </div>
        ))}
      </ul>
    </div>
  )
}

const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(1, 0, 0),
  },
  card: {
    margin: theme.spacing(1, 0, 0),
  },
})

export default withStyles(styles)(CommentList)
