import React, { useState, useEffect, useContext } from "react"
import { useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { useMatch } from "@reach/router"

import Loading from "../../Shared/Loading"
import Error from "../../Shared/Error"

import UserOrderList from "./UserOrderList"
import UserPaymentList from "./UserPaymentList"
import UserShippingList from "./UserShippingList"

import SortContext from "../../../context/SortContext"

import SignBackIn from "../../SignBackIn"
// import Layout from "../../Layout/layout"
import Header from "./Header"
import { useSiteMetadata } from "../../utils/hooks/useSiteMetadata"

const UserOrders = ({ classes }) => {
  const { state, dispatch } = useContext(SortContext)
  const page = state.page
  const pageSize = state.pageSize
  const search = state.search
  const [currentTab, setCurrentTab] = useState("orders")
  const [inProgressFilter, setInProgressFilter] = useState(null)
  const { sbuId } = useSiteMetadata()

  useEffect(() => {
    tabValue === "orders"
      ? setCurrentTab("orders")
      : tabValue === "shipping"
      ? setCurrentTab("shipping")
      : tabValue === "payments"
      ? setCurrentTab("payments")
      : setCurrentTab("orders")
  }, [])

  const matchTab = useMatch("/my/:tabValue")
  const tabValue = matchTab.tabValue
  const { data, loading, error } = useQuery(MY_ORDERS_QUERY, {
    variables: {
      page: page,
      pageSize: pageSize,
      search: search,
      inProgressFilter: inProgressFilter,
      businessUnitId: sbuId,
    },
    onError: error => {
      dispatch({ type: "SET_LOGOUT" })
      return <SignBackIn />
    },
    onCompleted: data => {
      console.log(matchTab.tabValue)
    },
  })

  const tabs = [
    { value: "orders", label: "Orders" },
    { value: "payments", label: "Payments" },
    { value: "shipping", label: "Shipping" },
  ]

  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  if (loading) return <Loading />
  if (error) return <Error />

  const myOrders = data.myOrders

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Header />
          <Box mt={2}>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              textColor="secondary"
              className={classes.tabs}
            >
              {tabs.map(tab => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box mt={3}>
            {currentTab === "orders" && (
              <UserOrderList
                myOrders={myOrders}
                setInProgressFilter={setInProgressFilter}
                inProgressFilter={inProgressFilter}
              />
            )}
            {currentTab === "payments" && <UserPaymentList sbuId={sbuId} />}
            {currentTab === "shipping" && <UserShippingList sbuId={sbuId} />}
          </Box>
        </Container>
      </div>
    </>
  )
}

const styles = theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
    margin: 1,
    width: "100%",
  },
  container: {
    display: "grid",
  },
  tabs: {},
})
const MY_ORDERS_QUERY = gql`
  query(
    $page: Int
    $pageSize: Int
    $search: String
    $inProgressFilter: Boolean
    $businessUnitId: Int
  ) {
    myOrders(
      page: $page
      pageSize: $pageSize
      search: $search
      inProgressFilter: $inProgressFilter
      businessUnitId: $businessUnitId
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        status {
          id
          orderStatus
        }
        orderDate
        completionDate
        businessUnit {
          id
          name
        }
        orderWithItem {
          id
          qty
        }
        subtotal
        taxAmt
        discountAmt
        postageAmt
        balanceDue
        balanceDueDate
        orderPaymentTotal
        orderBalanceDue
        notes
        fromCart
        cartId
        inProgress
        orderPayment {
          id
          paidBy {
            uid
            firstName
            lastName
          }
          billingName
        }
      }
    }
  }
`
export default withStyles(styles)(UserOrders)
