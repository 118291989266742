import React, { useState, useContext } from "react"
import gql from "graphql-tag"
import { useMutation, useQuery } from "@apollo/client"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  instructions: {
    margin: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
}))

const ApplePaymentForm = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Card>
        <div className={classes.paper}>
          <Typography component="h6" variant="h5">
            Apple Payment Form Component
          </Typography>
        </div>
      </Card>
    </div>
  )
}

export default ApplePaymentForm
