import React, { useState } from "react"
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardHeader,
  ClickAwayListener,
  Container,
  Divider,
  Form,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  Paper,
  SvgIcon,
  TextField,
  Typography,
} from "@material-ui/core"

import { withStyles } from "@material-ui/styles"

import { Alert } from "@material-ui/lab"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"

import {
  Edit3 as EditIcon,
  Check as CheckIcon,
  Plus as PlusIcon,
  X as RemoveIcon,
} from "react-feather"
import { AirlineSeatLegroomReducedTwoTone } from "@material-ui/icons"
import ContactMailIcon from "@material-ui/icons/ContactMail"

const UserEditUsername = ({ classes, userEmail }) => {
  const [editEmail, setEditEmail] = useState(false)
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState(userEmail)
  const [message, setMessage] = useState("")
  const [success, setSuccess] = useState(false)

  const [updateUsernameEmail, { loading, error }] = useMutation(
    UPDATE_USERNAME_EMAIL,
    {
      variables: {
        newEmail: email,
        password: password,
      },
      onCompleted: data => {
        console.log(data)
        const updatedEmail = data.updateUserEmail.user.email
        const msg = data.updateUserEmail.message
        setEmail(updatedEmail)
        setMessage(msg)
        setSuccess(true)
        setPassword("")
        // setEmail(data.user.email)
        // setMessage(data.message)
        setEditEmail(false)
      },
    }
  )

  const handleSubmit = event => {
    updateUsernameEmail(event)
  }

  const handleSwitch = e => {
    e.preventDefault()
    setEditEmail(!editEmail)
  }
  const handleClickAway = () => {
    setEmail(userEmail)
    setPassword("")
    setEditEmail(false)
  }
  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Card square variant="outlined" className={classes.card}>
      <CardHeader
        avatar={<ContactMailIcon />}
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title="Primary (Account Sign-in) Email"
        // subheader=""
      />

      {editEmail ? (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box mt={2}>
            <form
              id="usernameForm"
              onSubmit={event => {
                event.preventDefault()
                handleSubmit(event)
              }}
              className={classes.form}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Email Address"
                      autoComplete="email"
                      id="email"
                      type="email"
                      variant="outlined"
                      size="small"
                      required
                      //    helperText="(required)"
                      value={email}
                      onChange={event => setEmail(event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Password"
                      id="password"
                      type="password"
                      required
                      variant="outlined"
                      size="small"
                      autoComplete="current-password"
                      //    helperText="(required)"
                      value={password}
                      onChange={event => setPassword(event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      size="small"
                      // className={classes.iconButton}
                      aria-label="confirm email"
                      type="submit"
                      form="usernameForm"
                      disabled={loading || !email.trim() || !password.trim()}
                    >
                      Change
                      {/* <SvgIcon>
                      <CheckIcon />
                    </SvgIcon> */}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <Button
                      fullWidth
                      color="primary"
                      size="small"
                      variant="outlined"
                      aria-label="cancel"
                      onClick={() => setEditEmail(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </ClickAwayListener>
      ) : (
        <>
          <Grid item xs={12}>
            {/* <Box mt={2}> */}
            {/* <Typography gutterBottom>{email}</Typography> */}
            <ButtonBase
              focusRipple
              className={classes.menuButton}
              // focusVisibleClassName={classes.focusVisible}
              style={{
                width: "100%",
                display: "block",
                padding: 10,
              }}
              onClick={handleSwitch}
            >
              <Typography className={classes.buttonText}>{email}</Typography>
            </ButtonBase>
            {success && message && (
              <Alert
                onClose={() => {
                  setSuccess(false)
                }}
              >
                {message}
              </Alert>
            )}
            {/* </Box> */}
          </Grid>
          {/* <Grid item sm={3}>
            <Box align="right">
              <IconButton
                color="primary"
                className={classes.iconButton}
                aria-label="edit email"
                size="small"
                onClick={handleSwitch}
              >
                <SvgIcon>
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            </Box>
          </Grid> */}
        </>
      )}
      <Grid item xs={12}>
        <Typography variant="caption" gutterBottom>
          ** Password and email verification required (2 steps)
        </Typography>
      </Grid>
    </Card>
  )
}
const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
    boxShadow: "none",
  },
  buttonText: {
    textAlign: "left",
  },
  card: {
    width: "100%",
    padding: theme.spacing(1),
  },
  menuButton: {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.action.hoverLight,

      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  editBox: {
    width: "100%",
  },
  form: {
    display: "flex",
    width: "100%",
  },
  field: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    // width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 5,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  disabled: {
    color: theme.palette.common.black,
    opacity: 1,
  },
  paper: {
    padding: theme.spacing(1),
    minHeight: 220,
  },
  title: {
    // fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

const UPDATE_USERNAME_EMAIL = gql`
  mutation($newEmail: String!, $password: String!) {
    updateUserEmail(newEmail: $newEmail, password: $password) {
      message
      user {
        email
      }
    }
  }
`
export default withStyles(styles)(UserEditUsername)
