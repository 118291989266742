import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { withStyles } from "@material-ui/styles"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"

const CommunicationPreferences = ({ classes, myProfile }) => {
  const prefs = myProfile.servicePreference
  const phoneNumbers = myProfile.person.phonePerson
  const emailAddresses = myProfile.person.emailPerson
  const prefEmail = prefs.emailPref ? prefs.emailPref.id : emailAddresses[0]
  const prefPhone = prefs.phonePref ? prefs.phonePref.id : phoneNumbers[0]
  const prefSms = prefs.smsPref ? prefs.smsPref.id : phoneNumbers[0]
  const frequency = parseInt(prefs.promoFrequency.slice(2, 3))
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [contactEmailBool, setContactEmailBool] = useState(prefs.emailOptIn)
  const [contactPushBool, setContactPushBool] = useState(prefs.pushOptIn)
  const [contactTextBool, setContactTextBool] = useState(prefs.smsOptIn)
  const [contactPhoneBool, setContactPhoneBool] = useState(prefs.phoneOptIn)
  const [contactEmail, setContactEmail] = useState(prefEmail)
  const [contactText, setContactText] = useState(prefSms)
  const [contactPhone, setContactPhone] = useState(prefPhone)
  const [emailId, setEmailId] = useState(prefEmail)
  const [phoneId, setPhoneId] = useState(prefPhone)
  const [textId, setTextId] = useState(prefSms)
  const [doNotContact, setDoNotContact] = useState(prefs.doNotContact)

  const [serviceEmailBool, setServiceEmailBool] = useState(
    prefs.serviceEmailOptIn
  )
  const [serviceTextBool, setServiceTextBool] = useState(prefs.serviceSmsOptIn)
  const [servicePhoneBool, setServicePhoneBool] = useState(
    prefs.servicePhoneOptIn
  )
  const [servicePushBool, setServicePushBool] = useState(prefs.servicePushOptIn)

  const [promoFrequency, setPromoFrequency] = useState(frequency)
  const [promoEmailBool, setPromoEmailBool] = useState(prefs.promoEmailOptIn)
  const [promoTextBool, setPromoTextBool] = useState(prefs.promoSmsOptIn)
  const [promoPhoneBool, setPromoPhoneBool] = useState(prefs.promoPhoneOptIn)
  const [promoPushBool, setPromoPushBool] = useState(prefs.promoPushOptIn)

  const [handleSubmit, { loading, error }] = useMutation(
    UPDATE_PREFERENCES_MUTATION,
    {
      variables: {
        id: prefs.id,
        emailOptIn: contactEmailBool,
        emailPref: emailId,
        smsOptIn: contactTextBool,
        smsPref: textId,
        phoneOptIn: contactPhoneBool,
        phonePref: phoneId,
        pushOptIn: contactPushBool,
        doNotContact: doNotContact,
        serviceSmsOptIn: serviceTextBool,
        serviceEmailOptIn: serviceEmailBool,
        servicePhoneOptIn: servicePhoneBool,
        servicePushOptIn: servicePushBool,
        promoSmsOptIn: promoTextBool,
        promoPushOptIn: promoPushBool,
        promoPhoneOptIn: promoPhoneBool,
        promoEmailOptIn: promoEmailBool,
        promoFrequency: promoFrequency,
      },
      onCompleted: data => {
        let newPref = data.updateServicePreference.servicePreference
        let newFrequency = parseInt(newPref.promoFrequency.slice(2, 3))
        setShowSuccessMessage(true)
        setSuccessMessage(data.updateServicePreference.message)
        setContactEmailBool(newPref.emailOptIn)
        setContactEmail(newPref.emailPref)
        setContactTextBool(newPref.smsOptIn)
        setContactText(newPref.smsPref)
        setContactPhoneBool(newPref.phoneOptIn)
        setContactPhone(newPref.phonePref)
        setContactPushBool(newPref.pushOptIn)
        setDoNotContact(newPref.doNotContact)
        setServiceTextBool(newPref.serviceSmsOptIn)
        setServicePhoneBool(newPref.servicePhoneOptIn)
        setServiceEmailBool(newPref.serviceEmailOptIn)
        setServicePushBool(newPref.servicePushOptIn)
        setPromoTextBool(newPref.promoSmsOptIn)
        setPromoPushBool(newPref.promoPushOptIn)
        setPromoPhoneBool(newPref.promoPhoneOptIn)
        setPromoEmailBool(newPref.promoEmailOptIn)
        setPromoFrequency(newFrequency)
        setSubmitting(false)
      },
    }
  )

  const promoFrequencies = [
    { id: 1, name: "Never" },
    { id: 2, name: "No more than two per year" },
    { id: 3, name: "No more than one per month" },
    { id: 4, name: "No more than one per week" },
    { id: 5, name: "Send me all promotions" },
  ]

  const handleCheckContactEmailBool = event => {
    setContactEmailBool(event.target.checked)
    event.target.checked === true && setDoNotContact(false)
    event.target.checked === false && setServiceEmailBool(false)
    event.target.checked === false && setPromoEmailBool(false)
  }
  const handleCheckContactPushBool = event => {
    setContactPushBool(event.target.checked)
    event.target.checked === true && setDoNotContact(false)
    event.target.checked === false && setServicePushBool(false)
    event.target.checked === false && setPromoPushBool(false)
  }
  const handleCheckContactTextBool = event => {
    setContactTextBool(event.target.checked)
    event.target.checked === true && setDoNotContact(false)
    event.target.checked === false && setServiceTextBool(false)
    event.target.checked === false && setPromoTextBool(false)
  }
  const handleCheckContactPhoneBool = event => {
    setContactPhoneBool(event.target.checked)
    event.target.checked === true && setDoNotContact(false)
    event.target.checked === false && setServicePhoneBool(false)
    event.target.checked === false && setPromoPhoneBool(false)
  }

  const handleCheckDoNotContact = event => {
    setDoNotContact(event.target.checked)
    !doNotContact && setContactEmailBool(false)
    setContactPushBool(false)
    setContactTextBool(false)
    setContactPhoneBool(false)

    setServiceEmailBool(false)
    setServicePhoneBool(false)
    setServiceTextBool(false)
    setServicePushBool(false)

    setPromoEmailBool(false)
    setPromoPhoneBool(false)
    setPromoTextBool(false)
    setPromoPushBool(false)

    setPromoFrequency(1)
  }
  const handleCheckServiceEmailBool = event => {
    setServiceEmailBool(event.target.checked)
    event.target.checked === true && setDoNotContact(false)
    event.target.checked === true && setContactEmailBool(true)
  }
  const handleCheckServiceTextBool = event => {
    setServiceTextBool(event.target.checked)
    event.target.checked === true && setDoNotContact(false)
    event.target.checked === true && setContactTextBool(true)
  }
  const handleCheckServicePhoneBool = event => {
    setServicePhoneBool(event.target.checked)
    event.target.checked === true && setDoNotContact(false)
    event.target.checked === true && setContactPhoneBool(true)
  }
  const handleCheckServicePushBool = event => {
    setServicePushBool(event.target.checked)
    event.target.checked === true && setDoNotContact(false)
    event.target.checked === true && setContactPushBool(true)
  }
  const handleCheckPromoEmailBool = event => {
    setPromoEmailBool(event.target.checked)
    event.target.checked === true && setDoNotContact(false)
    event.target.checked === true && setContactEmailBool(true)
  }
  const handleCheckPromoTextBool = event => {
    setPromoTextBool(event.target.checked)
    event.target.checked === true && setDoNotContact(false)
    event.target.checked === true && setContactTextBool(true)
  }
  const handleCheckPromoPhoneBool = event => {
    setPromoPhoneBool(event.target.checked)
    event.target.checked === true && setDoNotContact(false)
    event.target.checked === true && setContactPhoneBool(true)
  }
  const handleCheckPromoPushBool = event => {
    setPromoPushBool(event.target.checked)
    event.target.checked === true && setDoNotContact(false)
    event.target.checked === true && setContactPushBool(true)
  }

  const handleEmailChange = event => {
    event.persist()
    let value = 0
    if (event.target.value !== 0) {
      value = event.target.value
      console.log(value)
    }
    setContactEmail(value)
    setEmailId(value)
  }
  const handleTextChange = event => {
    event.persist()
    let value = 0
    if (event.target.value !== 0) {
      value = event.target.value
      console.log(value)
    }
    setContactText(value)
    setTextId(value)
  }
  const handlePhoneChange = event => {
    event.persist()
    let value = 0
    if (event.target.value !== 0) {
      value = event.target.value
      console.log(value)
    }
    setContactPhone(value)
    setPhoneId(value)
  }

  return (
    <>
      <form
        onSubmit={event => {
          event.preventDefault()
          setSubmitting(true)
          handleSubmit()
        }}
      >
        <Card square elevation={0} variant="outlined" className={classes.root}>
          <CardHeader title="Preferences" />
          <Divider />
          <CardContent>
            <Grid container spacing={6} wrap="wrap">
              <Grid item md={4} sm={6} xs={12}>
                <Typography gutterBottom variant="h6" color="textPrimary">
                  How to contact me
                </Typography>
                <Typography gutterBottom variant="body2" color="textSecondary">
                  I prefer to be contacted by
                </Typography>

                <div>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Email"
                    checked={contactEmailBool}
                    onChange={handleCheckContactEmailBool}
                    name="email"
                    color="primary"
                  />
                  <Collapse in={contactEmailBool} timeout="auto" unmountOnExit>
                    <TextField
                      id="contact-email"
                      label="Email Address"
                      //   style={{ margin: 8 }}
                      placeholder="Email Address"
                      select
                      fullWidth
                      margin="dense"
                      size="small"
                      variant="outlined"
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={contactEmail}
                      onChange={handleEmailChange}
                    >
                      {emailAddresses.map(e => (
                        <option key={e.id} value={e.id}>
                          {e.emailAddress}
                        </option>
                      ))}
                    </TextField>
                  </Collapse>
                </div>

                <div>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Text messages"
                    checked={contactTextBool}
                    onChange={handleCheckContactTextBool}
                    name="app"
                    color="primary"
                  />
                  <Collapse in={contactTextBool} timeout="auto" unmountOnExit>
                    <TextField
                      id="contact-email"
                      label="Text Messages"
                      //   style={{ margin: 8 }}
                      placeholder="Text Messages"
                      select
                      fullWidth
                      margin="dense"
                      size="small"
                      variant="outlined"
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={contactText}
                      onChange={handleTextChange}
                    >
                      {phoneNumbers.map(ph => (
                        <option key={ph.id} value={ph.id}>
                          {ph.phoneNumber}
                        </option>
                      ))}
                    </TextField>
                  </Collapse>
                </div>
                <div>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Phone calls"
                    checked={contactPhoneBool}
                    onChange={handleCheckContactPhoneBool}
                    name="phone"
                    color="primary"
                  />
                  <Collapse in={contactPhoneBool} timeout="auto" unmountOnExit>
                    <TextField
                      id="contact-phone"
                      label="Phone Calls"
                      //   style={{ margin: 8 }}
                      placeholder="Phone Calls"
                      select
                      fullWidth
                      margin="dense"
                      size="small"
                      variant="outlined"
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={contactPhone}
                      onChange={handlePhoneChange}
                    >
                      {phoneNumbers.map(ph => (
                        <option key={ph.id} value={ph.id}>
                          {ph.phoneNumber}
                        </option>
                      ))}
                    </TextField>
                  </Collapse>
                </div>
                <div>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={
                      <>
                        <Typography>Push Notifications</Typography>
                        <Collapse
                          in={contactPushBool}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Typography variant="body2" color="textSecondary">
                            Must be enabled in your browser and/or device.
                          </Typography>
                        </Collapse>
                      </>
                    }
                    checked={contactPushBool}
                    onChange={handleCheckContactPushBool}
                    name="app"
                    color="primary"
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Do not contact me"
                    checked={doNotContact}
                    onChange={handleCheckDoNotContact}
                    name="doNotContact"
                    color="primary"
                  />
                </div>
                <Collapse in={doNotContact} timeout="auto" unmountOnExit>
                  <Typography
                    gutterBottom
                    variant="body2"
                    color="textSecondary"
                  >
                    No outbound communication. Please login periodically and
                    browse the "Service" section of this app for important
                    messages.
                  </Typography>
                </Collapse>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Typography gutterBottom variant="h6" color="textPrimary">
                  Service Messages
                </Typography>
                <Typography gutterBottom variant="body2" color="textSecondary">
                  Notify me with essential customer service alerts and updates
                  via
                </Typography>

                <div>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Email"
                    checked={serviceEmailBool}
                    onChange={handleCheckServiceEmailBool}
                    name="emailService"
                    color="primary"
                  />
                </div>

                <div>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Text messages"
                    checked={serviceTextBool}
                    onChange={handleCheckServiceTextBool}
                    name="textService"
                    color="primary"
                  />
                </div>

                <div>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Phone calls"
                    checked={servicePhoneBool}
                    onChange={handleCheckServicePhoneBool}
                    name="phoneService"
                    color="primary"
                  />
                </div>

                <div>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Push Notifications"
                    checked={servicePushBool}
                    onChange={handleCheckServicePushBool}
                    name="pushService"
                    color="primary"
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Typography gutterBottom variant="h6" color="textPrimary">
                  Promotional Messages
                </Typography>
                <Typography gutterBottom variant="body2" color="textSecondary">
                  Notify me about new products and special offers
                </Typography>
                <div>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Email"
                    checked={promoEmailBool}
                    onChange={handleCheckPromoEmailBool}
                    name="emailPromo"
                    color="primary"
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Text messages"
                    checked={promoTextBool}
                    onChange={handleCheckPromoTextBool}
                    name="textPromo"
                    color="primary"
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Phone calls"
                    checked={promoPhoneBool}
                    onChange={handleCheckPromoPhoneBool}
                    name="phonePromo"
                    color="primary"
                  />
                </div>

                <div>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Push Notifications"
                    checked={promoPushBool}
                    onChange={handleCheckPromoPushBool}
                    name="pushPromo"
                    color="primary"
                  />
                </div>
                <Box mt={2}>
                  <Typography variant="body1" color="textSecondary">
                    How often?
                  </Typography>
                  <div>
                    <TextField
                      id="promotion-frequency"
                      label="Promotions Frequency"
                      //   style={{ margin: 8 }}
                      placeholder="Promotions Frequency"
                      select
                      fullWidth
                      margin="dense"
                      size="small"
                      variant="outlined"
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={promoFrequency}
                      onChange={event => setPromoFrequency(event.target.value)}
                    >
                      {promoFrequencies.map(pf => (
                        <option key={pf.id} value={pf.id}>
                          {pf.name}
                        </option>
                      ))}
                    </TextField>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </CardContent>
          {showSuccessMessage && (
            <Box style={{ width: "100%", padding: "10px" }}>
              <Alert
                onClose={() => {
                  setShowSuccessMessage(false)
                }}
              >
                {successMessage}
              </Alert>
            </Box>
          )}
          <Divider />

          <Box p={2} display="flex" justifyContent="flex-end">
            <Button
              disabled={submitting}
              color="secondary"
              type="submit"
              variant="contained"
            >
              Save Preferences
            </Button>
          </Box>
        </Card>
      </form>
    </>
  )
}

CommunicationPreferences.propTypes = {
  className: PropTypes.string,
}
const styles = theme => ({
  root: {},
})

const UPDATE_PREFERENCES_MUTATION = gql`
  mutation(
    $id: Int
    $emailOptIn: Boolean
    $emailPref: Int
    $smsOptIn: Boolean
    $smsPref: Int
    $phoneOptIn: Boolean
    $phonePref: Int
    $doNotContact: Boolean
    $pushOptIn: Boolean
    $serviceEmailOptIn: Boolean
    $serviceSmsOptIn: Boolean
    $servicePhoneOptIn: Boolean
    $servicePushOptIn: Boolean
    $promoEmailOptIn: Boolean
    $promoSmsOptIn: Boolean
    $promoPhoneOptIn: Boolean
    $promoPushOptIn: Boolean
    $promoFrequency: Int
  ) {
    updateServicePreference(
      id: $id
      emailOptIn: $emailOptIn
      emailPref: $emailPref
      smsOptIn: $smsOptIn
      smsPref: $smsPref
      phoneOptIn: $phoneOptIn
      phonePref: $phonePref
      doNotContact: $doNotContact
      pushOptIn: $pushOptIn
      serviceEmailOptIn: $serviceEmailOptIn
      serviceSmsOptIn: $serviceSmsOptIn
      servicePhoneOptIn: $servicePhoneOptIn
      servicePushOptIn: $servicePushOptIn
      promoEmailOptIn: $promoEmailOptIn
      promoPhoneOptIn: $promoPhoneOptIn
      promoSmsOptIn: $promoSmsOptIn
      promoPushOptIn: $promoPushOptIn
      promoFrequency: $promoFrequency
    ) {
      message
      servicePreference {
        id
        user {
          email
        }
        emailOptIn
        emailPref {
          id
          emailAddress
        }
        smsOptIn
        smsPref {
          id
          phoneNumber
        }
        phoneOptIn
        phonePref {
          id
          phoneNumber
        }
        pushOptIn
        doNotContact
        serviceSmsOptIn
        servicePushOptIn
        serviceEmailOptIn
        servicePhoneOptIn
        promoSmsOptIn
        promoPushOptIn
        promoEmailOptIn
        promoPhoneOptIn
        promoFrequency
      }
    }
  }
`
export default withStyles(styles)(CommunicationPreferences)
