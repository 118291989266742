import React, { useState, useContext, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import RoomIcon from "@material-ui/icons/Room"
import CreateIcon from "@material-ui/icons/Create"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import PaletteIcon from "@material-ui/icons/Palette"

import { GithubPicker } from "react-color"

import CommentsContext from "../context"
import useDidMountEffect from "../../../../utils/hooks/useDidMountEffect"

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    // width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

const CommentEditForm = ({ editing, setEditing }) => {
  const { state, dispatch } = useContext(CommentsContext)
  const classes = useStyles()
  const [color, setColor] = useState(state.currentComment.color)
  const [showPalette, setShowPalette] = useState(false)
  const [canvasTool, setCanvasTool] = useState(state.currentComment.canvasTool)
  const [commentText, setCommentText] = useState(state.currentComment.text)
  const [openAlert, setOpenAlert] = useState(false)
  const [comment, setComment] = useState({
    color: color,
    text: commentText,
    canvasTool: canvasTool,
  })

  useDidMountEffect(() => {
    setComment({
      color: color,
      text: commentText,
      canvasTool: canvasTool,
    })
  }, [canvasTool])

  useDidMountEffect(() => {
    setComment({
      color: color,
      text: commentText,
      canvasTool: canvasTool,
    })
  }, [color])

  useDidMountEffect(() => {
    setComment({
      color: color,
      text: commentText,
      canvasTool: canvasTool,
    })
  }, [commentText])

  const toggleShowPalette = () => {
    setShowPalette(!showPalette)
  }
  const handleHidePalette = () => {
    if (showPalette === true) {
      setShowPalette(false)
    }
  }
  const updateCommentText = event => {
    setCommentText(event.target.value)
  }

  const handleChangeComplete = (color, event) => {
    setColor(color.hex)
  }
  const handleUpdateComment = event => {
    dispatch({ type: "UPDATE_COMMENT", payload: comment })
    setEditing(false)
    setCommentText("")
    setColor("#666666")
    setCanvasTool("none")
    setOpenAlert(true)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9}>
          <Paper className={classes.root}>
            <TextField
              id="comment-field"
              label="Comments"
              multiline
              fullWidth
              rows={4}
              value={commentText}
              variant="outlined"
              onChange={updateCommentText}
            />
          </Paper>
        </Grid>
        <Grid item container xs={12} sm={3}>
          <Grid item xs={12}>
            <Box className={classes.root}>
              <IconButton
                className={classes.iconButton}
                aria-label="choose a color"
                onClick={toggleShowPalette}
              >
                <PaletteIcon style={{ color: color }} />
              </IconButton>
              <Divider className={classes.divider} orientation="vertical" />
              <ButtonGroup variant="outlined" color="secondary">
                <IconButton onClick={() => setCanvasTool("arrow")}>
                  <RoomIcon
                    style={
                      canvasTool === "arrow"
                        ? { color: color }
                        : { color: "#41424C" }
                    }
                  />
                </IconButton>
                <IconButton onClick={() => setCanvasTool("circle")}>
                  <RadioButtonUncheckedIcon
                    style={
                      canvasTool === "circle"
                        ? { color: color }
                        : { color: "#41424C" }
                    }
                  />
                </IconButton>
                <IconButton onClick={() => setCanvasTool("pen")}>
                  <CreateIcon
                    style={
                      canvasTool === "pen"
                        ? { color: color }
                        : { color: "#41424C" }
                    }
                  />
                </IconButton>
              </ButtonGroup>
            </Box>
            {showPalette && (
              <>
                <ClickAwayListener onClickAway={handleHidePalette}>
                  <GithubPicker
                    color={color}
                    onChangeComplete={handleChangeComplete}
                  />
                </ClickAwayListener>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<AddCircleIcon style={{ color: color }} />}
              onClick={handleUpdateComment}
            >
              Update Comment
            </Button>
            <Grid item xs={12}>
              {openAlert && (
                <Alert
                  onClose={() => setOpenAlert(false)}
                  severity="success"
                  variant="outlined"
                  color="secondary"
                >
                  Now, point out the issue with your pointer, circle or pen on
                  the above image.
                </Alert>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {showPalette && (
        <>
          <ClickAwayListener onClickAway={handleHidePalette}>
            <GithubPicker
              color={color}
              onChangeComplete={handleChangeComplete}
            />
          </ClickAwayListener>
        </>
      )}
    </>
  )
}

export default CommentEditForm
