import React, { useState } from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"

const UserPublicItemDetails = ({ classes }) => {
  return (
    <div>
      Browse public item details and order a public item from the user dashboard
    </div>
  )
}

const styles = theme => ({
  root: {},
})
export default withStyles(styles)(UserPublicItemDetails)
