import React, { useState, useContext, useEffect } from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import {
  Box,
  Card,
  CardHeader,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core"

import Loading from "../../../../Shared/Loading"
import Error from "../../../../Shared/Error"

import CheckoutContext from "../context/CheckoutContext"
import SummaryShipping from "./SummaryShipping"
import SummaryBilling from "./SummaryBilling"
import SummaryTotals from "./SummaryTotals"
import SummaryOrderItemCard from "./SummaryOrderItemCard"
import SummaryEmailPhone from "./SummaryEmailPhone"
import { useCart } from "../../Cart"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  headerCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
}))

function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const OrderSummary = () => {
  const classes = useStyles()
  const { state, dispatch } = useContext(CheckoutContext)
  const order = state.order
  const shippingAddress = state.orderShipping
  const billingAddress = state.orderBilling
  const { items } = useCart()
  // const [expanded, setExpanded] = useState(false)
  // const page = state.page
  // const pageSize = 10
  // const orderId = order.id
  // const [orderDetails, setOrderDetails] = useState("")

  // useEffect(() => {
  //   dispatch({ type: "SET_PAGE", payload: 1 })
  // }, [])

  // const [data, loading, error] = useQuery(ORDER_DETAILS_QUERY, {
  //   fetchPolicy: "cache-and-network",
  //   variables: {
  //     id: orderId,
  //   },

  //   onCompleted: data => {
  //     console.log("completed")
  //     console.log(data)
  //     setOrderDetails(data.order)
  //     let orderPayment = data.order.orderPayment

  //     dispatch({
  //       type: "SET_ORDER_SHIPPING",
  //       payload: data.order.orderShipping,
  //     })
  //     dispatch({
  //       type: "SET_ORDER_BILLING",
  //       payload: orderPayment,
  //     })
  //   },
  // })

  // if (loading) return <Loading />
  // if (error) return "error"

  return (
    <>
      <Paper square className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {shippingAddress && <SummaryShipping />}
          </Grid>
          <Grid item xs={12} md={6}>
            {billingAddress && <SummaryBilling />}
          </Grid>

          <Grid item xs={12}>
            <SummaryEmailPhone />
          </Grid>
          <Grid item xs={12}>
            <SummaryTotals />
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.title}>Order Items</Typography>
            {items &&
              items.map(item => (
                <Box mt={2} key={item.id}>
                  <SummaryOrderItemCard item={item} items={items} />
                </Box>
              ))}
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default OrderSummary
