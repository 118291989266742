import React, { useState, useEffect } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { useCart } from "./Cart"
import InputLabel from "@material-ui/core/InputLabel"

const UserCartQtySelect = ({ classes, item }) => {
  const id = item.id
  const quantity = item.quantity
  const [itemQty, setItemQty] = useState(quantity)
  const [itemPrice, setItemPrice] = useState(item.price)

  const { updateItemQuantity, updateItemPrice } = useCart()

  useEffect(() => {
    updateItemPrice(id, itemPrice)
  }, [itemPrice])

  const { loading, error, data } = useQuery(ITEM_QTY_QUERY, {
    fetchPolicy: "cache-and-network",
    pollInterval: 370000,
    variables: { id: id },
  })
  if (loading) return "loading..."
  if (error) return "error at userfiledetails"
  if (data === undefined) return "data undefined..."

  const priceSet = data.item.itemPrice

  function handleUpdate(event) {
    let price = event.target.value.price
    let q = event.target.value.minQuantity
    setItemQty(q)
    updateItemQuantity(id, q)
    setItemPrice(price)
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel>{itemQty}</InputLabel>
        <Select value="" onChange={handleUpdate} label="Qty">
          {priceSet.map(ps => (
            <MenuItem key={ps.id} value={ps}>
              {ps.minQuantity}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
})

const ITEM_QTY_QUERY = gql`
  query($id: Int) {
    item(id: $id) {
      itemPrice {
        id
        minQuantity
        price
      }
    }
  }
`

export default withStyles(styles)(UserCartQtySelect)
