import React, { useContext } from "react"
import { Link } from "gatsby"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

import moment from "moment"
import SortContext from "../../../../context/SortContext"
import Loading from "../../../Shared/Loading"
import Error from "../../../Shared/Error"
import ShippingListItem from "./ShippingListItem"

const UserShippingList = ({ classes, sbuId }) => {
  const { state } = useContext(SortContext)
  const page = state.page
  const pageSize = state.pageSize
  const search = state.search
  const { data, loading, error } = useQuery(SHIPPPING_QUERY, {
    variables: {
      page: page,
      pageSize: pageSize,
      search: search,
      businessUnitId: sbuId,
    },
  })
  if (loading) return <Loading />
  if (error) return <Error />

  // const result = myOrders.filter(order => order.orderShipping.length > 0)
  // Orders with shipments count
  // const resCount = result.length
  //todo total shipments count
  // let allShipments = result.reduce(function (accumulator, currentValue) {
  //   return [...accumulator, ...currentValue.orderShipping]
  // }, [])

  // const shipCount = allShipments.length
  const myShipments = data.myShipments

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="stretch">
        <>
          {myShipments.objects.map(shipment => {
            return (
              <Grid item xs={12}>
                <Box key={shipment.id} className={classes.shipmentListBox}>
                  <ShippingListItem shipment={shipment} />
                </Box>
              </Grid>
            )
          })}
        </>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  root: {},
  card: {
    padding: theme.spacing(1),
  },
  container: {
    display: "grid",
  },
  address: {
    paddingLeft: theme.spacing(1),
  },
  shipmentListBox: {
    width: "100%",
  },
})

const SHIPPPING_QUERY = gql`
  query($page: Int, $pageSize: Int, $search: String, $businessUnitId: Int) {
    myShipments(
      page: $page
      pageSize: $pageSize
      search: $search
      businessUnitId: $businessUnitId
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        order {
          id
        }
        carrier {
          ouid
          name
        }
        shipDate
        dueDate
        tracking
        shipException {
          id
          name
        }

        shipTo {
          id
          name
          thirdPartyAddress
          address {
            uid
            region {
              id
              name
            }
            attention
            addressLine1
            addressLine2
            addressLine3
            zone
            city
            postalCode
          }
        }
      }
    }
  }
`
export default withStyles(styles)(UserShippingList)
