import React, { useState, useEffect, useContext } from "react"
import PaymentOptionSelect from "./PaymentOptionSelect"
import StripePaymentForm from "./StripePaymentForm"
import GooglePaymentForm from "./GooglePaymentForm"
import AmazonPaymentForm from "./AmazonPaymentForm"
import ApplePaymentForm from "./ApplePaymentForm"

import CheckoutContext from "../context/CheckoutContext"

import { Box, Breadcrumbs, Button, Grid, Typography } from "@material-ui/core"

const PaymentForm = () => {
  const { state, dispatch } = useContext(CheckoutContext)
  const [paymentOption, setPaymentOption] = useState("stripe")

  const getPaymentOption = paymentOption => {
    switch (paymentOption) {
      case "stripe":
        return <StripePaymentForm />
      case "google":
        return <GooglePaymentForm />
      case "amazon":
        return <AmazonPaymentForm />
      case "apple":
        return <ApplePaymentForm />
      default:
        return <StripePaymentForm />
    }
  }

  return (
    <>
      <PaymentOptionSelect
        paymentOption={paymentOption}
        setPaymentOption={setPaymentOption}
      />

      <Box>{getPaymentOption(paymentOption)}</Box>
    </>
  )
}

export default PaymentForm
