import React, { useContext } from "react"
import {
  Box,
  ButtonBase,
  IconButton,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import {
  Menu as MenuIcon,
  ArrowLeftCircle as BackArrowIcon,
} from "react-feather"
import UserContext from "../../../../context/UserContext"
const HomeNav = ({ classes }) => {
  const { state, dispatch } = useContext(UserContext)
  return (
    <div className={classes.root}>
      <ButtonBase onClick={() => dispatch({ type: "TOGGLE_MOBILE_NAV" })}>
        <SvgIcon style={{ marginRight: "10px" }}>
          <BackArrowIcon />
        </SvgIcon>
        <Typography display="inline">Main Menu</Typography>
      </ButtonBase>
    </div>
  )
}
const styles = theme => ({
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing(4),
  },
})

export default withStyles(styles)(HomeNav)
