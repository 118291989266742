import React, { useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import UserContext from "../../../context/UserContext"
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Hidden,
  IconButton,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  Layers as LayersIcon,
  Menu as MenuIcon,
  User as UserIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
} from "react-feather"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}))

function Header({ className, ...rest }) {
  const { state, dispatch } = useContext(UserContext)
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <SvgIcon fontSize="small" color="secondary" className={classes.icon}>
          <LayersIcon />
        </SvgIcon>
        <Typography variant="h5" color="inherit" display="inline">
          Items
        </Typography>
      </Grid>
      <Hidden mdUp>
        <Grid item>
          <IconButton onClick={() => dispatch({ type: "TOGGLE_MOBILE_NAV" })}>
            <SvgIcon fontSize="small" color="secondary">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Grid>
      </Hidden>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
