import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  Paper,
  SvgIcon,
  Tooltip,
  Typography,
  colors,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { Rating } from "@material-ui/lab"
import FavoriteIcon from "@material-ui/icons/Favorite"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import { Users as UsersIcon } from "react-feather"
import getInitials from "../../../utils/getInitials"
import StarsIcon from "@material-ui/icons/Stars"
import PageviewIcon from "@material-ui/icons/Pageview"
import Draggable from "react-draggable"
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const ImageCard = ({ itemImage, classes, className, ...rest }) => {
  const [isLiked, setLiked] = useState(true)
  const [likes, setLikes] = useState(1)
  const [open, setOpen] = useState(false)
  const carouselImageUrl = itemImage.carouselImageUrl
  const personName =
    itemImage.createdBy && itemImage.createdBy.person
      ? itemImage.createdBy.person.firstName +
        " " +
        itemImage.createdBy.person.lastName
      : undefined
  const handleLike = () => {
    setLiked(true)
    setLikes(prevLikes => prevLikes + 1)
  }

  const handleUnlike = () => {
    setLiked(false)
    setLikes(prevLikes => prevLikes - 1)
  }
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <Box p={3}>
          <CardActionArea onClick={handleClickOpen}>
            <CardMedia className={classes.media} image={carouselImageUrl} />
          </CardActionArea>
          <Box display="flex" alignItems="center" mt={2}>
            {/* <Avatar alt="Author" src={project.author.avatar}> */}
            <Avatar alt="Author" variant="square" className={classes.square}>
              {itemImage.id}
              {/* {getInitials(project.author.name)} */}
            </Avatar>
            <Box ml={2}>
              <Link
                className={classes.link}
                color="textPrimary"
                to={`/my/items/pi/${itemImage.item.id}`}
                variant="h5"
              >
                {itemImage.title}
              </Link>
              <Typography variant="body2" color="textSecondary">
                by{" "}
                <Link
                  className={classes.link}
                  color="textPrimary"
                  to={
                    itemImage.createdBy && itemImage.createdBy.staff
                      ? `/my/service`
                      : "/my/account"
                  }
                  variant="h6"
                >
                  {itemImage.createdBy && itemImage.createdBy.staff
                    ? "Staff"
                    : personName}
                </Link>{" "}
                | Uploaded {moment(itemImage.uploadedAt).format("MMM Do, YYYY")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box pb={2} px={3}>
          <Typography color="textSecondary" variant="body2">
            Note: {itemImage.note}
          </Typography>
        </Box>
        <Box py={2} px={3}>
          <Grid
            alignItems="center"
            container
            justify="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography variant="h6" color="textPrimary">
                <Link
                  className={classes.link}
                  color="textPrimary"
                  to={`/my/items/pi/${itemImage.item.id}`}
                  variant="h5"
                >
                  {itemImage.item.name}
                </Link>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Item #: {itemImage.item.id}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box py={2} pl={2} pr={3} display="flex" alignItems="center">
          {itemImage.featured && (
            <Tooltip title="Featured Image">
              <StarsIcon />
            </Tooltip>
          )}

          <Box flexGrow={1} />
          {itemImage.proof && (
            <Tooltip title="Sample Proof">
              <PageviewIcon />
            </Tooltip>
          )}
        </Box>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth={false}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {itemImage.title}
        </DialogTitle>
        <DialogContent>
          <img
            src={itemImage.imageUrl}
            alt={itemImage.title ? itemImage.title : "Item Image"}
          />
          <DialogContentText>
            Note: {itemImage.note ? itemImage.note : undefined}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            variant="outlined"
            color="secondary"
          >
            Close
          </Button>
          <Button
            href={itemImage.imageUrl}
            download={itemImage.imageUrl}
            variant="contained"
            color="primary"
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ImageCard.propTypes = {
  className: PropTypes.string,
  itemImage: PropTypes.object.isRequired,
}

const styles = theme => ({
  root: {},
  media: {
    height: 200,
    backgroundColor: theme.palette.background.dark,
  },
  likedButton: {
    color: colors.red[600],
  },
  subscribersIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  square: {
    // color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: theme.palette.secondary.light,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
})

export default withStyles(styles)(ImageCard)
