import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import moment from "moment"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import SortContext from "../../../context/SortContext"
import OrderListItem from "./OrderListItem"
import NoUserOrders from "./NoUserOrders"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { useMatch } from "@reach/router"
import Loading from "../../Shared/Loading"
import Error from "../../Shared/Error"
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  InputAdornment,
  Paper,
  SvgIcon,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import Pagination from "@material-ui/lab/Pagination"
import {
  Edit as EditIcon,
  Search as SearchIcon,
  ArrowRight as ArrowRightIcon,
  X as CancelIcon,
} from "react-feather"

const sortOptions = [
  {
    value: "updatedAt|desc",
    label: "Last update (newest first)",
  },
  {
    value: "updatedAt|asc",
    label: "Last update (oldest first)",
  },
]
const orderStatusOptions = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "new",
    name: "New",
  },
  {
    id: "inProduction",
    name: "In Production",
  },
  {
    id: "shipped",
    name: "Shipped",
  },
  {
    id: "completed",
    name: "Completed",
  },
  {
    id: "onHold",
    name: "On Hold",
  },
  {
    id: "cancelled",
    name: "Cancelled",
  },
  {
    id: "discrepancy",
    name: "Discrepancy",
  },
]

const statusColors = {
  new: "primary",
  inProduction: "secondary",
  shipped: "secondary",
  canceled: "error",
  onHold: "warning",
  completed: "success",
  discrepancy: "error",
}

const UserOrderList = ({
  classes,
  myOrders,
  inProgressFilter,
  setInProgressFilter,
  ...rest
}) => {
  const { state, dispatch } = useContext(SortContext)
  const page = state.page
  const [searchBar, setSearchBar] = useState("")
  // const [inProgressFilter, setInProgressFilter] = useState(false)
  const [orderStatusFilter, setOrderStatusFilter] = useState("")
  const [sort, setSort] = useState("")

  const handleInProgressChange = event => {
    event.persist()

    let value = null

    if (event.target.checked) {
      value = true
    }
    setInProgressFilter(value)
  }

  // const handleOrderStatusChange = event => {
  //   event.persist()

  //   let value = ""

  //   if (event.target.value !== "all") {
  //     value = event.target.value
  //   }
  //   setOrderStatusFilter(value)
  // }

  // const handleSortChange = event => {
  //   event.persist()
  //   setSort(event.target.value)
  // }

  // const handlePageChange = (event, newPage) => {
  //   let x = 1
  //   const pagiNewPage = newPage
  //   dispatch({ type: "SET_PAGE", payload: pagiNewPage })
  // }

  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
  }

  // const handleClearFilters = event => {
  //   setInProgressFilter(null)
  //   setOrderStatusFilter("")
  // }
  const handleChange = (event, value) => {
    dispatch({ type: "SET_PAGE", payload: value })
  }
  return (
    <>
      <div className={classes.root}>
        {/* <Box p={2} minHeight={56} display="flex" alignItems="center"> */}
        <Grid container spacing={3} alignItems="stretch">
          {/* <Grid item container justify="space-between" xs={12}>
            <Grid item xs={12} sm={2}>
              <Box mb={1}>
                <Typography variant="caption">
                  Found {myOrders.count}{" "}
                  {myOrders.count !== 1 ? "items" : "item"}.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={7}>
              {myOrders.pages > 1 && (
                <Pagination
                  count={myOrders.pages}
                  page={state.page}
                  onChange={handleChange}
                  hideNextButton={!myOrders.hasNext}
                  hidePrevButton={!myOrders.hasPrev}
                  shape="rounded"
                  variant="outlined"
                  size="medium"
                  color="secondary"
                />
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              {myOrders.count > 1 && (
                <Paper
                  component="form"
                  variant="outlined"
                  square
                  onSubmit={event => {
                    event.preventDefault()
                    handleSearch(event)
                  }}
                  className={classes.searchField}
                >
                  <InputBase
                    fullWidth
                    className={classes.input}
                    placeholder="Search Orders"
                    inputProps={{ "aria-label": "Search Orders" }}
                    onChange={event => setSearchBar(event.target.value)}
                    value={searchBar}
                  />
                  <IconButton
                    type="submit"
                    className={classes.iconButton}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                  <Divider className={classes.divider} orientation="vertical" />
                  <IconButton
                    color="primary"
                    className={classes.iconButton}
                    aria-label="clear"
                    onClick={handleClearSearchResults}
                  >
                    <CancelIcon />
                  </IconButton>
                </Paper>
              )}
            </Grid>
          </Grid> */}

          {/* <Grid item xs={12} sm={3}>
                <FormControlLabel
                  className={classes.shippableField}
                  control={
                    <Switch
                      checked={inProgressFilter}
                      onChange={handleInProgressChange}
                      name="In Progress"
                    />
                  }
                  label="Current / Recent Orders"
                />
              </Grid> */}
          {/* <Grid item xs={12} sm={4}>
                {myOrders.pages > 1 && (
                  <Pagination
                    count={myOrders.pages}
                    page={state.page}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    color="secondary"
                  />
                )}
              </Grid> */}

          {/* </Box> */}

          <>
            {/* {JSON.stringify(myOrders)} */}
            {/* <Typography color="textSecondary" gutterBottom variant="body2">
                {myOrders.count} orders found. Start a new order by adding items
                to your cart.
              </Typography> */}

            {myOrders.count > 0 &&
              myOrders.objects.map(order => {
                return (
                  <Box key={order.id} className={classes.orderListBox}>
                    <OrderListItem order={order} />
                  </Box>
                )
              })}
          </>
        </Grid>
      </div>
      {myOrders.count === 0 && (
        <Card>
          <CardContent>
            <Typography variant="body1">
              You have no orders. Add items to your cart to get started.
            </Typography>
          </CardContent>
        </Card>
      )}
    </>
  )
}

const styles = theme => ({
  root: {},
  table: {
    marginTop: 5,
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  orderListBox: {
    width: "100%",
    margin: theme.spacing(2),
  },
})
UserOrderList.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array,
}

UserOrderList.defaultProps = {
  orders: [],
}

export default withStyles(styles)(UserOrderList)
