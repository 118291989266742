import React, { useState } from "react"
import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Dialog,
  Divider,
  Grid,
  Paper,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  PlusCircle as PlusCircleIcon,
  Download as DownloadIcon,
  List as ListIcon,
  Printer as PrintIcon,
} from "react-feather"
import ListAltIcon from "@material-ui/icons/ListAlt"
import withStyles from "@material-ui/core/styles/withStyles"
import { Link } from "gatsby"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"
import Header from "./Header"

import OrderItemCard from "./OrderItemCard"
import OrderSummaryBox from "./OrderSummaryBox"
import OrderShippingBox from "./OrderShippingBox"
import OrderPaymentBox from "./OrderPaymentBox"
import OrderPrint from "./OrderPrint"

const UserOrderDetails = ({ classes, id }) => {
  const [orderDetails, setOrderDetails] = useState("")
  const [orderPrintView, setOrderPrintView] = useState(false)
  const [fullWidthDialog, setFullWidthDialog] = useState(true)
  const [maxWidthDialog, setMaxWidthDialog] = useState("lg")
  const { data, loading, error } = useQuery(ORDER_DETAILS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: id,
    },
    onCompleted: data => {
      console.log("completed")
      console.log(data)
      setOrderDetails(data.order)
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />
  const order = data.order
  return (
    <>
      <div className={classes.root}>
        <Container>
          <Grid
            container
            alignItems="flex-start"
            spacing={3}
            className={classes.orderGrid}
          >
            <Grid item xs={12}>
              <Header
                orderDetails={orderDetails}
                setOrderPrintView={setOrderPrintView}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <OrderSummaryBox orderDetails={orderDetails} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <OrderShippingBox orderDetails={orderDetails} />
              <Box mt={2}>
                <OrderPaymentBox orderDetails={orderDetails} />
              </Box>
            </Grid>
            {orderDetails && orderDetails.orderWithItem ? (
              <Grid item xs={12}>
                <Card square className={classes.paper}>
                  <Typography className={classes.title}>
                    Items in this order
                  </Typography>
                  {orderDetails &&
                    orderDetails.orderWithItem &&
                    orderDetails.orderWithItem.map(oi => (
                      <Box className={classes.orderItemCard} key={oi.id}>
                        <OrderItemCard oi={oi} />
                      </Box>
                    ))}
                </Card>
              </Grid>
            ) : !loading ? (
              <Grid item xs={12}>
                <Typography>There are no items in this order.</Typography>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
          <Dialog
            fullWidth={fullWidthDialog}
            maxWidth={maxWidthDialog}
            open={orderPrintView}
            onClose={() => setOrderPrintView(false)}
          >
            <OrderPrint
              orderDetails={orderDetails}
              setOrderPrintView={setOrderPrintView}
            />
          </Dialog>
        </Container>
      </div>
    </>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(1, 0, 1),
  },
  orderGrid: {
    width: "100%",
  },
  orderItemCard: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(18),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow: "none",
  },
})

const ORDER_DETAILS_QUERY = gql`
  query ($id: Int) {
    order(id: $id) {
      id
      customer {
        uid
        firstName
        lastName
      }
      orderDate
      completionDate
      businessUnit {
        id
        name
        homepage
        primaryEmail
      }
      taxAmt
      discountAmt
      postageAmt
      orderTotal
      orderSubtotal
      orderPaymentTotal
      orderBalanceDue
      balanceDue
      balanceDueDate
      notes
      fromCart
      cartId
      inProgress
      status {
        id
        orderStatus
      }
      orderPayment {
        id
        paymentDate
        paidBy {
          uid
          firstName
          lastName
        }
        billingName
        billingAddress {
          id
          address {
            uid
            region {
              id
              name
            }
            attention
            addressLine1
            addressLine2
            addressLine3
            zone
            city
            postalCode
          }
          thirdPartyAddress
        }
        method {
          id
          name
        }
        code
        taxPaid
        shippingPaid
        totalPaid
        pmtException {
          id
          name
        }
      }
      orderShipping {
        id
        carrier {
          ouid
          name
        }
        tracking
        shipDate
        shipTo {
          id
          name
          thirdPartyAddress
          address {
            uid
            attention
            addressLine1
            addressLine2
            addressLine3
            isOrg
            zone
            city
            postalCode
            region {
              id
              name
            }
          }
        }
      }
      orderWithItem {
        id
        customFields
        item {
          id
          name
          category {
            name
          }
          isPrivate
          featuredImageThumbnailUrl
        }
        qty
        priceEach
        itemSub
        itemTax
        itemTotal
        itemDiscount
        taxAmt
        discountAmt
      }
    }
  }
`
export default withStyles(styles)(UserOrderDetails)
