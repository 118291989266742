import React, { Fragment } from "react"
import {
  Box,
  Card,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core"

import withStyles from "@material-ui/core/styles/withStyles"
import { Link } from "gatsby"

function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const OrderItemCard = ({ classes, oi }) => {
  const thumbnail = oi.item.featuredImageThumbnailUrl
  const customFields = oi.customFields ? JSON.parse(oi.customFields) : null
  const sortedCustomFields =
    customFields &&
    Object.entries(customFields).sort((a, b) => b[0].localeCompare(a[0]))
  function capitalizeFLetter() {
    var input = document.getElementById("input")
    var x = document.getElementById("div")
    var string = input.value
    x.innerHTML = string.charAt(0).toUpperCase() + string.slice(1)
  }
  function splitCamelCaseToString(s) {
    return s.split(/(?=[A-Z])/).join(" ")
  }
  const customFieldSet = []
  if (customFields) {
    for (const [key, value] of sortedCustomFields) {
      let format = (
        <Fragment key={key}>
          {/* {(key.charAt(0).toUpperCase() + key.slice(1)).replace(/_/g, " ")}:{" "} */}
          {isNaN(key)
            ? key.charAt(0).toUpperCase() +
              splitCamelCaseToString(key.slice(1)) +
              ": "
            : key}
          {isNaN(value)
            ? value.charAt(0).toUpperCase() + value.slice(1)
            : value}
          {/* {value.charAt(0).toUpperCase() + value.slice(1)} */}
          {/* {value.charAt(0) + value.slice(1)} */}
          {/* {value} */}
          <br />
        </Fragment>
      )
      customFieldSet.push(format)
    }
  }
  {
    console.log(oi)
  }
  return (
    <>
      <Box>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={4} sm={2}>
            <CardMedia
              className={classes.media}
              src={thumbnail ? thumbnail : undefined}
              title={oi.item.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mb={2}>
              {oi.item.isPrivate ? (
                <Link
                  className={classes.link}
                  variant="subtitle2"
                  color="textPrimary"
                  to={`/my/items/pi/${oi.item.id}`}
                  state={{ id: `${oi.item.id}` }}
                >
                  <Typography>{oi.item.name}</Typography>
                </Link>
              ) : (
                <Typography>{oi.item.name}</Typography>
              )}

              <Typography>{oi.item.category.name}</Typography>
              {oi.item.isPrivate && (
                <Typography>Custom Private Item</Typography>
              )}
              <Typography>{customFields ? customFieldSet : ""}</Typography>
            </Box>
          </Grid>
          <Grid item container xs={12} sm={4}>
            <Grid item xs={9}>
              {oi.qty} pcs
            </Grid>
            <Grid item xs={3}>
              ${financial(oi.priceEach)} ea
            </Grid>
            {/* {oi.taxAmt > 0 && (
              <>
                <Grid item xs={9}>
                  Tax
                </Grid>
                <Grid item xs={3}>
                  ${financial(oi.taxAmt)}
                </Grid>
              </>
            )}
            {oi.discountAmt > 0 && (
              <>
                <Grid item xs={9}>
                  Discount
                </Grid>
                <Grid item xs={3}>
                  ${financial(oi.discountAmt)}
                </Grid>
              </>
            )} */}
            {oi.itemDiscount ||
              (oi.itemTax && (
                <>
                  <Grid item xs={9}>
                    Item Subtotal
                  </Grid>
                  <Grid item xs={3}>
                    ${financial(oi.itemSub)}
                  </Grid>
                </>
              ))}
            {oi.itemDiscount && (
              <>
                <Grid item xs={9}>
                  Item Discount
                </Grid>
                <Grid item xs={3}>
                  ${financial(oi.itemDiscount)}
                </Grid>
              </>
            )}

            {oi.itemTax && (
              <>
                <Grid item xs={9}>
                  Item Tax
                </Grid>
                <Grid item xs={3}>
                  ${financial(oi.itemTax)}
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={9}>
              Item Total
            </Grid>
            <Grid item xs={3}>
              <strong>${financial(oi.itemTotal)}</strong>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider />
    </>
  )
}
const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.link,
    fontWeight: "bold",
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "none",
  },
  orderItemBox: {
    width: "100%",
    margin: theme.spacing(2),
  },
})

export default withStyles(styles)(OrderItemCard)
