import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import {
  Avatar,
  Box,
  Card,
  CardMedia,
  CardActionArea,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
  colors,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { Rating } from "@material-ui/lab"
import FavoriteIcon from "@material-ui/icons/Favorite"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import { Users as UsersIcon } from "react-feather"
import getInitials from "../../../utils/getInitials"
import StarsIcon from "@material-ui/icons/Stars"
import PageviewIcon from "@material-ui/icons/Pageview"

const SampleCard = ({ sampleProof, classes, className, ...rest }) => {
  const [isLiked, setLiked] = useState(true)
  const [likes, setLikes] = useState(1)
  const carouselImageUrl = sampleProof.carouselImageUrl
  const statusNumbers = [1, 5, 7, 16, 17, 18, 19, 20, 21, 22]
  const handleLike = () => {
    setLiked(true)
    setLikes(prevLikes => prevLikes + 1)
  }

  const handleUnlike = () => {
    setLiked(false)
    setLikes(prevLikes => prevLikes - 1)
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box p={3}>
        <CardActionArea
          component={Link}
          to={`/my/items/pi/${sampleProof.item.id}`}
          state={{ id: sampleProof.item.id }}
        >
          <CardMedia className={classes.media} image={carouselImageUrl} />
        </CardActionArea>
        <Box display="flex" alignItems="center" mt={2}>
          {/* <Avatar alt="Author" src={project.author.avatar}> */}
          <Avatar alt="Author" className={classes.avatar}>
            {sampleProof.version}
            {/* {getInitials(project.author.name)} */}
          </Avatar>
          <Box ml={2}>
            <Link
              className={classes.link}
              to={`/my/items/pi/${sampleProof.item.id}`}
              variant="h5"
              state={{ id: sampleProof.item.id }}
            >
              {sampleProof.item.name}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {moment(sampleProof.timestamp).format("MMM Do, YYYY")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box pb={2} px={3}>
        <Typography color="textSecondary" variant="body2">
          {sampleProof.status && statusNumbers.includes(sampleProof.status.id)
            ? sampleProof.status.name
            : undefined}
        </Typography>
      </Box>
      <Box py={2} px={3}>
        <Grid alignItems="center" container justify="space-between" spacing={3}>
          <Grid item>
            <Typography variant="h5" color="textPrimary">
              {sampleProof.item.id}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Item Id
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="right" color="textPrimary">
              {sampleProof.id}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Proof ID
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      {/* <Box py={2} pl={2} pr={3} display="flex" alignItems="center">
        {itemImage.featured && (
          <Tooltip title="Featured Image">
            <StarsIcon />
          </Tooltip>
        )}

        <Box flexGrow={1} />
        {itemImage.proof && (
          <Tooltip title="Sample Proof">
            <PageviewIcon />
          </Tooltip>
        )}
      </Box> */}
    </Card>
  )
}

SampleCard.propTypes = {
  className: PropTypes.string,
  sampleProof: PropTypes.object.isRequired,
}

const styles = theme => ({
  root: {},
  media: {
    height: 200,
    backgroundColor: theme.palette.background.dark,
  },
  likedButton: {
    color: colors.red[600],
  },
  subscribersIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
})

export default withStyles(styles)(SampleCard)
