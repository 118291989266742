import React, { useContext, useEffect } from "react"
import { Link } from "gatsby"
import CheckoutContext from "../context/CheckoutContext"
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core"
import { withStyles } from "@material-ui/styles"

const SummaryShipping = ({ classes }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const postalCode = state.spc
  // NOTE: Only handle one shipping address for now.
  const shipTo = state.orderShipping[0]

  const handleConfirmShipping = () => {
    dispatch({ type: "SET_SELECTED_ADDRESS_TYPE", payload: "shipping" })
    dispatch({ type: "SET_ACTIVE_STEP", payload: 1 })
    if (!state.showAddressComponent) {
      dispatch({ type: "TOGGLE_ADDRESS_COMPONENT" })
    }
  }

  // useEffect(() => {
  //   if (postalCode && postalCode.length !== 0) {
  //     dispatch({
  //       type: "SET_SHIPPING_POSTAL_CODE",
  //       payload: shipTo.address.postalCode,
  //     })
  //   }
  // }, [postalCode])

  return (
    // <></>
    <Box>
      <ButtonBase
        focusRipple
        className={classes.menuButton}
        // focusVisibleClassName={classes.focusVisible}
        style={{
          // width: "100%",
          display: "block",
          padding: 3,
        }}
        onClick={handleConfirmShipping}
      >
        <Typography className={classes.title}>
          {shipTo ? "Ship To" : ""}
        </Typography>
      </ButtonBase>

      <Box mt={1} pl={1}>
        <Typography variant="caption">
          {shipTo ? (
            <>
              {shipTo.name && shipTo.name}
              {shipTo.name && <br />}
              {shipTo.address.attention && `attn: ${shipTo.address.attention}`}
              {shipTo.address.attention && <br />}
              {shipTo.address.addressLine1 && shipTo.address.addressLine1}
              {shipTo.address.addressLine1 && <br />}
              {shipTo.address.addressLine2 && shipTo.address.addressLine2}
              {shipTo.address.addressLine2 && <br />}
              {shipTo.address.addressLine3 && shipTo.address.addressLine3}
              {shipTo.address.addressLine3 && <br />}
              {shipTo.address.city}, {shipTo.address.zone}{" "}
              {shipTo.address.postalCode} <br />
              {shipTo.address.region.name}
            </>
          ) : (
            ""
          )}
        </Typography>
      </Box>
    </Box>
  )
}
const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
    boxShadow: "none",
  },
  buttonText: {
    textAlign: "left",
  },
  card: {
    width: "100%",
    padding: theme.spacing(1),
  },
  field: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    color: "primary",
    variant: "outlined",
    // width: 400,
  },
  iconBox: {
    alignItems: "right",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  icon: {
    size: "20px",
  },
  iconButton: {
    padding: 5,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  disabled: {
    color: theme.palette.common.black,
    opacity: 1,
  },
  menuButton: {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "transparent",
      color: theme.palette.link,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
        color: theme.palette.link,
      },
    },
  },
  paper: {
    padding: theme.spacing(1),
    minHeight: 220,
  },
  title: {
    // fontSize: 14,
  },
  form: {
    display: "flex",
    width: "100%",
  },
  pos: {
    marginBottom: 12,
  },
  focusVisible: {},
  title: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
  },
})

export default withStyles(styles)(SummaryShipping)
