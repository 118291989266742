import React, { useState } from "react"
import { Link } from "gatsby"

import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"

import {
  Avatar,
  Box,
  Dialog,
  Typography,
  makeStyles,
  DialogContent,
} from "@material-ui/core"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../Shared/Loading"
import Error from "../../../Shared/Error"

// function senderSelector(state, senderId) {
//   const { user } = state.account;
//   const { contacts } = state.chat;

//   if (senderId !== user.id) {
//     const contact = contacts.byId[senderId];

//     return {
//       avatar: contact.avatar,
//       name: contact.name,
//       type: 'contact'
//     };
//   }

//   return {
//     avatar: user.avatar,
//     name: 'Me',
//     type: 'user'
//   };
// }

const useStyles = makeStyles(theme => ({
  root: {
    // marginBottom: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    height: 32,
    width: 32,
  },
  dialog: {
    maxWidth: "95vw",
  },
  image: {
    cursor: "pointer",
    height: "auto",
    maxWidth: "100%",
    width: 380,
  },
  myLink: {
    textDecoration: "none",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontFamily: "sans-serif",
  },
  otherLink: {
    textDecoration: "none",
    color: theme.palette.common.black,
    fontWeight: "bold",
    fontFamily: "sans-serif",
  },
  personName: {
    fontWeight: "bold",
    fontFamily: "sans-serif",
  },
  messageBox: {
    borderRadius: "15px",
  },
}))

function Message({ className, message, ...rest }) {
  const classes = useStyles()
  const [openedFile, setOpenedFile] = useState(false)
  const [viewingFile, setViewingFile] = useState("")
  const senderId = message.messageFrom.id
  const senderUid = message.messageFrom.person.uid
  //   const senderName =
  //     message.messageFrom.person &&
  //     message.messageFrom.person.firstName +
  //       " " +
  //       message.messgeFrom.person.lastName
  //   const sender = useSelector(state => senderSelector(state, message.senderId))
  const { data, loading, error } = useQuery(ME_QUERY)
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const myId = data.me.id
  //   return (
  //     <div>
  //       {senderId}: {message.message} (My id: {myId})(
  //       {myId === senderId ? "true" : "false"})
  //     </div>
  //   )

  //   const senderType =
  //     message &&
  //     message.messageFrom &&
  //     message.messageFrom.id &&
  //     message.mesageFrom.id === myId
  //       ? "user"
  //       : "nonUser"
  const handleOpenDialog = () => {
    // setViewingFile(imageFile)
    setOpenedFile(true)
  }
  const handleCloseDialog = () => {
    setOpenedFile(false)
  }
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Dialog
        open={openedFile}
        onClose={handleCloseDialog}
        className={classes.dialog}
      >
        <DialogContent>
          <img
            alt="Image in Message"
            // className={classes.image}
            src={message.messageFileUrl}
          />
        </DialogContent>
      </Dialog>
      <Box
        display="flex"
        // maxWidth={500}
        // ml={sender.type === "user" ? "auto" : 0}
        ml={myId === senderId ? "auto" : 0}
      >
        {/* <Avatar className={classes.avatar} src={sender.avatar} /> */}
        <Avatar
          alt={message.messageFrom.person.firstName}
          src={message.messageFrom.person.profileImageUrl}
          className={classes.avatar}
        />
        <Box ml={2}>
          <Box
            bgcolor={
              myId === senderId ? "secondary.main" : "background.paper"
              //   sender.type === "user" ? "secondary.main" : "background.default"
            }
            color={
              myId === senderId ? "secondary.contrastText" : "text.primary"
              //   sender.type === "user" ? "secondary.contrastText" : "text.primary"
            }
            py={1}
            px={2}
            borderRadius="15px"
            boxShadow={1}
          >
            <Typography className={classes.personName}>
              {message.messageFrom.person.firstName}{" "}
              {message.messageFrom.person.lastName}
            </Typography>
            <Box mt={1}>
              {message.messageFile ? (
                <Box mt={2} onClick={handleOpenDialog}>
                  <img
                    alt="Image in Message"
                    className={classes.image}
                    src={message.messageFileUrl}
                  />
                  <Typography color="inherit" variant="body1">
                    {message.message}
                  </Typography>
                </Box>
              ) : (
                <Typography color="inherit" variant="body1">
                  {message.message}
                </Typography>
              )}
            </Box>
          </Box>
          <Box mt={1} display="flex" justifyContent="flex-end">
            <Typography noWrap color="textSecondary" variant="caption">
              {moment(message.sent).fromNow()}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* {openedFile && (
        <Lightbox large={openedFile} onClose={() => setOpenedFile(null)} />
      )} */}
    </div>
  )
}

Message.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object.isRequired,
}

const ME_QUERY = gql`
  query {
    me {
      id
    }
  }
`
export default Message
