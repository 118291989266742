import React from "react"

import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import Loading from "../../../../components/Shared/Loading"
import Error from "../../../utils/Error"
// import FileStage from "./FileStage"
import withStyles from "@material-ui/core/styles/withStyles"
import Grid from "@material-ui/core/Grid"

// import UserFileEdit from "./UserFileEdit"

// import UserFiles from "../index"
// import UserFileUpload from './UserFileUpload'
// import UserFilesView from "./UserFilesView"
// import ColorField from "./ColorField"
// import ImageFeedbackApp from "./ImageFeedbackApp"
// import UserOrderAddress from './UserOrderAddress'
// import UserOrderStatus from './UserOrderStatus'
// import UserOrderDetails from "./UserOrderDetails"

const UserFileDetails = ({ classes, id }) => {
  const { loading, error, data } = useQuery(MY_IMAGE_FILE_QUERY, {
    fetchPolicy: "cache-and-network",
    pollInterval: 370000,
    variables: { id: id },
  })
  if (loading) return "loading..."
  if (error) return "error at userfiledetails"
  if (data === undefined) return "data undefined..."
  const img = data.itemImage

  return (
    <>
      {/* <div className={classes.root}> */}

      {/* <FileStage img={img} /> */}
      {/* </div> */}
    </>
  )
}
const MY_IMAGE_FILE_QUERY = gql`
  query ($id: Int) {
    itemImage(id: $id) {
      id
      title
      image
      imageUrl
      note
      featured
      proof
      createdBy {
        staff
        person {
          firstName
        }
      }
      uploadedAt
    }
  }
`
const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
  },
  container: {
    display: "grid",
  },
})

export default withStyles(styles)(UserFileDetails)
