import React, { useState, useContext } from "react"
import { navigate } from "gatsby"
import withStyles from "@material-ui/core/styles/withStyles"
import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import Typography from "@material-ui/core/Typography"
import FolderIcon from "@material-ui/icons/Folder"
import Card from "@material-ui/core/Card"
import Button from "@material-ui/core/Button"
import { green, purple } from "@material-ui/core/colors"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Box from "@material-ui/core/Box"
import UserContext from "../../../context/UserContext"

const MenuBox = ({ classes, activeTab, setActiveTab }) => {
  const { state, dispatch } = useContext(UserContext)
  const now = new Date().toISOString()

  return (
    <div className={classes.root}>
      <Card square className={classes.paper}>
        <Box pb={2}>
          <Typography variant="h6" className={classes.title}>
            Account Management
          </Typography>
        </Box>

        <Box pb={2}>
          <Button
            onClick={() => setActiveTab("profile")}
            fullWidth
            variant="outlined"
            color={activeTab === "profile" ? "secondary" : "primary"}
          >
            Profile
          </Button>
        </Box>
        <Box pb={2}>
          <Button
            onClick={() => setActiveTab("password")}
            fullWidth
            variant="outlined"
            color={activeTab === "password" ? "secondary" : "primary"}
          >
            Change Password
          </Button>
        </Box>
        <Box pb={2}>
          <Button
            onClick={() => setActiveTab("preferences")}
            fullWidth
            variant="outlined"
            color={activeTab === "preferences" ? "secondary" : "primary"}
          >
            Preferences
          </Button>
        </Box>
      </Card>
    </div>
  )
}

const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    "&:hover": {
      backgroundColor: purple[700],
    },
  },
  title: {},
  paper: {
    padding: theme.spacing(2),
    boxShadow: "none",
  },
  root: {},
})

// const ORDER_CREATE_MUTATION = gql`
// `

export default withStyles(styles)(MenuBox)
