import React, { useState, useContext } from "react"
import {
  Backdrop,
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Portal,
  SvgIcon,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import AddPhotoIcon from "@material-ui/icons/AddPhotoAlternate"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
import SortContext from "../../../../context/SortContext"
import {
  X as XIcon,
  Maximize as MaximizeIcon,
  Minimize as MinimizeIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  UserCheck as CheckIcon,
  User as UserIcon,
  Search as SearchIcon,
  XSquare as CloseIcon,
} from "react-feather"

// import { closeNewMessage } from 'src/actions/mailActions';
// import QuillEditor from 'src/components/QuillEditor';
import clsx from "clsx"
import moment from "moment"
import PerfectScrollbar from "react-perfect-scrollbar"

import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Shared/Loading"
import Error from "../../../Shared/Error"

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: `calc(100% - ${theme.spacing(6)}px)`,
    maxHeight: `calc(100% - ${theme.spacing(8)}px)`,
    width: 600,
    position: "fixed",
    bottom: 0,
    right: 0,
    margin: theme.spacing(3),
    outline: "none",
    zIndex: 2000,
    display: "flex",
    flexDirection: "column",
    minHeight: 350,
  },
  fullScreen: {
    height: "100%",
    width: "100%",
  },
  input: {
    width: "100%",
  },
  editor: {
    flexGrow: 1,
    "& .ql-editor": {
      minHeight: 300,
    },
  },
  action: {
    marginRight: theme.spacing(1),
  },
}))

const ConversationPersonSearch = ({
  newConversationOpen,
  setNewConversationOpen,
  className,

  inviteList,
  setInviteList,
  addPeople,
  setAddPeople,
}) => {
  const classes = useStyles()
  const [searchBar, setSearchBar] = useState("")
  // const [matches, setMatches] = useState([])
  //   const dispatch = useDispatch();
  //   const { newMessageOpen } = useSelector((state) => state.mail);
  const { state, dispatch } = useContext(SortContext)
  const [fullScreen, setFullScreen] = useState(false)
  const search = state.search
  const page = state.page
  const pageQ = state.page + 1
  const pageSize = state.pageSize

  const selectPerson = match => {
    setInviteList(invites => [...invites, match.match])

    // setPickUid(match.uid)
    // setPickFirst(match.firstName)
    // setPickLast(match.lastName)
    // setPickPerson(true)
    console.log(match)
  }
  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  const handlePageChange = (event, newPage) => {
    dispatch({ type: "SET_PAGE", payload: newPage })
  }

  const handleChangeRowsPerPage = event => {
    dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  const handleExitFullScreen = () => {
    setFullScreen(false)
  }
  const handleEnterFullScreen = () => {
    setFullScreen(true)
  }
  const handleClose = () => {
    setAddPeople(false)
  }
  const { loading, error, data } = useQuery(SEARCH_USERS, {
    variables: {
      search: search,
      page: pageQ,
      pageSize: pageSize,
      isVerifiedFilter: true,
    },
    fetchPolicy: "cache-and-network",
  })
  if (!addPeople) {
    return null
  }
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const resCount = data.usersPaginated.count
  const resPage = data.usersPaginated.page
  const resPages = data.usersPaginated.pages
  const people = data.usersPaginated.objects
  const matches = data.usersPaginated.objects

  return (
    <Portal>
      <Backdrop open={fullScreen} />
      <Paper
        className={clsx(classes.root, { [classes.fullScreen]: fullScreen })}
        elevation={12}
      >
        <Box
          bgcolor="background.dark"
          display="flex"
          alignItems="center"
          py={1}
          px={2}
        >
          <Typography variant="h5" color="textPrimary">
            Select Invitees
          </Typography>
          <Box flexGrow={1} />
          {fullScreen ? (
            <IconButton onClick={handleExitFullScreen}>
              <SvgIcon fontSize="small">
                <MinimizeIcon />
              </SvgIcon>
            </IconButton>
          ) : (
            <IconButton onClick={handleEnterFullScreen}>
              <SvgIcon fontSize="small">
                <MaximizeIcon />
              </SvgIcon>
            </IconButton>
          )}
          <IconButton onClick={handleClose}>
            <SvgIcon fontSize="small">
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Box>
        <Box p={2}>
          <Box minHeight={56} maxWidth={230} display="flex" alignItems="center">
            <form
              noValidate
              onSubmit={event => {
                event.preventDefault()
                handleSearch(event)
              }}
            >
              <TextField
                className={classes.queryField}
                margin="dense"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit" color="primary">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={event => setSearchBar(event.target.value)}
                placeholder="Search Users"
                value={searchBar}
                variant="outlined"
              />
            </form>
          </Box>
        </Box>

        <Divider />
        <>
          <PerfectScrollbar>
            <Box minWidth={700} mb={1}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell></TableCell>

                    <TableCell>Date Added</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {matches.map(match => {
                    return (
                      <TableRow
                        hover
                        key={match.id}
                        // selected={isCustomerSelected}
                      >
                        <TableCell padding="checkbox">
                          <IconButton onClick={e => selectPerson({ match }, e)}>
                            <SvgIcon fontSize="small">
                              <CheckIcon />
                            </SvgIcon>
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <Typography variant="body2" color="textSecondary">
                              {match.person && match.person.firstName}{" "}
                              {match.person && match.person.lastName}
                            </Typography>
                            {/* {user ? (
                              <SvgIcon fontSize="small" color="secondary">
                                <UserIcon />
                              </SvgIcon>
                            ) : (
                              ""
                            )} */}
                          </Box>
                        </TableCell>
                        <TableCell>{match.email}</TableCell>
                        <TableCell>
                          <SvgIcon fontSize="small" color="secondary">
                            <UserIcon />
                          </SvgIcon>
                        </TableCell>

                        <TableCell>
                          {moment(
                            match.person && match.person.dateAdded
                          ).format("MMM DD, YYYY")}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>

          <TablePagination
            component="div"
            count={resCount}
            onChangePage={handlePageChange}
            // onChangePage= {event => dispatch({type: 'SET_PAGE', payload: (newPage)})}
            // onChangeRowsPerPage={event => setPageSize(event.target.value)}
            // onChangeRowsPerPage = {event => dispatch({type: 'SET_PAGE_SIZE', payload: (event.target.value)})}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            // dispatch({ type: "SET_LOGIN" })
            // onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
            // ActionsComponent={TablePaginationActions}
          />
        </>
      </Paper>
    </Portal>
  )
}

const SEARCH_USERS = gql`
  query(
    $page: Int
    $pageSize: Int
    $isVerifiedFilter: Boolean
    $search: String
  ) {
    usersPaginated(
      page: $page
      pageSize: $pageSize
      isVerifiedFilter: $isVerifiedFilter
      search: $search
    ) {
      page
      pages
      hasNext
      hasPrev
      count
      objects {
        id
        email
        isVerified
        person {
          uid
          firstName
          lastName
          dateAdded
        }
      }
    }
  }
`
export default ConversationPersonSearch
