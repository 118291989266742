import React, { useState, useContext } from "react"
import { loadStripe } from "@stripe/stripe-js"
// import {CardElement, Elements, useElements, useStripe} from '../../src';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js"
// import '../styles/common.css';
import Loading from "../../../../utils/Loading"
import Error from "../../../../utils/Error"
import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import AddressForm from "../AddressForm"
import CheckoutContext from "../context/CheckoutContext"
import { useCart } from "../../Cart"
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainCard: {
    width: "100%",
    padding: theme.spacing(2),
    // backgroundColor: theme.palette.background.highlight,
  },
}))
const stripePromise = loadStripe("pk_test_kkkvfWvmHopjrqS9DdGkfPZF")

const CheckoutForm = () => {
  const classes = useStyles()
  const { state, dispatch } = useContext(CheckoutContext)
  const billingAddress = state.orderBilling[0]
  const billingAddressId =
    billingAddress && billingAddress.id ? billingAddress.id : null
  const shippingAddress = state.orderShipping[0]
  const cartStorage = JSON.parse(localStorage.getItem("ie-cart"))
  const [intent, setIntent] = useState("")
  const [clientSecret, setClientSecret] = useState("")
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [paymentComplete, setPaymentComplete] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [stripePaymentId, setStripePaymentId] = useState(null)
  const stripe = useStripe()
  const elements = useElements()
  console.log(billingAddress)

  // const cardStyle = {
  //   const useStyles = makeStyles(theme => ({
  //   style: {
  //     base: {
  //       iconColor: "#21252B",
  //       color: "#21252B",
  //       fontWeight: 500,
  //       fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  //       fontSize: "16px",
  //       fontSmoothing: "antialiased",
  //       ":-webkit-autofill": {
  //         color: "#fce883",
  //       },
  //       "::placeholder": {
  //         color: "#21252B",
  //       },
  //     },
  //     invalid: {
  //       iconColor: "#FFC7EE",
  //       color: "#FFC7EE",
  //     },
  //   },
  // }
  const cardOptions = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#3A3B3C",
        color: "#000",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#fce883" },
        "::placeholder": { color: "#9E9E9E" },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  }
  const handleChange = async event => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty)
    setError(event.error ? event.error.message : "")
  }
  const { data, loading } = useQuery(MY_STRIPE_PAYMENT_QUERY, {
    variables: {
      cartId: cartStorage.id,
      shippingAddressId: shippingAddress.id,
    },
    onCompleted: data => {
      console.log(data)
      let intent = JSON.parse(data.myStripePayment.intent)
      setStripePaymentId(data.myStripePayment.id)
      setIntent(intent)
      setClientSecret(intent.client_secret)
    },
  })
  const handleSubmit = async ev => {
    ev.preventDefault()
    setProcessing(true)
    console.log(billingAddress)
    console.log(state.billingDetails)
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: billingAddress.name,
          address: {
            line1: billingAddress.address.addressLine1
              ? billingAddress.address.addressLine1
              : "",
            line2: billingAddress.address.addressLine2
              ? billingAddress.address.addressLine2
              : "",
            city: billingAddress.address.city
              ? billingAddress.address.city
              : "",
            postal_code: billingAddress.address.postalCode
              ? billingAddress.address.postalCode
              : "",
            state: billingAddress.address.zone
              ? billingAddress.address.zone
              : "",
          },
          email: state.selectedEmail ? state.selectedEmail : "",
          phone: state.selectedPhone ? state.selectedPhone : null,
        },
      },
    })

    if (payload.error) {
      console.log("error with payment")
      setError(`Payment failed ${payload.error.message}`)
      setProcessing(false)
    } else {
      // Create order mutation, properly close cart in backend, return with new cart, email receipt, start the fulfillment flow.
      /// updateCartId works, but it is an incomplete solution.
      console.log("payment a success")
      console.log(payload)
      setError(null)
      setProcessing(false)

      console.log("success. now create new order and cart")

      // emptyCart()
      // updateCartId()
      !paymentComplete && handleCompletePayment()
      setSucceeded(true)
      console.log("payment completed")
    }
  }

  const [handleCompletePayment] = useMutation(
    COMPLETE_STRIPE_PAYMENT_MUTATION,
    {
      variables: {
        stripePaymentId: stripePaymentId,
        succeeded: true,
        billingAddressId: billingAddressId,
      },
      onCompleted: data => {
        console.log("payment and mutation completed. New order id:")
        const paymentData = data.completeStripePayment
        console.log(paymentData)
        const newOrderId = paymentData.orderId
        dispatch({ type: "SET_ORDER_ID", payload: newOrderId })
        dispatch({ type: "SET_PAYMENT_DATA", payload: paymentData.payment })
        setClientSecret("")
        setIntent("")
        handleNextPage()
      },
    }
  )
  const handleNextPage = () => {
    setPaymentComplete(true)
    dispatch({ type: "SET_ACTIVE_STEP", payload: 4 })
  }

  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <Card variant="outlined" className={classes.mainCard}>
      <form id="payment-form" onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xxs={12} xs={12} sm={9}>
            <CardElement
              id="card-element"
              options={cardOptions}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xxs={12} xs={12} sm={3}>
            {processing ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                size="small"
                disabled={
                  processing || disabled || succeeded || !billingAddress
                }
                id="submit"
              >
                Pay
              </Button>
            )}
          </Grid>
          {/* Show any error that happens when processing the payment */}
          {error && (
            <div className="card-error" role="alert">
              {error}
            </div>
          )}
        </Grid>
      </form>
    </Card>
  )
}

const MY_STRIPE_PAYMENT_QUERY = gql`
  query($cartId: String, $shippingAddressId: Int) {
    myStripePayment(cartId: $cartId, shippingAddressId: $shippingAddressId) {
      id
      customer {
        uid
        firstName
        lastName
      }
      cart {
        id
        cartId
        guestCart
        items
        totalItems
        totalUniqueItems
        started
        latestChange
        businessUnit {
          id
          name
        }
        cartOpen
        expirationDate
        shippingPostalCode
        cartPrices
      }
      intent
      order {
        id
      }
    }
  }
`
const COMPLETE_STRIPE_PAYMENT_MUTATION = gql`
  mutation($stripePaymentId: Int, $succeeded: Boolean, $billingAddressId: Int) {
    completeStripePayment(
      stripePaymentId: $stripePaymentId
      succeeded: $succeeded
      billingAddressId: $billingAddressId
    ) {
      message
      orderId
      payment {
        id
        order {
          id
        }
        paidBy {
          uid
          firstName
          lastName
        }
        billingName
        billingAddress {
          address {
            uid
            attention
            addressLine1
            addressLine2
            addressLine3
            isOrg
            zone
            city
            postalCode
            region {
              id
              name
            }
          }
        }
        method {
          id
          name
        }
        paymentDate
        pmtException {
          id
          name
        }
        code
        taxPaid
        shippingPaid
        totalPaid
      }
    }
  }
`

const StripePaymentForm = () => (
  <>
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
    <AddressForm />
  </>
)
export default StripePaymentForm
