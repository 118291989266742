import React, { useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import {
  Grid,
  Box,
  Button,
  ButtonBase,
  Card,
  ClickAwayListener,
  Container,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import HomeWorkIcon from "@material-ui/icons/HomeWork"

import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"

import { useCart } from "./Cart"
import UserContext from "../../../context/UserContext"
import { useSiteMetadata } from "../../utils/hooks/useSiteMetadata"
import { useSalesUseTaxData } from "../../utils/hooks/useSalesUseTaxData"
import GetShipping from "./GetShipping"
import useDidMountEffect from "../../utils/hooks/useDidMountEffect"
import BigBlackButton from "../../DropIn/BigBlackButton"
function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const CheckOutBox = ({
  classes,
  cartId,
  uid,
  cartItems,
  started,
  setCheckout,
}) => {
  const { state, dispatch } = useContext(UserContext)

  const now = new Date().toISOString()
  const [orderId, setOrderId] = useState("")
  const [order, setOrder] = useState(null)
  const [orderItems, setOrderItems] = useState("")
  // const [weight, setWeight] = useState(40)
  // For clickaway listener
  const [enterCode, setEnterCode] = useState(false)
  // Shipping price on display
  const [shipping, setShipping] = useState(null)
  const [taxCode, setTaxCode] = useState(state.stc)
  const [taxRate, setTaxRate] = useState(0.0)
  const [taxDescription, setTaxDescription] = useState("")
  const [taxName, setTaxName] = useState("")
  const [tax, setTax] = useState(0)
  const { sbuId } = useSiteMetadata()
  const { edges } = useSalesUseTaxData()
  const [total, setTotal] = useState(0)
  const [shippingIsUpdated, setShippingIsUpdated] = useState(false)
  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    totalItems,
    items,
    totalWeight,
    latestUpdate,
    updateItemQuantity,
    updateItemPrice,
    removeItem,
    emptyCart,
    isOpen,
    closeCart,
  } = useCart()

  useEffect(() => {
    // stc stands for sales tax code
    let currentTax = edges.find(edge => edge.node.id == state.stc)
    console.log(currentTax)
    setTaxRate(currentTax.node.rate)
    setTaxDescription(currentTax.node.description)
    setTaxName(currentTax.node.name)
    console.log(taxRate)
    calcTotal()
  }, [shippingIsUpdated])

  useDidMountEffect(() => {
    let currentTax = edges.find(edge => edge.node.id == state.stc)
    console.log(currentTax)
    setTaxRate(currentTax.node.rate)
    setTaxDescription(currentTax.node.description)
    setTaxName(currentTax.node.name)
    console.log(taxRate)
    calcTotal()
  }, [shippingIsUpdated])
  const jsonItems = JSON.stringify(items)

  const [addOrUpdateCart, { loading, error }] = useMutation(
    CREATE_OR_UPDATE_MY_CART_MUTATION,
    {
      variables: {
        cartId: cartId,
        businessUnitId: sbuId,
        items: jsonItems,
        totalItems: totalItems,
        totalUniqueItems: totalUniqueItems,
        started: started,
        latestChange: latestUpdate,
        cartOpen: true,
        cartExpired: false,
      },

      onCompleted: data => {
        console.log("items")
        console.log(items)
        console.log("cart created or updated")
        console.log(data)
        setCheckout(true)
      },
    }
  )

  // const [addNewOrder] = useMutation(ORDER_CREATE_MUTATION, {
  //   variables: {
  //     sbuId: sbuId,
  //     taxAmt: 0.0,
  //     discountAmt: 0.0,
  //     postageAmt: 0.0,
  //     balanceDue: 0.0,
  //     fromCart: true,
  //     cartId: cartId,
  //   },
  //   onCompleted: (data, errors) => {
  //     const orderId = data.createMyOrder.order.id
  //     // setOrder(orderData)
  //     dispatch({ type: "SET_ORDER_ID", payload: orderId })
  //     // handleCloseCart()
  //     console.log(orderId)
  //     // closeCart()
  //     navigate(`/my/orders/checkout/${orderId}`)
  //   },
  // })

  // const [handleCloseCart] = useMutation(CLOSE_CART_MUTATION, {
  //   variables: {
  //     cartId: cartId,
  //     businessUnitId: 1,
  //     personUid: uid,
  //     items: items,
  //     totalItems: cartItems.length,
  //     totalUniqueItems: totalUniqueItems,
  //     started: started,
  //     latestChange: now,
  //     cartOpen: false,
  //   },
  //   onCompleted: data => {
  //     // console.log({ order })
  //     // navigate("/my/orders/checkout", {
  //     //   state: { order },
  //     // })
  //     // navigate("/my/orders/checkout")
  //   },
  // })

  // const [addOrderItem] = useMutation(ADD_ORDER_ITEM_MUTATION, {
  //   variables: {
  //     orderId: orderId,
  //     // itemId: cartItem.id,
  //     taxId: 1,
  //     discountId: 1,
  //     // qty: cartItem.qty,
  //     // priceEach: cartItem.priceEach,
  //   },

  //   onCompleted: (data, error) => {
  //     const addedItem = data.createOrderItem.orderItem
  //   },
  // })

  // const closeCart = event => {
  //   localStorage.clear("ie-cart")
  // }
  // // cartItems.forEach(obj => {
  //   let itemInCart = Object.entries(obj)
  //   let newItemId = itemInCart.id
  //   console.log(newItemId)
  // setItemId(Object.entries(obj).id)

  //   Object.entries(obj).forEach(([key, value]) => {
  //     console.log(`${key} ${value}`)
  //   })
  // })
  // cartItems.forEach(addOrderItem)
  // console.log(orderId)
  // console.log(cartItems)
  //   })
  // }
  // if (isEmpty) return ""
  // const cart = JSON.parse(localStorage.getItem("ie-cart"));
  // const subtotal = cart.cartTotal

  const subtotal = cartTotal

  const calcTotal = () => {
    let subtotalPlusShipping = subtotal + state.sp
    if (taxRate > 0) {
      var totalValue = (subtotal + state.sp) * (1 + taxRate)
      var totalTax = (subtotal + state.sp) * taxRate
      setTax(totalTax)
    } else {
      var totalValue = subtotalPlusShipping
    }
    return setTotal(totalValue)
  }

  const handleGoToCheckout = () => {
    addOrUpdateCart()
  }

  const handleRemoveLocation = () => {
    setEnterCode(true)
    setShipping(null)
    setShippingIsUpdated(false)
    setTaxRate(0.0)
    setTaxName("")
    setTaxDescription("")
    dispatch({ type: "SET_TAX", payload: 1 })
    dispatch({ type: "SET_SHIP_ZONE_INDEX", payload: null })
    dispatch({ type: "SET_SHIPPING_LOCATION", payload: "" })
    dispatch({ type: "SET_POSTAL_CODE", payload: "" })
    dispatch({ type: "SET_SHIPPING_ZONE", payload: "" })
    dispatch({ type: "SET_SHIPPING_PRICE", payload: 0.0 })
    dispatch({ type: "SET_SHIP_ZIP_SET", payload: false })
    console.log("clicked")
  }
  const handleClickAway = () => {
    // if (!state.pc) handleRemoveLocation()
    // else setEnterCode(false)
    setEnterCode(false)
  }
  return (
    <div className={classes.root}>
      <Card square className={classes.paper}>
        <Typography variant="h6" className={classes.title}>
          Cart Summary
        </Typography>
        {/* <div>{JSON.stringify(items)}</div> */}

        <TableContainer>
          <Table className={classes.table} aria-label="spanning table">
            <TableBody>
              <TableRow>
                <TableCell>Subtotal</TableCell>
                <TableCell align="right">
                  {subtotal ? <>${financial(subtotal)}</> : ""}
                </TableCell>
              </TableRow>
              <ClickAwayListener onClickAway={handleClickAway}>
                <TableRow>
                  <TableCell>
                    {enterCode ? "Postal Code" : "Postage"}

                    <br />
                    <ButtonBase
                      onClick={handleRemoveLocation}
                      className={classes.link}
                    >
                      {state.loc}
                    </ButtonBase>
                  </TableCell>
                  <TableCell align="right">
                    <GetShipping
                      totalWeight={totalWeight}
                      shipping={shipping}
                      setShipping={setShipping}
                      enterCode={enterCode}
                      setEnterCode={setEnterCode}
                      shippingIsUpdated={shippingIsUpdated}
                      setShippingIsUpdated={setShippingIsUpdated}
                      setTaxRate={setTaxRate}
                      onRemoveLocation={handleRemoveLocation}
                    />
                  </TableCell>
                </TableRow>
              </ClickAwayListener>
              <TableRow>
                <TableCell>
                  <Typography>Sales Tax</Typography>
                  {taxRate > 0 ? (
                    <Typography variant="caption">{taxRate * 100}%</Typography>
                  ) : !enterCode && !state.pc && taxRate === 0 ? (
                    "Enter postal code"
                  ) : (
                    "No tax collected"
                  )}
                </TableCell>

                <TableCell align="right">
                  {total && taxRate ? `$${financial(total * taxRate)}` : ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Estimated Total</TableCell>
                <TableCell align="right">
                  {total ? `$${financial(total)}` : ""}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {!isEmpty && (
          <BigBlackButton
            action={handleGoToCheckout}
            buttonText="Checkout"
            disabled={isEmpty}
          />
        )}
      </Card>
    </div>
  )
}

const styles = theme => ({
  root: {
    // margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 270,
  },
  title: {},
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "0px",
    borderRadius: "15px",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  postalInput: {
    maxWidth: 80,
  },
  componentCell: {
    alignItems: "right",
  },
  table: {
    padding: theme.spacing(0),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.link,
  },
})

const CREATE_OR_UPDATE_MY_CART_MUTATION = gql`
  mutation(
    $cartId: String
    $items: String
    $totalItems: Int
    $totalUniqueItems: Int
    $started: DateTime
    $latestChange: DateTime
    $businessUnitId: Int
    $cartOpen: Boolean
    $expirationDate: DateTime
  ) {
    createOrUpdateMyCart(
      cartId: $cartId
      items: $items
      totalItems: $totalItems
      totalUniqueItems: $totalUniqueItems
      started: $started
      latestChange: $latestChange
      businessUnitId: $businessUnitId
      cartOpen: $cartOpen
      expirationDate: $expirationDate
    ) {
      cart {
        id
        cartId
        items
        totalItems
        totalUniqueItems
        started
        latestChange
        cartOpen
        expirationDate
        cartExpired
      }
    }
  }
`

const ORDER_CREATE_MUTATION = gql`
  mutation(
    $sbuId: Int
    $taxAmt: Money
    $discountAmt: Money
    $postageAmt: Money
    $balanceDue: Money
    $fromCart: Boolean
    $cartId: String
  ) {
    createMyOrder(
      sbuId: $sbuId
      taxAmt: $taxAmt
      discountAmt: $discountAmt
      postageAmt: $postageAmt
      balanceDue: $balanceDue
      fromCart: $fromCart
      cartId: $cartId
    ) {
      order {
        id
        customer {
          uid
        }
        inProgress
        status {
          id
          description
        }
        orderDate
        completionDate
        businessUnit {
          id
          name
        }
        status {
          id
          orderStatus
          description
        }
        taxAmt
        discountAmt
        postageAmt
        balanceDue
        balanceDueDate
        notes
        fromCart
        cartId
        orderWithItem {
          id
          order {
            id
          }
          qty
          priceEach
          itemSubtotal
          customFields
        }
      }
    }
  }
`
const ADD_ORDER_ITEM_MUTATION = gql`
  mutation(
    $orderId: Int!
    $itemId: Int!
    $taxId: Int
    $discountId: Int
    $qty: Int
    $priceEach: Money
    $customFields: String
  ) {
    createOrderItem(
      orderId: $orderId
      itemId: $itemId
      taxId: $taxId
      discountId: $discountId
      qty: $qty
      priceEach: $priceEach
      customFields: $customFields
    ) {
      orderItem {
        id
        item {
          id
          name
          isPrivate
          featuredImageThumbnailUrl
          itemPrice {
            id
            minQuantity
            price
          }
          category {
            id
            name
          }
        }
        order {
          id
        }
        qty
        priceEach
        customFields
        tax {
          id
          code
        }
        discount {
          id
          code
        }
      }
    }
  }
`
const CLOSE_CART_MUTATION = gql`
  mutation(
    $cartId: String
    $businessUnitId: Int
    $personUid: String
    $items: String
    $totalItems: Int
    $totalUniqueItems: Int
    $started: DateTime
    $latestChange: DateTime
    $cartOpen: Boolean
  ) {
    updateCart(
      cartId: $cartId
      businessUnitId: $businessUnitId
      personUid: $personUid
      items: $items
      totalItems: $totalItems
      totalUniqueItems: $totalUniqueItems
      started: $started
      latestChange: $latestChange
      cartOpen: $cartOpen
    ) {
      cart {
        id
        cartId
        person {
          uid
          firstName
          lastName
        }
        items
        totalItems
        totalUniqueItems
        businessUnit {
          id
          name
        }
        started
        latestChange
        cartOpen
        cartExpired
      }
    }
  }
`
export default withStyles(styles)(CheckOutBox)
