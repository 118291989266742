import React, { useEffect, useContext } from "react"
import { useLocation, Match } from "@reach/router"
import { Link, navigate } from "gatsby"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import PerfectScrollbar from "react-perfect-scrollbar"
import PropTypes from "prop-types"
import UserContext from "../../../context/UserContext"
import { useSiteMetadata } from "../../utils/hooks/useSiteMetadata"
import {
  Avatar,
  Box,
  ButtonBase,
  Chip,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Typography,
  makeStyles,
} from "@material-ui/core"
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined"
import {
  Bell as BellIcon,
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  Home as HomeIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  Truck as SupplyIcon,
  Package as PackageIcon,
  Layers as LayersIcon,
  MessageSquare as MessageSquareIcon,
  LogOut as LogoutIcon,
} from "react-feather"

import Logo from "../Logo"
import NavItem from "./NavItem"

const navConfig = [
  {
    items: [
      {
        title: "Main Menu",
        icon: HomeIcon,
        href: "/my",
      },
      {
        title: "Cart",
        icon: ShoppingCartIcon,
        href: "/my/cart",
      },
      {
        title: "Orders",
        icon: PackageIcon,
        href: "/my/orders",
        items: [
          {
            title: "Orders",
            href: "/my/orders",
          },
          {
            title: "Payments",
            href: "/my/payments",
          },
          {
            title: "Shipping",
            href: "/my/shipping",
          },
        ],
      },
      {
        title: "Items",
        icon: LayersIcon,
        href: "/my/items",
      },
      {
        title: "Files",
        href: "/my/files",
        icon: FolderIcon,
      },
      {
        title: "Service",
        icon: MessageSquareIcon,
        href: "/my/service",
      },
      {
        title: "Account",
        icon: UserIcon,
        href: "/my/account",
      },
    ],
  },
]

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  )
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth

  if (item.items) {
    const open = Match(pathname, {
      path: item.href,
      exact: false,
    })

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    )
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    )
  }

  return acc
}

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 65,
    height: "calc(100% - 62px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  name: {
    textDecoration: "none",
    fontWeight: "bold",
    fontVariant: "small-caps",
    color: theme.palette.primary.dark,
    fontFamily: "sans-serif",
    fontSize: "19px",
  },
  buttonLeaf: {
    color: theme.palette.text.primary,
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    fontSize: "17px",
    display: "flex",
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1.5),
    color: theme.palette.secondary.main,
  },
  borderBox: {
    borderStyle: "solid",
    borderRadius: "15px",
    borderColor: "ECECEC",
    borderWidth: "1px",
  },
}))

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles()
  const location = useLocation()
  const { state, dispatch } = useContext(UserContext)
  const { title } = useSiteMetadata()
  const [handleSignOut, { client, loading, error }] = useMutation(
    LOGOUT_MUTATION,
    {
      onCompleted() {
        client.resetStore()
        navigate("/")
      },
    }
  )

  const handleLogout = () => {
    dispatch({
      type: "SET_LOGOUT",
    })
    dispatch({ type: "SET_VERIFIED", payload: false })
    dispatch({ type: "SET_INITIAL", payload: "" })
    handleSignOut()
  }
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line
  }, [location.pathname])

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {navConfig.map(config => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Box p={2}>
          <Divider style={{ margin: "20px" }} />
          <ButtonBase
            size="small"
            style={{
              marginTop: "auto",
              padding: "10px 8px",
              width: "100%",
              justifyContent: "flex-start",
            }}
            color="primary"
            onClick={handleLogout}
          >
            <LogoutIcon className={classes.icon} />
            <Typography className={classes.buttonLeaf}>Sign Out</Typography>
          </ButtonBase>
        </Box>
      </PerfectScrollbar>
    </Box>
  )

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}
const LOGOUT_MUTATION = gql`
  mutation {
    deleteTokenCookie {
      deleted
    }
  }
`
export default NavBar
