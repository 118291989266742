import React, { useContext } from "react"
import { Link } from "gatsby"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

import moment from "moment"
import SortContext from "../../../../context/SortContext"
import Loading from "../../../Shared/Loading"
import Error from "../../../Shared/Error"
import PaymentListItem from "./PaymentListItem"

const UserPaymentList = ({ classes, sbuId }) => {
  const { state, dispatch } = useContext(SortContext)
  const page = state.page
  const pageSize = state.pageSize
  const search = state.search
  const { data, loading, error } = useQuery(MY_PAYMENTS, {
    variables: {
      page: page,
      pageSize: pageSize,
      search: search,
      businessUnitId: sbuId,
    },
  })

  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }

  if (loading) return <Loading />
  if (error) return <Error />
  const myPayments = data.myPayments

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3} alignItems="stretch">
          <>
            {myPayments.count > 0 &&
              myPayments.objects.map(payment => {
                return (
                  <Grid item xs={12} key={payment.id}>
                    <Box key={payment.id} className={classes.paymentListBox}>
                      <PaymentListItem payment={payment} key={payment.id} />
                    </Box>
                  </Grid>
                )
              })}
          </>
          <>
            {myPayments.count === 0 && (
              <Grid item xs={12}>
                <Box className={classes.paymentListBox}>
                  <Typography>No Payments Found</Typography>
                </Box>
              </Grid>
            )}
          </>
        </Grid>
      </div>
    </>
  )
}

const styles = theme => ({
  root: {},

  paymentListBox: {
    width: "100%",
  },
})

const MY_PAYMENTS = gql`
  query($page: Int, $pageSize: Int, $search: String, $businessUnitId: Int) {
    myPayments(
      page: $page
      pageSize: $pageSize
      search: $search
      businessUnitId: $businessUnitId
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        order {
          id
          status {
            id
            orderStatus
          }
          customer {
            uid
            firstName
            lastName
          }
          orderDate
          completionDate
          businessUnit {
            id
            name
          }
          subtotal
          taxAmt
          discountAmt
          postageAmt
        }
        paidBy {
          uid
          firstName
          lastName
        }
        billingName
        billingAddress {
          id
          name
          thirdPartyAddress
          address {
            uid
            region {
              id
              name
            }
            category {
              id
              name
            }
            attention
            addressLine1
            addressLine2
            addressLine3
            zone
            city
            postalCode
          }
        }
        paymentDate
        method {
          id
          name
        }
        code
        pmtException {
          id
          name
        }
        taxPaid
        shippingPaid
        totalPaid
      }
    }
  }
`
export default withStyles(styles)(UserPaymentList)
