import React, { useState } from "react"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

import Header from "./Header"
import ItemImages from "./ItemImages"
import SampleProofs from "./SampleProofs"

const UserFiles = ({ classes }) => {
  const [currentTab, setCurrentTab] = useState("itemImages")

  const tabs = [
    { value: "itemImages", label: "Item Images" },
    { value: "sampleProofs", label: "Samples and Proofs" },
  ]

  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  return (
    <div>
      <Container>
        <Header />
        <div>Files. Wrap canvas in hook so it doesn't pre-render.</div>
        <Box mt={2}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
            className={classes.tabs}
          >
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>

        <Divider />

        <Box mt={3}>
          {currentTab === "itemImages" && <ItemImages />}
          {currentTab === "sampleProofs" && <SampleProofs />}
        </Box>
      </Container>
    </div>
  )
}

const styles = theme => ({
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
})

export default withStyles(styles)(UserFiles)
