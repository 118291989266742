import React, { useState } from "react"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/styles"
import Table from "@material-ui/core/Table"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import Box from "@material-ui/core/Box"
import SvgIcon from "@material-ui/core/SvgIcon"
import InputBase from "@material-ui/core/InputBase"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"
import UserEditName from "./UserEditName"
import UserAddEditEmail from "./UserAddEditEmail"
import UserAddEditPhone from "./UserAddEditPhone"
import UserAddEditSocial from "./UserAddEditSocial"
import UserAddEdditAddresses from "./UserAddEditAddresses"
import UserEditUsername from "./UserEditUsername"

import {
  Edit3 as EditIcon,
  Check as CheckIcon,
  Plus as PlusIcon,
  X as RemoveIcon,
} from "react-feather"
import { useMutation } from "@apollo/client"

const UserContactInfo = ({ classes, myProfile }) => {
  const uid = myProfile.person.uid
  const firstName = myProfile.person.firstName
  const lastName = myProfile.person.lastName
  const userEmail = myProfile.email
  const emails = myProfile.person.emailPerson
  const phones = myProfile.person.phonePerson
  const socials = myProfile.person.personSocialMedia
  return (
    <div className={classes.root}>
      <Grid container justify="space-between" spacing={1}>
        <Grid item xs={12}>
          <UserEditName uid={uid} firstName={firstName} lastName={lastName} />
        </Grid>
        <Grid item xs={12}>
          <UserEditUsername userEmail={userEmail} />
        </Grid>
        <Grid item xs={12}>
          <UserAddEditEmail uid={uid} emails={emails} />
        </Grid>
        <Grid item xs={12}>
          <UserAddEditPhone uid={uid} phones={phones} />
        </Grid>
        <Grid item xs={12}>
          <UserAddEditSocial uid={uid} socials={socials} />
        </Grid>
        <Grid item xs={12}>
          <UserAddEdditAddresses uid={uid} />
        </Grid>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 1.0, 0.5),
    minWidth: 225,
    maxWidth: 950,
    boxShadow: "none",
  },
  field: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    // width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  disabled: {
    color: theme.palette.common.black,
    opacity: 1,
  },
  paper: {
    padding: theme.spacing(1),
    minHeight: 220,
  },
  title: {
    // fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

const MY_PROFILE_QUERY = gql`
  query {
    me {
      id
      email
      active
      person {
        uid
        firstName
        lastName
        phonePerson {
          id
          phoneNumber
          phoneType {
            id
            phoneType
          }
        }
        emailPerson {
          id
          emailAddress
        }
        personSocialMedia {
          id
          username
          url
          socialMediaType {
            id
            name
            url
          }
        }
      }
    }
  }
`
export default withStyles(styles)(UserContactInfo)
