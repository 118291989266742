import React, { useState } from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import Loading from "../../../../utils/Loading"
import Error from "../../../../utils/Error"
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardHeader,
  ClickAwayListener,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import ControlPointIcon from "@material-ui/icons/ControlPoint"
import UserAddresses from "./UserAddresses"
import HomeWorkIcon from "@material-ui/icons/HomeWork"

const UserAddEditAddress = ({ classes, uid }) => {
  const [addAddress, setAddAddress] = useState(false)
  const [addresses, setAddresses] = useState([])
  const { data, loading, error } = useQuery(MY_ADDRESSES_QUERY, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      const me = data.me
      setAddresses(me.person.addressPerson)
    },
  })
  if (loading) return <Loading />
  if (error) return <Error />

  const addressList = data.me.person.addressPerson
  if (addressList.length > 0 || addAddress === true)
    return (
      <UserAddresses
        addresses={addresses}
        setAddresses={setAddresses}
        setAddAddress={setAddAddress}
        uid={uid}
      />
    )
  return (
    <div className={classes.root}>
      <Card square variant="outlined" className={classes.mainCard}>
        <CardHeader avatar={<HomeWorkIcon />} title={"Address"} />

        <Box mt={1} width="100%">
          <Button
            color="primary"
            variant="outlined"
            aria-label="add an address"
            size="small"
            onClick={() => setAddAddress(true)}
            startIcon={<ControlPointIcon />}
          >
            Add an address
          </Button>
        </Box>
      </Card>
    </div>
  )
}
const styles = theme => ({
  root: {
    justifyContent: "center",
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    paddingBottom: 40,
    // paddingTop: 80,
  },

  mainCard: {
    width: "100%",
    padding: theme.spacing(2),
  },
})

const MY_ADDRESSES_QUERY = gql`
  query {
    me {
      id
      person {
        addressPerson {
          id
          address {
            uid
            region {
              id
              name
            }
            isOrg
            category {
              id
              name
            }
            attention
            addressLine1
            addressLine2
            addressLine3
            zone
            city
            postalCode
          }
        }
      }
    }
  }
`

export default withStyles(styles)(UserAddEditAddress)
