import { v4 as uuidv4 } from "uuid"

export default function reducer(state, action) {
  switch (action.type) {
    case "UPDATE_COMMENT":
      const updatedComment = {
        ...state.currentComment,

        text: action.payload.text,
        color: action.payload.color,
        canvasTool: action.payload.canvasTool,
      }
      const updatedCommentIndex = state.comments.findIndex(
        c => c.id === state.currentComment.id
      )
      const updatedComments = [
        ...state.comments.slice(0, updatedCommentIndex),
        updatedComment,
        ...state.comments.slice(updatedCommentIndex + 1),
      ]
      return {
        ...state,
        currentComment: {},
        comments: updatedComments,
      }
    case "CLEAN_UP_STATE":
      return {
        ...state,
        comments: [],
        proofId: null,
        requestChanges: false,
        reviewChanges: false,
        reviewDialogOpen: false,
        currentComment: {},
        editing: false,
        canvasTool: "none",
        color: "#666666",
        myName: "",
        previewImage: "",
        idString: "",
        imageApproved: true,
      }
    case "ADD_COMMENT":
      const newComment = {
        id: uuidv4(),
        text: action.payload.text,
        color: action.payload.color,
        canvasTool: action.payload.canvasTool,
      }
      const addedComments = [...state.comments, newComment]
      return {
        ...state,
        comments: addedComments,
      }
    case "CHANGE_CANVAS_TOOL":
      return {
        ...state,
        canvasTool: action.payload,
      }
    case "SET_MY_NAME":
      return {
        ...state,
        myName: action.payload,
      }
    case "CHANGE_COLOR":
      return {
        ...state,
        color: action.payload,
      }
    case "SET_PROOF_ID":
      return {
        ...state,
        proofId: action.payload,
      }
    case "SET_CURRENT_COMMENT":
      return {
        ...state,
        currentComment: action.payload,
      }
    case "SET_PREVIEW_IMAGE":
      return {
        ...state,
        previewImage: action.payload,
      }
    case "TOGGLE_EDITING":
      return {
        ...state,
        editing: !state.editing,
      }
    case "TOGGLE_REQUEST_CHANGES":
      return {
        ...state,
        requestChanges: !state.requestChanges,
      }
    case "TOGGLE_REVIEW_CHANGES":
      return {
        ...state,
        reviewChanges: !state.reviewChanges,
      }
    case "HIDE_REVIEW_CHANGES":
      return {
        ...state,
        reviewChanges: false,
      }
    case "SET_REVIEW_CHANGES":
      return {
        ...state,
        reviewChanges: true,
      }
    case "TOGGLE_REVIEW_DIALOG":
      return {
        ...state,
        reviewDialogOpen: !state.reviewDialogOpen,
      }
    case "SHOW_CONFIRMATION":
      return {
        ...state,
        imageApproved: true,
      }
    case "CLEAR_CONFIRMATION":
      return {
        ...state,
        imageApproved: false,
      }
    case "SET_ID_STRING":
      return {
        ...state,
        idString: action.payload,
      }
    case "REMOVE_COMMENT":
      const filteredComments = state.comments.filter(
        c => c.id !== action.payload.id
      )
      return {
        ...state,
        comments: filteredComments,
      }
    case "SHOW_DISCREPANCY":
      return {
        ...state,
        discrepancy: true,
      }
    default:
      return state
  }
}
