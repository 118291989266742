import React from "react"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/styles"
import Table from "@material-ui/core/Table"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import Box from "@material-ui/core/Box"
import EmailPreferences from "./EmailPreferences"
import MessagingPreferences from "./MessagingPreferences"
import DisableAccount from "./DisableAccount"
import Notifications from "./Notifications"
import CommunicationPreferences from "./CommunicationPreferences"

const UserPreferences = ({ classes, myProfile }) => {
  return (
    <div className={classes.root}>
      {/* <Card square className={classes.paper}> */}
      {/* <CardContent> */}
      <Grid container direction="row" justify="space-between">
        <Grid item xs={12}>
          {/* <Box mt={2}>
                <EmailPreferences />
              </Box> */}
          <Box>
            <CommunicationPreferences myProfile={myProfile} />
          </Box>
          {/* <Box>
            <Notifications />
          </Box>
          <Box mt={2}>
            <MessagingPreferences />
          </Box> */}
          <Divider />
          <Box mt={2}>
            <DisableAccount />
          </Box>
        </Grid>
      </Grid>
      {/* </CardContent> */}
      {/* </Card> */}
    </div>
  )
}

const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
    maxWidth: 950,
    boxShadow: "none",
  },
  paper: {
    padding: theme.spacing(1),
    minHeight: 220,
  },
  title: {
    // fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

export default withStyles(styles)(UserPreferences)
