import React, { useState } from "react"

import { Box, Container, Divider, Tab, Tabs } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"

import MenuBox from "./MenuBox"
import Header from "./Header"
import UserPreferences from "./UserPreferences"
import UserChangePassword from "./UserChangePassword"

import UserContactInfo from "./UserContactInfo"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import Loading from "../../utils/Loading"
import Error from "../../utils/Error"

const UserAccount = ({ classes }) => {
  const [currentTab, setCurrentTab] = useState("profile")
  const tabs = [
    { value: "profile", label: "Profile" },
    { value: "preferences", label: "Preferences" },
    { value: "password", label: "Password" },
  ]
  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }
  const { data, loading, error } = useQuery(MY_ACCOUNT_QUERY, {
    fetchPolicy: "cache-and-network",
  })

  if (loading) return <Loading />
  if (error) return <Error />
  const myProfile = data.me

  return (
    <>
      <div className={classes.root}>
        <Container>
          <Header />
          <Box mt={2}>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              textColor="secondary"
              className={classes.tabs}
            >
              {tabs.map(tab => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Divider />
          {/* <Box>{JSON.stringify(data)}</Box>
          <Divider /> */}
          <Box mt={3}>
            {currentTab === "profile" && (
              <UserContactInfo myProfile={myProfile} />
            )}
            {currentTab === "preferences" && (
              <UserPreferences myProfile={myProfile} />
            )}
            {currentTab === "password" && <UserChangePassword />}
          </Box>
        </Container>
      </div>
    </>
  )
}
const styles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    marginTop: 4,
  },
  tabs: {
    flexGrow: 1,
  },
})

const MY_ACCOUNT_QUERY = gql`
  query {
    me {
      id
      email
      active
      person {
        uid
        firstName
        lastName
        phonePerson {
          id
          phoneNumber
          phoneType {
            id
            phoneType
          }
        }
        emailPerson {
          id
          emailAddress
        }
        personSocialMedia {
          id
          username
          url
          socialMediaType {
            id
            name
            url
          }
        }
      }
      servicePreference {
        id
        emailOptIn
        emailPref {
          id
          emailAddress
        }
        smsOptIn
        smsPref {
          id
          phoneNumber
        }
        phoneOptIn
        phonePref {
          id
          phoneNumber
        }
        pushOptIn
        doNotContact
        serviceSmsOptIn
        servicePushOptIn
        serviceEmailOptIn
        servicePhoneOptIn
        promoSmsOptIn
        promoPushOptIn
        promoEmailOptIn
        promoPhoneOptIn
        promoFrequency
      }
    }
  }
`
export default withStyles(styles)(UserAccount)
