import React, { useState, useContext, useEffect, useRef } from "react"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core"
import UserContext from "../../../context/UserContext"
import NavBar from "./NavBar"
import Header from "../Header"
import Sign from "../../Sign/"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    overflow: "hidden",
  },
  wrapper: {
    paddingTop: 58,
    [theme.breakpoints.up("md")]: {
      marginLeft: 256,
    },
  },
  signInWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
  },
  contentContainer: {
    display: "flex",
    flexGrow: 1,
    overflow: "hidden",
  },
  content: {},
}))

const DashboardLayout = ({ children }) => {
  const { state, dispatch } = useContext(UserContext)
  const isLoggedIn = state.isLoggedIn
  const isVerified = state.isVerified
  const classes = useStyles()
  const isMobileNavOpen = state.isMobileNavOpen

  const isBrowser = () => typeof window !== "undefined"
  const [counting, setCounting] = useState(false)

  const [rand, setRand] = useState(null)
  const timer = useRef(null)

  const delay = 500

  useEffect(() => {
    if (isLoggedIn) {
      setCounting(true)
      setRand(Math.random)
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (counting) {
      timer.current = setTimeout(function () {
        handleLogout()
      }, delay * 1000)
      return () => {
        clearTimeout(timer.current)
      }
    }
  }, [rand])

  const handleReset = () => {
    clearTimeout(timer.current)
    setCounting(true)
    setRand(Math.random)
  }

  useEffect(() => {
    // if (isLoggedIn && isVerified) {
    window.addEventListener("keydown", handleReset)
    window.addEventListener("scroll", handleReset)
    window.addEventListener("click", handleReset)

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", handleReset)
      window.removeEventListener("scroll", handleReset)
      window.removeEventListener("click", handleReset)
    }
    // }
  }, [rand])

  const [handleLogout, { client, loading, error }] = useMutation(
    LOGOUT_MUTATION,
    {
      onCompleted: data => {
        dispatch({ type: "SET_LOGOUT" })
        dispatch({ type: "SET_VERIFIED", payload: false })
        client.resetStore()
        setCounting(false)
      },
    }
  )

  if (isLoggedIn && isVerified)
    return (
      <div>
        <div className={classes.root}>
          <Header
            onMobileNavOpen={() => dispatch({ type: "OPEN_MOBILE_NAV" })}
          />
          <NavBar
            onMobileClose={() => dispatch({ type: "CLOSE_MOBILE_NAV" })}
            openMobile={isMobileNavOpen}
          />
          <div
            className={
              isLoggedIn && isVerified ? classes.wrapper : classes.signInWrapper
            }
          >
            <div className={classes.content}>{children}</div>
          </div>
        </div>
      </div>
    )
  if (!isLoggedIn || !isVerified) {
    return <Sign />
  }
}

DashboardLayout.propTypes = {
  children: PropTypes.any,
}

const LOGOUT_MUTATION = gql`
  mutation {
    deleteTokenCookie {
      deleted
    }
  }
`

export default DashboardLayout
