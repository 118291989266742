import React, { useState, useContext, useRef } from "react"
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import withStyles from "@material-ui/core/styles/withStyles"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import ForwardIcon from "@material-ui/icons/Forward"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import PaletteIcon from "@material-ui/icons/Palette"
import RoomIcon from "@material-ui/icons/Room"
import CreateIcon from "@material-ui/icons/Create"
import { GithubPicker } from "react-color"

import CommentsContext from "../../context"

import useDidMountEffect from "../../../../../utils/hooks/useDidMountEffect"

const CommentForm = ({ classes }) => {
  const { state, dispatch } = useContext(CommentsContext)
  const [color, setColor] = useState("#666666")
  const [showPalette, setShowPalette] = useState(false)
  const [canvasTool, setCanvasTool] = useState("none")
  const [commentText, setCommentText] = useState("")
  const [openAlert, setOpenAlert] = useState(false)
  const [comment, setComment] = useState({
    color: color,
    text: commentText,
    canvasTool: state.canvasTool,
  })

  useDidMountEffect(() => {
    setComment({
      color: color,
      text: commentText,
      canvasTool: canvasTool,
    })
    dispatch({ type: "CHANGE_CANVAS_TOOL", payload: canvasTool })
  }, [canvasTool])

  useDidMountEffect(() => {
    setComment({
      color: color,
      text: commentText,
      canvasTool: canvasTool,
    })
    dispatch({ type: "CHANGE_COLOR", payload: color })
  }, [color])

  useDidMountEffect(() => {
    setComment({
      color: color,
      text: commentText,
      canvasTool: canvasTool,
    })
  }, [commentText])

  const toggleShowPalette = () => {
    setShowPalette(!showPalette)
  }
  const handleHidePalette = () => {
    if (showPalette === true) {
      setShowPalette(false)
    }
  }
  const updateCommentText = event => {
    setCommentText(event.target.value)
  }

  const handleChangeComplete = (color, event) => {
    setColor(color.hex)
  }
  const handleAddComment = event => {
    dispatch({ type: "ADD_COMMENT", payload: comment })
    setCommentText("")
    setColor("#666666")
    setCanvasTool("none")
    setOpenAlert(true)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9}>
          <Paper className={classes.root}>
            <TextField
              id="comment-field"
              label="Comments"
              multiline
              fullWidth
              rows={4}
              value={commentText}
              variant="outlined"
              onChange={updateCommentText}
            />
          </Paper>
        </Grid>
        <Grid item container xs={12} sm={3}>
          <Grid item xs={12}>
            <Box className={classes.root}>
              <IconButton
                className={classes.iconButton}
                aria-label="choose a color"
                onClick={toggleShowPalette}
              >
                <PaletteIcon style={{ color: color }} />
              </IconButton>
              <Divider className={classes.divider} orientation="vertical" />
              <ButtonGroup variant="outlined" color="secondary">
                <IconButton onClick={() => setCanvasTool("arrow")}>
                  <RoomIcon
                    style={
                      canvasTool === "arrow"
                        ? { color: color }
                        : { color: "#41424C" }
                    }
                  />
                </IconButton>
                <IconButton onClick={() => setCanvasTool("circle")}>
                  <RadioButtonUncheckedIcon
                    style={
                      canvasTool === "circle"
                        ? { color: color }
                        : { color: "#41424C" }
                    }
                  />
                </IconButton>
                <IconButton onClick={() => setCanvasTool("pen")}>
                  <CreateIcon
                    style={
                      canvasTool === "pen"
                        ? { color: color }
                        : { color: "#41424C" }
                    }
                  />
                </IconButton>
              </ButtonGroup>
            </Box>
            {showPalette && (
              <>
                <ClickAwayListener onClickAway={handleHidePalette}>
                  <GithubPicker
                    color={color}
                    onChangeComplete={handleChangeComplete}
                  />
                </ClickAwayListener>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<AddCircleIcon style={{ color: color }} />}
              onClick={handleAddComment}
            >
              Add Comment
            </Button>
            <Grid item xs={12}>
              {openAlert && (
                <Alert
                  onClose={() => setOpenAlert(false)}
                  severity="success"
                  variant="outlined"
                  color="secondary"
                >
                  Use this tool to point out the issue on the above image.
                </Alert>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
const styles = theme => ({
  root: {
    margin: theme.spacing(1, 0, 0),
    padding: "10px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
})

export default withStyles(styles)(CommentForm)
