import React, { useContext, useEffect } from "react"
import { Link } from "gatsby"
import CheckoutContext from "../context/CheckoutContext"
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core"
import { withStyles } from "@material-ui/styles"

const SummaryBilling = ({ classes }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const billingAddress = state.orderBilling[0]

  const handleConfirmBilling = () => {
    dispatch({ type: "SET_SELECTED_ADDRESS_TYPE", payload: "billing" })
    dispatch({ type: "SET_ACTIVE_STEP", payload: 2 })
    if (!state.showAddressComponent) {
      dispatch({ type: "TOGGLE_ADDRESS_COMPONENT" })
    }
  }
  useEffect(() => {
    console.log("from order summary")
  }, [])

  return (
    <Box>
      <ButtonBase
        focusRipple
        className={classes.menuButton}
        style={{
          display: "block",
          padding: 3,
        }}
        onClick={handleConfirmBilling}
      >
        <Typography className={classes.title}>
          {billingAddress ? "Billing Address" : ""}
        </Typography>
      </ButtonBase>

      {state.orderBilling.length > 1 || state.multiplePayments === true ? (
        <Typography>
          Multiple Payments
          <br />
          See below for details
        </Typography>
      ) : (
        <Box mt={1} pl={1}>
          <Typography variant="caption">
            {billingAddress ? (
              <>
                {billingAddress.name && billingAddress.name}
                {billingAddress.name && <br />}
                {billingAddress.address.attention &&
                  `attn: ${billingAddress.address.attention}`}
                {billingAddress.address.attention && <br />}
                {billingAddress.address.addressLine1 &&
                  billingAddress.address.addressLine1}
                {billingAddress.address.addressLine1 && <br />}
                {billingAddress.address.addressLine2 &&
                  billingAddress.address.addressLine2}
                {billingAddress.address.addressLine2 && <br />}
                {billingAddress.address.addressLine3 &&
                  billingAddress.address.addressLine3}
                {billingAddress.address.addressLine3 && <br />}
                {billingAddress.address.city}, {billingAddress.address.zone}{" "}
                {billingAddress.address.postalCode} <br />
                {billingAddress.address.region.name}
              </>
            ) : (
              ""
            )}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
const styles = theme => ({
  menuButton: {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "transparent",
      color: theme.palette.link,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
        color: theme.palette.link,
      },
    },
  },

  title: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
  },
})

export default withStyles(styles)(SummaryBilling)
