import React, { useState } from "react"
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
} from "@material-ui/core"

import withStyles from "@material-ui/core/styles/withStyles"

import moment from "moment"

import { Link, navigate } from "gatsby"
import {
  Edit as EditIcon,
  Search as SearchIcon,
  ArrowRight as ArrowRightIcon,
  X as CancelIcon,
} from "react-feather"
function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const PaymentListItem = ({ payment, classes }) => {
  const paymentId = parseInt(payment.id)

  return (
    <Card variant="outlined" className={classes.cardButton}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={3}>
          <Box mb={2} className={classes.id}>
            <Typography variant="body2" color="textSecondary">
              {moment(payment.paymentDate).format("MMM DD, YYYY")}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box mb={2} className={classes.id}>
            <Link
              style={{
                textDecoration: "none",
                color: "textPrimary",
              }}
              variant="subtitle2"
              color="textPrimary"
              to={`/my/orders/${payment.order.id}`}
              state={{ id: `${payment.order.id}` }}
            >
              Order {payment.order.id}
            </Link>
            <Typography variant="body2" color="textSecondary">
              Payment id: {payment.id}
            </Typography>
            {payment.exception && (
              <Typography variant="body2" color="textSecondary">
                Exception: {payment.exception.name}
              </Typography>
            )}
            <>
              <Typography variant="body2" color="textSecondary"></Typography>
            </>
          </Box>
        </Grid>

        <Grid item xs={12} sm={5}>
          <Box mb={1} className={classes.id}>
            <Typography variant="body2" color="textSecondary">
              ${financial(payment.totalPaid)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {payment.method && payment.method.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {payment.billingName ? (
                <>Paid by: {payment.billingName}</>
              ) : (
                <>
                  Paid by: {payment.paidBy.firstName} {payment.paidBy.lastName}
                </>
              )}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,

    // width: 500,
    // flexGrow: 1,
    // margin: 1,
  },
  id: {
    textAlign: "left",
  },
  cardButton: {
    // width: "100%",
    minWidth: 225,
    maxWidth: 950,
    padding: theme.spacing(2),
    borderRadius: "15px",
  },
})

export default withStyles(styles)(PaymentListItem)
