import React, { useState, useEffect } from "react"
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  ClickAwayListener,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  SvgIcon,
  TextField,
  Typography,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import ControlPointIcon from "@material-ui/icons/ControlPoint"
import HomeWorkIcon from "@material-ui/icons/HomeWork"

import EditIcon from "@material-ui/icons/Edit"
import { withStyles } from "@material-ui/styles"

import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"

import Loading from "../../../../utils/Loading"
import Error from "../../../../utils/Error"
import useDidMountEffect from "../../../../utils/hooks/useDidMountEffect"
import {
  regions,
  usaStates,
  canadaProvinces,
  allRegions,
} from "../../../../DropIn/Lists/geography"
const UserAddresses = ({
  classes,
  uid,
  addresses,
  setAddresses,
  setAddAddresses,
}) => {
  const [showForm, setShowForm] = useState(false)

  const [region, setRegion] = useState(1)
  const [regionId, setRegionId] = useState(1)
  const [findMoreRegions, setFindMoreRegions] = useState(false)
  const [regionSetting, setRegionSetting] = useState("United States")

  const [category, setCategory] = useState(1)
  const [categoryId, setCategoryId] = useState(1)

  const [addressUid, setAddressUid] = useState("")
  const [updateAddressUid, setUpdateAddressUid] = useState("")

  const [newArray, setNewArray] = useState([])
  const [personAddressIndex, setPersonAddressIndex] = useState(0)

  const [addressDelUid, setAddressDelUid] = useState("")

  const [attention, setAttention] = useState("")
  const [addressLine1, setAddressLine1] = useState("")
  const [addressLine2, setAddressLine2] = useState("")
  const [addressLine3, setAddressLine3] = useState("")
  const [city, setCity] = useState("")
  const [zone, setZone] = useState("")
  const [postalCode, setPostalCode] = useState("")

  const [isThirdParty, setIsThirdParty] = useState(false)
  const [thirdPartyName, setThirdPartyName] = useState("")

  const [submitting, setSubmitting] = useState(false)
  const [editing, setEditing] = useState(false)

  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState("")

  //  Initial Query: Pulls in Category and Region lists from database
  // const { data, loading, error } = useQuery(ADDRESS_FORM_INFO_QUERY, {
  //   fetchPolicy: "cache-first",
  //   onCompleted: data => {
  //     setAddressCategories(data.addressCategories)

  //   },
  // })
  const addressCategories = [
    { id: 1, name: "Billing" },
    { id: 2, name: "Shipping" },
    { id: 3, name: "Billing and Shipping" },
  ]
  // 0. If no addresses are on the list, show the form instead of a list of addresses.
  useEffect(() => {
    addresses.length === 0 && setShowForm(true)
  }, [])

  // 1. Creates a new address
  const [handleNewAddress, { loading, error }] = useMutation(
    ADD_ADDRESS_MUTATION,
    {
      variables: {
        regionId: region,
        categoryId: category,
        isOrg: false,
        attention: attention,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        addressLine3: addressLine3,
        city: city,
        zone: zone,
        postalCode: postalCode,
      },
      onCompleted: (data, response, errors) => {
        console.log(data)
        setSubmitting(false)
        setAddressUid(data.createAddress.address.uid)
      },
      onError: err => console.error(err),
    }
  )

  const [deleteAddress] = useMutation(DELETE_ADDRESS_MUTATION, {
    variables: {
      uid: addressDelUid,
    },
    onCompleted: () => {
      setAddresses(newArray)
      handleClear()
    },
  })

  // 2. Associates the new address with a person. First the hook, then the mutation.

  useDidMountEffect(() => {
    addressUid.length > 5 && !editing && addPersonAddress()
  }, [addressUid])

  const [addPersonAddress] = useMutation(ADD_PERSON_ADDRESS_MUTATION, {
    variables: {
      addressUid: addressUid,
      name: thirdPartyName,
      isThirdParty: isThirdParty,
    },
    onCompleted: (data, response, errors) => {
      console.log("person address added")
      setMessage(data.createMyAddressPerson.message)

      const addressValue = data.createMyAddressPerson.addressPerson
      setAddresses(prevSelected => [...prevSelected, addressValue])
      setSuccess(true)
      handleClear()
    },
  })

  // 3. Mutation to Update the address
  const [handleUpdateAddress] = useMutation(UPDATE_ADDRESS_MUTATION, {
    variables: {
      uid: updateAddressUid,
      regionId: regionId,
      categoryId: categoryId,
      isOrg: false,
      attention: attention,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      addressLine3: addressLine3,
      city: city,
      zone: zone,
      postalCode: postalCode,
    },
    onCompleted: (data, response, errors) => {
      console.log(data)
      let addressData = data.updateAddress.address
      let newArray = [...addresses]
      newArray[personAddressIndex] = {
        ...newArray[personAddressIndex],
        address: addressData,
      }
      setAddresses(newArray)
      setMessage(data.updateAddress.message)
      setSubmitting(false)
      console.log("updated address")
      console.log(newArray)
      handleClear()
      setSuccess(true)
    },
    onError: err => console.error(err),
  })

  // 4. Listen for a change in the "address to delete" state. If it is valid and new (length > 5), execute mutation to delete the address
  useDidMountEffect(() => {
    addressDelUid.length > 5 && deleteAddress()
  }, [addressDelUid])

  const handleDelete = (address, event) => {
    const filteredArray = addresses.filter(x => x.address.uid !== address.uid)
    setNewArray(filteredArray)
    setAddressDelUid(address.uid)
  }

  // Things to happen when region changes.
  // 1. If region is 3 (other regions), hide the partial region list and show the full list of regions
  // 2. If region is 1 (United States), show USA specific fields on form
  // 3. If region is 2 (Canada), show Canada specific fields on form
  // 4. You can modify this for any country or region. The database is set up to hold generic international addresses. Only the UI changes.

  useDidMountEffect(() => {
    console.log(region)
    if (region == 3) setFindMoreRegions(true)
    region == 1
      ? setRegionSetting("United States")
      : region == 2
      ? setRegionSetting("Canada")
      : setRegionSetting("other")
  }, [region])

  // This puts the form in edit mode.
  // 1. Put the selected address's index value (an integer) in state for later use.
  // 2. Populate the form fields for editing the address.

  const handleEdit = a => {
    let addressIndex = addresses.findIndex(x => x.id == a.id)
    setPersonAddressIndex(addressIndex)
    setUpdateAddressUid(a.address.uid)
    setAttention(a.address.attention)
    setAddressLine1(a.address.addressLine1)
    setAddressLine2(a.address.addressLine2)
    setAddressLine3(a.address.addressLine3)
    setCity(a.address.city)
    setZone(a.address.zone)
    setPostalCode(a.address.postalCode)
    setRegion(a.address.region.id)
    setCategory(a.address.category.id)
    setShowForm(true)
    setEditing(true)
  }

  // Toggles the function to execute when the submit button is selected.
  const handleAddOrUpdateAddress = event => {
    editing ? handleUpdateAddress() : handleNewAddress()
  }

  const handleRegionChange = event => {
    event.persist()
    let value = 0
    if (event.target.value !== 0) {
      value = event.target.value
      console.log(value)
    }
    setRegion(value)
    setRegionId(value)
  }

  const handleCategoryChange = event => {
    event.persist()

    let value = 0
    if (event.target.value !== 0) {
      value = event.target.value
    }

    setCategory(value)
    setCategoryId(value)
  }

  const handlePartyChange = event => {
    setIsThirdParty(event.target.checked)
  }

  // Clear most of the state
  const handleClear = () => {
    setRegionId(1)
    setCategoryId(1)
    setRegion(1)
    setCategory(1)
    setUpdateAddressUid("")
    setAttention("")
    setAddressLine1("")
    setAddressLine2("")
    setAddressLine3("")
    setCity("")
    setZone("")
    setPostalCode("")
    setRegion("")
    setCategory("")
    setAddressDelUid("")
    setEditing(false)
  }

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Card square variant="outlined" className={classes.mainCard}>
      <CardHeader
        avatar={<HomeWorkIcon />}
        title={editing ? "Edit and Update Address" : "Address"}
      />
      <>
        <>
          <Grid container spacing={1}>
            {addresses.map(a => {
              const address = a.address
              return (
                <Grid item xs={12} sm={3} key={address.uid}>
                  <Card
                    className={
                      address.uid === updateAddressUid
                        ? classes.selectedCard
                        : classes.card
                    }
                    variant="outlined"
                    key={address.uid}
                  >
                    <CardContent key={address.uid}>
                      <Typography
                        variant="caption"
                        component="p"
                        key={address.uid}
                      >
                        {address.attention && address.attention}
                        {address.attention && <br />}
                        {address.addressLine1 && address.addressLine1}
                        {address.addressLine1 && <br />}
                        {address.addressLine2 && address.addressLine2}
                        {address.addressLine2 && <br />}
                        {address.addressLine3 && address.addressLine3}
                        {address.addressLine3 && <br />}
                        {address.city}, {address.zone} {address.postalCode}{" "}
                        <br />
                        {address.region.name}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {address.uid === updateAddressUid ? (
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          onClick={handleClear}
                        >
                          Cancel Edit
                        </Button>
                      ) : (
                        <>
                          <Button
                            startIcon={<EditIcon />}
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={e => handleEdit(a)}
                          >
                            Edit
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={e => handleDelete(address)}
                          >
                            Remove
                          </Button>
                        </>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
          {success && message && (
            <Box width="100%" mt={2}>
              <Alert
                onClose={() => {
                  setSuccess(false)
                }}
              >
                {message}
              </Alert>
            </Box>
          )}
        </>
        {showForm ? (
          <Card variant="outlined" className={classes.innerCard}>
            <CardHeader
              title={editing ? "Edit and Update" : "Add an address"}
            />

            <form
              onSubmit={event => {
                event.preventDefault()
                setSubmitting(true)

                handleAddOrUpdateAddress(event)
              }}
            >
              <Grid container spacing={3}>
                {!editing && (
                  <>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isThirdParty}
                            onChange={handlePartyChange}
                            name="thirdParty"
                          />
                        }
                        label="This is a third party address (e.g. friend, family member, colleague)"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Collapse in={isThirdParty} timeout="auto" unmountOnExit>
                        <FormControl fullWidth>
                          <TextField
                            label="Third Party Name"
                            id="thirdParty"
                            variant="outlined"
                            value={thirdPartyName}
                            onChange={event =>
                              setThirdPartyName(event.target.value)
                            }
                          />
                        </FormControl>
                      </Collapse>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Address Category"
                    name="category"
                    variant="outlined"
                    onChange={handleCategoryChange}
                    select
                    // margin="dense"
                    // size="small"
                    fullWidth
                    SelectProps={{ native: true }}
                    value={category}
                  >
                    <option></option>
                    {addressCategories.map(ac => (
                      <option key={ac.id} value={ac.id}>
                        {ac.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Country or Region"
                    variant="outlined"
                    select
                    className={findMoreRegions ? classes.hide : classes.show}
                    value={region}
                    onChange={handleRegionChange}
                    SelectProps={{ native: true }}
                  >
                    <option></option>
                    {regions.map(r => (
                      <option key={r.id} value={r.id}>
                        {r.name}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    select
                    label="More Regions"
                    variant="outlined"
                    className={findMoreRegions ? classes.show : classes.hide}
                    helperText="(required)"
                    value={region}
                    onChange={handleRegionChange}
                    SelectProps={{ native: true }}
                  >
                    {allRegions.map(r => (
                      <option key={r.id} value={r.id}>
                        {r.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                <>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        label="Attention or c/o (optional)"
                        id="attention"
                        variant="outlined"
                        helperText="(not required)"
                        value={attention}
                        onChange={event => setAttention(event.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl required fullWidth>
                      <TextField
                        label="Address"
                        id="addressLine1"
                        variant="outlined"
                        helperText="(required)"
                        value={addressLine1}
                        onChange={event => setAddressLine1(event.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        label={
                          regionSetting === "USA"
                            ? "(Apt, Unit, Box...)"
                            : "Address Line 2"
                        }
                        id="addressLine2"
                        value={addressLine2}
                        onChange={event => setAddressLine2(event.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        className={
                          regionSetting === "USA"
                            ? classes.hide
                            : regionSetting === "Canada"
                            ? classes.hide
                            : classes.show
                        }
                        label="Address Line 3"
                        id="addressLine3"
                        value={addressLine3}
                        onChange={event => setAddressLine3(event.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <FormControl required fullWidth>
                      <TextField
                        label="City"
                        id="city"
                        variant="outlined"
                        value={city}
                        fullWidth
                        onChange={event => setCity(event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Divider />

                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <TextField
                        select
                        className={
                          regionSetting === "other"
                            ? classes.hide
                            : classes.show
                        }
                        label={
                          regionSetting === "United States"
                            ? "State"
                            : regionSetting === "Canada"
                            ? "Province"
                            : "State, Province or Zone"
                        }
                        id="zone"
                        type="text"
                        variant="outlined"
                        helperText="(required)"
                        value={zone}
                        onChange={event => setZone(event.target.value)}
                        SelectProps={{ native: true }}
                      >
                        <option></option>
                        {regionSetting === "United States"
                          ? usaStates.map((us, index) => (
                              <option key={index} value={us.abbreviation}>
                                {us.name}
                              </option>
                            ))
                          : regionSetting === "Canada"
                          ? canadaProvinces.map((ca, index) => (
                              <option key={index} value={ca.abbreviation}>
                                {ca.name}
                              </option>
                            ))
                          : ""}
                      </TextField>

                      <TextField
                        label="State, Province or Zone"
                        id="zone"
                        type="text"
                        variant="outlined"
                        helperText="(required)"
                        className={
                          regionSetting === "other"
                            ? classes.show
                            : classes.hide
                        }
                        value={zone}
                        onChange={event => setZone(event.target.value)}
                        SelectProps={{ native: true }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <FormControl>
                      <TextField
                        label="Postal Code"
                        id="postalCode"
                        type="text"
                        variant="outlined"
                        helperText="(required)"
                        value={postalCode}
                        onChange={event => setPostalCode(event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </>

                <Divider />

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting || !addressLine1.trim()}
                    >
                      {loading ? "Updating..." : editing ? "Update" : "Add New"}
                    </Button>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </Card>
        ) : (
          <Box mt={1} width="100%">
            <Button
              color="primary"
              variant="outlined"
              aria-label="add an address"
              size="small"
              onClick={() => setShowForm(true)}
              startIcon={<ControlPointIcon />}
            >
              Add an address
            </Button>
          </Box>
        )}
      </>
      {/* <Box mt={2}>
        <Button
          color="secondary"
          fullWidth
          size="large"
          variant="outlined"
          onClick={() => setActiveStep(4)}
        >
          Next: Add Social Media
        </Button>
      </Box> */}
    </Card>
  )
}
const styles = theme => ({
  root: {
    justifyContent: "center",
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    paddingBottom: 40,
    paddingTop: 80,
  },
  card: {
    minWidth: 200,
  },
  selectedCard: {
    minWidth: 200,
    border: `4px solid ${theme.palette.divider}`,
  },
  mainCard: {
    width: "100%",
    padding: theme.spacing(1),
  },
  innerCard: {
    marginTop: theme.spacing(2),
    width: "100%",
    padding: theme.spacing(1),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  show: {},
  hide: {
    display: "none",
  },
  // root: {
  //   margin: theme.spacing(0, 0.5, 0.5, 0.5),
  //   minWidth: 225,
  //   boxShadow: "none",
  // },
  // field: {
  //   padding: "2px 4px",
  //   display: "flex",
  //   alignItems: "center",
  //   // width: 400,
  // },
  // input: {
  //   marginLeft: theme.spacing(1),
  //   flex: 1,
  // },
  // iconButton: {
  //   padding: 5,
  // },
  // divider: {
  //   height: 28,
  //   margin: 4,
  // },
  // disabled: {
  //   color: "#000000",
  //   opacity: 1,
  // },
  // paper: {
  //   padding: theme.spacing(1),
  //   minHeight: 220,
  // },
  // title: {
  //   // fontSize: 14,
  // },
  // pos: {
  //   marginBottom: 12,
  // },
})

// const ADDRESS_FORM_INFO_QUERY = gql`
//   query {
//     addressCategories {
//       id
//       name
//     }
//     regions {
//       id
//       name
//     }
//   }
// `
const ADD_ADDRESS_MUTATION = gql`
  mutation(
    $isOrg: Boolean
    $categoryId: Int
    $regionId: Int
    $attention: String
    $addressLine1: String
    $addressLine2: String
    $addressLine3: String
    $zone: String
    $city: String
    $postalCode: String
  ) {
    createAddress(
      isOrg: $isOrg
      categoryId: $categoryId
      regionId: $regionId
      attention: $attention
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      addressLine3: $addressLine3
      zone: $zone
      city: $city
      postalCode: $postalCode
    ) {
      address {
        isOrg
        uid
        region {
          id
          name
        }
        category {
          id
          name
        }
        attention
        addressLine1
        addressLine2
        addressLine3
        zone
        city
        postalCode
      }
    }
  }
`

const ADD_PERSON_ADDRESS_MUTATION = gql`
  mutation($addressUid: String!, $isThirdParty: Boolean, $name: String) {
    createMyAddressPerson(
      addressUid: $addressUid
      isThirdParty: $isThirdParty
      name: $name
    ) {
      addressPerson {
        id
        name
        thirdPartyAddress
        address {
          isOrg
          uid
          region {
            id
            name
          }
          category {
            id
            name
          }

          attention
          addressLine1
          addressLine2
          addressLine3
          zone
          city
          postalCode
        }
        person {
          uid
        }
      }
      message
    }
  }
`

const DELETE_ADDRESS_MUTATION = gql`
  mutation($uid: String!) {
    deleteAddress(uid: $uid) {
      uid
    }
  }
`

const UPDATE_ADDRESS_MUTATION = gql`
  mutation(
    $uid: String!
    $regionId: Int
    $categoryId: Int
    $isOrg: Boolean
    $attention: String
    $addressLine1: String
    $addressLine2: String
    $addressLine3: String
    $zone: String
    $city: String
    $postalCode: String
  ) {
    updateAddress(
      uid: $uid
      regionId: $regionId
      categoryId: $categoryId
      isOrg: $isOrg
      attention: $attention
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      addressLine3: $addressLine3
      city: $city
      zone: $zone
      postalCode: $postalCode
    ) {
      address {
        uid
        isOrg
        region {
          id
          name
          label
          worldRegion
          globalRegion
        }
        category {
          id
          name
        }
        attention
        addressLine1
        addressLine2
        addressLine3
        zone
        city
        postalCode
      }
      message
    }
  }
`
export default withStyles(styles)(UserAddresses)
