import React, { useEffect, useContext, useState } from "react"
import EditOrder from "./EditOrder"
import { Link } from "gatsby"
import AddressForm from "./AddressForm"
import PaymentForm from "./PaymentForm"
import ConfirmAndComplete from "./ConfirmAndComplete"
import SuccessPage from "./SuccessPage"
import OrderSummary from "./OrderSummary"
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import {
  PlusCircle as PlusCircleIcon,
  Truck as ShippingIcon,
  ShoppingCart as ShoppingCartIcon,
  DollarSign as PayIcon,
  CheckCircle as ConfirmIcon,
} from "react-feather"
import CheckoutContext from "./context/CheckoutContext"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import { useCart } from "../Cart"
function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const CheckoutForm = ({ order, checkout, setCheckout, classes }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const [currentTab, setCurrentTab] = useState("shipping")
  const activeStep = state.activeStep

  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    totalItems,
    items,
    totalWeight,
    latestUpdate,
    updateItemQuantity,
    updateItemPrice,
    removeItem,
    emptyCart,
    isOpen,
    closeCart,
  } = useCart()

  const tabs = [
    { id: 0, value: "cart", label: "Cart", icon: <ShoppingCartIcon /> },
    { id: 1, value: "shipping", label: "Shipping", icon: <ShippingIcon /> },
    { id: 2, value: "payment", label: "Pay", icon: <PayIcon /> },
    { id: 3, value: "confirm", label: "Confirm", icon: <ConfirmIcon /> },
  ]
  const handleTabsChange = (event, value) => {
    if (value === "cart") setCheckout(false)
    if (value === "shipping") {
      dispatch({ type: "SET_SELECTED_ADDRESS_TYPE", payload: "shipping" })
      dispatch({ type: "SET_ACTIVE_STEP", payload: 1 })
      if (!state.showAddressComponent)
        dispatch({ type: "TOGGLE_ADDRESS_COMPONENT" })
    }
    if (value === "billing") {
      dispatch({ type: "SET_SELECTED_ADDRESS_TYPE", payload: "billing" })
      dispatch({ type: "SET_ACTIVE_STEP", payload: 2 })
    }
    if (value === "confirm") {
      dispatch({ type: "SET_ACTIVE_STEP", payload: 4 })
      setCurrentTab("confirm")
    }
  }
  useEffect(() => {
    if (activeStep === 1) setCurrentTab("shipping")
    if (activeStep === 2) setCurrentTab("payment")
    if (activeStep === 4) setCurrentTab("confirm")
  }, [activeStep])

  const getStepper = activeStep => {
    switch (activeStep) {
      case 0:
        return <EditOrder />
      case 1:
        return <AddressForm />
      case 2:
        return <PaymentForm />
      case 3:
        return <ConfirmAndComplete />
      case 4:
        return <SuccessPage />
      default:
        return "unknown step"
    }
  }

  return (
    <>
      <Box mt={2}>
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
          textColor="secondary"
          className={classes.tabs}
        >
          {tabs.map(tab => (
            <Tab
              icon={<SvgIcon>{tab.icon}</SvgIcon>}
              label={tab.label}
              aria-label={tab.label}
              key={tab.value}
              value={tab.value}
              className={classes.tab}
            />
          ))}
        </Tabs>
      </Box>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          {activeStep !== 4 && (
            <Card square variant="outlined" className={classes.headerCard}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>Back</Grid>
                <Grid item>
                  <Typography className={classes.addressHeader}>
                    {state.total && `$${financial(state.total)}`}
                  </Typography>
                </Grid>
                <Grid item>Details</Grid>
              </Grid>
            </Card>
          )}

          <Box>{getStepper(activeStep)}</Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
          {activeStep !== 4 && <OrderSummary />}
        </Grid>
      </Grid>
    </>
  )
}
const styles = theme => ({
  root: {
    // flexGrow: 1,
    // margin: 1,
    // width: "100%",
  },
  tab: {
    textTransform: "none",
    fontSize: theme.typography.pxToRem(14),
  },
  container: {
    //   display: "grid",
  },
  headerCard: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  addressHeader: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.link,
  },
})
export default withStyles(styles)(CheckoutForm)
