import React, { useState, Fragment } from "react"
import {
  Box,
  Grid,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core"
import PrintIcon from "@material-ui/icons/Print"
import CloseIcon from "@material-ui/icons/Close"
import moment from "moment"
// import OrderContext from "./context/OrderContext"
const StyledTableCell = withStyles(theme => ({
  head: {
    //   backgroundColor: theme.palette.common.black,
    //   color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    fontWeight: "bold",
  },
}))(TableCell)

const TableCellNoUnderline = withStyles({
  root: {
    borderBottom: "none",
  },
})(TableCell)

const useStyles = makeStyles({
  root: {
    minWidth: 700,
  },
  noUnderLineCell: {
    borderBottom: "none",
  },
  orderBox: {
    float: "right",
  },
  paymentDate: {
    fontSize: "10px",
  },
  orderIdFont: {
    fontSize: "19px",
    fontWeight: "bold",
  },
  businessNameFont: {
    fontSize: "17px",
    fontWeight: "bold",
  },
})

const OrderPrint = ({ setOrderPrintView, orderDetails }) => {
  //   const { state, dispatch } = useContext(OrderContext)
  const classes = useStyles()
  const order = orderDetails
  const items = orderDetails.orderWithItem

  function capitalizeFLetter() {
    var input = document.getElementById("input")
    var x = document.getElementById("div")
    var string = input.value
    x.innerHTML = string.charAt(0).toUpperCase() + string.slice(1)
  }

  const handleClose = () => {
    setOrderPrintView(false)
  }
  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }

  function priceRow(qty, unit) {
    return qty * unit
  }
  return (
    <div className={classes.root}>
      <Box display="block" displayPrint="none">
        <DialogActions>
          <IconButton
            variant="contained"
            color="secondary"
            autoFocus
            onClick={() => window.print()}
          >
            <PrintIcon />
          </IconButton>
          <IconButton
            variant="contained"
            color="secondary"
            autoFocus
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Box>
      <DialogContent>
        <Grid container justify="space-between">
          <Grid item xs={4}></Grid>
          <Grid item xs={3}>
            <Box className={classes.orderBox}>
              <Typography className={classes.orderIdFont}>
                Order {order.id}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box mb={3}>
          <Typography className={classes.businessNameFont}>
            {order.businessUnit.name}
          </Typography>
          <Typography variant="body2">{order.businessUnit.homepage}</Typography>
          <Typography variant="body2">
            {order.businessUnit.primaryEmail}
          </Typography>
        </Box>
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={4}>
            <Box>
              <Typography variant="body2">
                {order.customer.firstName} {order.customer.lastName}
              </Typography>
              {/* {JSON.stringify(order.orderShipping)} */}
              {/* <Typography variant="body2">
                {order.customer.user && order.customer.user.email
                  ? order.customer.user.email
                  : ""}
              </Typography> */}
              {/* <Typography variant="body2">Company Name</Typography>
              <Typography variant="body2">Company Name</Typography> */}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Table size="small">
              <TableRow>
                <TableCellNoUnderline>Order</TableCellNoUnderline>
                <TableCellNoUnderline align="right">
                  {order.id}
                </TableCellNoUnderline>
              </TableRow>
              <TableRow>
                <TableCellNoUnderline>Ordered</TableCellNoUnderline>
                <TableCellNoUnderline align="right">
                  {moment(order.orderDate).format("MMM DD, YYYY")}
                </TableCellNoUnderline>
              </TableRow>
              <TableRow>
                <TableCellNoUnderline>Status</TableCellNoUnderline>
                <TableCellNoUnderline align="right">
                  {order.status && order.status.orderStatus}
                </TableCellNoUnderline>
              </TableRow>
              {order.balanceDue &&
                order.balanceDue > 0 &&
                order.balanceDueDate && (
                  <TableRow>
                    <TableCellNoUnderline>Balance Due</TableCellNoUnderline>
                    <TableCellNoUnderline align="right">
                      {moment(order.balanceDueDate).format("MMM DD, YYYY")}
                    </TableCellNoUnderline>
                  </TableRow>
                )}
            </Table>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Description</StyledTableCell>

                <StyledTableCell align="right">Qty</StyledTableCell>
                <StyledTableCell align="right">Ea</StyledTableCell>
                <StyledTableCell align="right">Total</StyledTableCell>
              </TableRow>
            </TableHead>
            {order.orderWithItem.map(item => {
              const customFields = item.customFields
                ? JSON.parse(item.customFields)
                : {}
              const sortedCustomFields = Object.entries(customFields).sort(
                (a, b) => b[0].localeCompare(a[0])
              )
              function splitCamelCaseToString(s) {
                return s.split(/(?=[A-Z])/).join(" ")
              }
              const customFieldSet = []
              if (sortedCustomFields && sortedCustomFields.length > 0) {
                for (const [key, value] of sortedCustomFields) {
                  let format = (
                    <Fragment key={key}>
                      {/* {(key.charAt(0).toUpperCase() + key.slice(1)).replace(/_/g, " ")}:{" "} */}
                      {isNaN(key)
                        ? key.charAt(0).toUpperCase() +
                          splitCamelCaseToString(key.slice(1)) +
                          ": "
                        : key}
                      {isNaN(value)
                        ? value.charAt(0).toUpperCase() + value.slice(1)
                        : value}
                      {/* {value.charAt(0).toUpperCase() + value.slice(1)} */}
                      {/* {value.charAt(0) + value.slice(1)} */}
                      {/* {value} */}
                      <br />
                    </Fragment>
                  )
                  customFieldSet.push(format)
                }
              }
              return (
                <TableRow>
                  <TableCell>
                    {item.item.name}
                    <br />
                    {item.item.category.name}
                    {item.customFields && <br />}
                    {customFieldSet}
                  </TableCell>
                  <TableCell align="right">{item.qty}</TableCell>
                  <TableCell align="right">
                    ${financial(item.priceEach)}
                  </TableCell>
                  {/* <TableCell align="right">Tax</TableCell> */}
                  <TableCell align="right">
                    ${financial(priceRow(item.qty, item.priceEach))}
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </Box>
        <Box mt={4}>
          <Grid container justify="space-between">
            <Grid item xs={4}>
              <Box p={2}>
                <Typography variant="caption">{order.notes}</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Table size="small">
                <TableRow pb={2}>
                  <TableCellNoUnderline>Subtotal</TableCellNoUnderline>
                  <TableCellNoUnderline align="right">
                    {order.orderSubtotal
                      ? "$" + financial(order.orderSubtotal)
                      : ""}
                  </TableCellNoUnderline>
                </TableRow>
                {order.postageAmt > 0 && (
                  <TableRow pb={2}>
                    <TableCellNoUnderline>Shipping</TableCellNoUnderline>
                    <TableCellNoUnderline align="right">
                      {order.postageAmt
                        ? "$" + financial(order.postageAmt)
                        : ""}
                    </TableCellNoUnderline>
                  </TableRow>
                )}
                {order.discountAmt > 0 && (
                  <TableRow pb={2}>
                    <TableCellNoUnderline>Discount</TableCellNoUnderline>
                    <TableCellNoUnderline align="right">
                      {order.discountAmt
                        ? "$" + financial(order.discountAmt)
                        : ""}
                    </TableCellNoUnderline>
                  </TableRow>
                )}
                {order.taxAmt > 0 && (
                  <TableRow pb={2}>
                    <TableCell>Tax</TableCell>
                    <TableCell align="right">
                      {order.taxAmt ? "$" + financial(order.taxAmt) : ""}
                    </TableCell>
                  </TableRow>
                )}
                {order.orderTotal > 0 && (
                  <TableRow pb={2}>
                    <TableCellNoUnderline>Total</TableCellNoUnderline>
                    <TableCellNoUnderline align="right">
                      {order.orderTotal
                        ? "$" + financial(order.orderTotal)
                        : ""}
                    </TableCellNoUnderline>
                  </TableRow>
                )}
                {order.orderPayment && order.orderPayment.length > 0 && (
                  <>
                    {order.orderPayment.map(pmt => {
                      const paymentCount = order.orderPayment.length
                      let last = order.orderPayment[paymentCount - 1]
                      if (last)
                        return (
                          <TableRow pb={2} key={pmt.id}>
                            <TableCell>
                              Pmt{" "}
                              {pmt.paymentDate
                                ? moment(pmt.paymentDate).format("MMM DD, YYYY")
                                : ""}
                            </TableCell>
                            <TableCell align="right">
                              {pmt.totalPaid
                                ? "$" + financial(pmt.totalPaid)
                                : ""}
                            </TableCell>
                          </TableRow>
                        )
                      else
                        return (
                          <TableRow pb={2} key={pmt.id}>
                            <TableCellNoUnderline>
                              Pmt{" "}
                              {pmt.paymentDate
                                ? moment(pmt.paymentDate).format("MMM DD, YYYY")
                                : ""}
                            </TableCellNoUnderline>
                            <TableCellNoUnderline align="right">
                              {pmt.totalPaid
                                ? "$" + financial(pmt.totalPaid)
                                : ""}
                            </TableCellNoUnderline>
                          </TableRow>
                        )
                    })}
                  </>
                )}

                <TableRow pb={2}>
                  <TableCellNoUnderline>Balance</TableCellNoUnderline>
                  <TableCellNoUnderline align="right">
                    {order.balanceDue
                      ? "$" + financial(order.balanceDue)
                      : "$0.00"}
                  </TableCellNoUnderline>
                </TableRow>
              </Table>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </div>
  )
}

export default OrderPrint
