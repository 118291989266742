import React, { useState, useContext, useEffect } from "react"
import SortContext from "../../../../context/SortContext"

import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import Loading from "../../../Shared/Loading"
import Error from "../../../Shared/Error"

import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core"
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  X as CancelIcon,
} from "react-feather"
import Pagination from "@material-ui/lab/Pagination"

import withStyles from "@material-ui/core/styles/withStyles"
import SampleCard from "./SampleCard"
import NoSampleProofs from "./NoSampleProofs"
const SampleProofs = ({ classes }) => {
  const { state, dispatch } = useContext(SortContext)

  const searchContext = state.search
  const [searchBar, setSearchBar] = useState(searchContext)

  const { loading, error, data } = useQuery(MY_SAMPLE_PROOFS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      page: state.page,
      pageSize: state.pageSize,
      search: state.search,
    },
  })

  useEffect(() => {
    dispatch({ type: "SET_PAGE", payload: 1 })
    dispatch({ type: "SET_PAGE_SIZE", payload: 10 })
    dispatch({ type: "SET_SEARCH", payload: "" })
  }, [])

  const handleChange = (event, value) => {
    dispatch({ type: "SET_PAGE", payload: value })
  }

  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
    setSearchBar("")
  }
  if (loading) return <Loading />
  if (error) return <Error />
  const mySampleProofs = data.mySampleProofs
  const sampleCount = mySampleProofs.count ? mySampleProofs.count : undefined
  const openProofs = mySampleProofs.objects.filter(
    sample => sample.closed === false
  )
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Box mb={1}>
            <Typography variant="caption">
              Found {openProofs.length}{" "}
              {openProofs.length !== 1 ? "open samples" : "open sample/proof"}{" "}
              needing attention
            </Typography>
          </Box>
        </Grid>
        <>
          {openProofs.length > 0 &&
            openProofs.map(openProof => (
              <Grid item xs={12} sm={4} key={openProof.id}>
                <SampleCard sampleProof={openProof} />
              </Grid>
            ))}
        </>
      </Grid>
      <Divider />
      <Grid container spacing={3}>
        <Grid item container justify="space-between" xs={12}>
          <Grid item xs={12} sm={2}>
            <Box mb={1}>
              <Typography variant="caption">
                Found {sampleCount}{" "}
                {sampleCount !== 1 ? "closed samples" : "closed sample/proof"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7}>
            {mySampleProofs.pages > 1 && (
              <Pagination
                count={mySampleProofs.pages}
                page={state.page}
                onChange={handleChange}
                variant="outlined"
                size="medium"
                color="secondary"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            {mySampleProofs.pages > 1 && (
              <Paper
                square
                component="form"
                onSubmit={event => {
                  event.preventDefault()
                  handleSearch(event)
                }}
                className={classes.searchField}
              >
                <InputBase
                  fullWidth
                  className={classes.input}
                  placeholder="Search Items"
                  inputProps={{ "aria-label": "search items" }}
                  onChange={event => setSearchBar(event.target.value)}
                  value={searchBar}
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="clear"
                  onClick={handleClearSearchResults}
                >
                  <CancelIcon />
                </IconButton>
              </Paper>
            )}
          </Grid>
        </Grid>
        <>
          {mySampleProofs.count > 0 &&
            mySampleProofs.objects.map(sampleProof => (
              <Grid item xs={12} sm={6} lg={4} key={sampleProof.id}>
                <SampleCard sampleProof={sampleProof} />
              </Grid>
            ))}
        </>
        <>{mySampleProofs.count === 0 && <NoSampleProofs />}</>
        <Grid item container xs={12} justify="flex-end" alignItems="flex-end">
          <Grid item xs={12} sm={6} lg={4}>
            <Box className={classes.pagination}>
              {mySampleProofs.pages > 1 && (
                <Pagination
                  count={mySampleProofs.pages}
                  page={state.page}
                  onChange={handleChange}
                  variant="outlined"
                  size="medium"
                  color="secondary"
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 4,
  },
  tabs: {},
})

const MY_SAMPLE_PROOFS_QUERY = gql`
  query($page: Int, $pageSize: Int, $search: String) {
    mySampleProofs(page: $page, pageSize: $pageSize, search: $search) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        item {
          id
          name
        }
        status {
          id
          name
        }
        version
        image
        sampleProofUrl
        thumbnailUrl
        carouselImageUrl
      }
    }
  }
`
export default withStyles(styles)(SampleProofs)
