import React, { useContext } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Hidden,
  IconButton,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import {
  Menu as MenuIcon,
  Layers as LayersIcon,
  PlusCircle as PlusCircleIcon,
  Download as DownloadIcon,
  List as ListIcon,
  Printer as PrintIcon,
} from "react-feather"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import ListAltIcon from "@material-ui/icons/ListAlt"
import UserContext from "../../../../context/UserContext"
import withStyles from "@material-ui/core/styles/withStyles"

const Header = ({ classes, orderDetails, setOrderPrintView }) => {
  const { state, dispatch } = useContext(UserContext)

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <SvgIcon
              fontSize="small"
              color="secondary"
              className={classes.icon}
            >
              <LayersIcon />
            </SvgIcon>
            <Typography variant="h5" color="inherit" display="inline">
              Order {orderDetails && orderDetails.id ? orderDetails.id : ""}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => setOrderPrintView(true)}>
              <SvgIcon
                fontSize="small"
                color="secondary"
                className={classes.icon}
              >
                <PrintIcon />
              </SvgIcon>
            </IconButton>
          </Grid>
          <Hidden mdUp>
            <Grid item>
              <IconButton
                onClick={() => dispatch({ type: "TOGGLE_MOBILE_NAV" })}
              >
                <SvgIcon
                  fontSize="small"
                  color="secondary"
                  className={classes.icon}
                >
                  <MenuIcon />
                </SvgIcon>
              </IconButton>
            </Grid>
          </Hidden>
        </Grid>
        {/* </Box> */}
        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum. */}
      </div>
    </>
  )
}

const styles = theme => ({
  root: {
    // display: "flex",
    width: "100%",
    marginTop: theme.spacing(3),
  },
  container: {},
  grow: {
    flexGrow: 1,
  },
  icon: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(-0.5),
  },
  headerText: {
    fontSize: "20px",
  },
})
export default withStyles(styles)(Header)
