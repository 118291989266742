import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
// import { useDispatch } from 'react-redux';
import {
  Box,
  Checkbox,
  Hidden,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
  SvgIcon,
} from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import RefreshIcon from "@material-ui/icons/Refresh"
import MoreIcon from "@material-ui/icons/MoreVert"
import ClearIcon from "@material-ui/icons/Clear"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import ForumIcon from "@material-ui/icons/Forum"
import { Search as SearchIcon, Menu as MenuIcon } from "react-feather"
import MessagesContext from "../context/MessagesContext"

// import { openSidebar } from 'src/actions/mailActions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    height: 50,
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexGrow: 1,
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },
}))

const Toolbar = ({ myConversation }) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(MessagesContext)

  const handleBack = () => {
    dispatch({ type: "SET_PANEL", payload: "conversationList" })
    dispatch({ type: "SET_SELECTED_CONVERSATION_ID", payload: "" })
  }

  return (
    <div className={classes.root}>
      <IconButton onClick={handleBack}>
        <ForumIcon />
      </IconButton>
      <Typography>{myConversation.topic}</Typography>
    </div>
  )
}

Toolbar.propTypes = {
  className: PropTypes.string,
}

export default Toolbar
