import React, { useState, useContext, Fragment } from "react"
import { navigate } from "gatsby"
import withStyles from "@material-ui/core/styles/withStyles"
import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import Typography from "@material-ui/core/Typography"
import FolderIcon from "@material-ui/icons/Folder"
import Card from "@material-ui/core/Card"
import Button from "@material-ui/core/Button"
import { green, purple } from "@material-ui/core/colors"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Box from "@material-ui/core/Box"
import BigBlackButton from "../../../DropIn/BigBlackButton"
import moment from "moment"

// import UserContext from "../../../context/UserContext"

function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const OrderSummaryBox = ({ classes, orderDetails }) => {
  //   const { state, dispatch } = useContext(UserContext)
  const order = orderDetails

  // const tax = order && order.taxAmt ? Number(order.taxAmt) : 0
  // const shipping = order && order.shipNumber(order.postageAmt)
  // const shipping = parseInt(order.postageAmt)
  // const subtotal = Number(order.subtotal)
  // const discount = Number(order.discountAmt)
  // const total = shipping + tax + subtotal - discount
  // const orderDate = moment(order.orderDate).format("MMM Do, YYYY, h:mm a")

  // const totalPaid = order => {
  //   const total = order.orderPayment.reduce(
  //     (prevValue, currentValue) => prevValue + parseInt(currentValue.totalPaid),
  //     0
  //   )
  //   return <>{total}</>
  // }

  return (
    <>
      <Card square className={classes.paper}>
        {order.status && (
          <>
            <Typography variant="body1" className={classes.title}>
              Status
            </Typography>
            <Box ml={1} mt={2}>
              <Typography variant="body1">
                {order.status.orderStatus}
              </Typography>
            </Box>
          </>
        )}
        <Typography variant="body1" className={classes.title}>
          Summary
        </Typography>
        <Box ml={1} mt={2}>
          <Typography variant="body1">
            Order Placed{" "}
            {order.orderDate
              ? moment(order.orderDate).format("MMM Do, YYYY, h:mm a")
              : ""}
          </Typography>
        </Box>

        <TableContainer className={classes.paper}>
          <Table
            className={classes.table}
            aria-label="summary table"
            size="small"
          >
            <TableBody>
              {order.orderSubtotal && order.orderSubtotal > 0 && (
                <>
                  <TableRow>
                    <TableCell>Subtotal</TableCell>
                    <TableCell align="right">
                      ${financial(order.orderSubtotal)}
                    </TableCell>
                  </TableRow>
                </>
              )}
              {order.discountAmt && order.discountAmt > 0 ? (
                <>
                  <TableRow>
                    <TableCell>Tax</TableCell>
                    <TableCell align="right">
                      ${financial(order.discountAmt)}
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                ""
              )}
              {order.postageAmt && order.postageAmt > 0 && (
                <>
                  <TableRow>
                    <TableCell>Shipping</TableCell>
                    <TableCell align="right">
                      ${financial(order.postageAmt)}
                    </TableCell>
                  </TableRow>
                </>
              )}
              {order.taxAmt && order.taxAmt > 0 && (
                <>
                  <TableRow>
                    <TableCell>Tax</TableCell>
                    <TableCell align="right">
                      ${financial(order.taxAmt)}
                    </TableCell>
                  </TableRow>
                </>
              )}
              {order.ordertotal && order.ordertotal > 0 && (
                <>
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell align="right">
                      ${financial(order.orderTotal)}
                    </TableCell>
                  </TableRow>
                </>
              )}

              {order.orderPayment &&
                order.orderPayment.map(payment => {
                  const paymentDate = moment(payment.paymentDate).format(
                    "MMM Do, YYYY"
                  )
                  return (
                    <TableRow key={payment.id}>
                      <TableCell>
                        <Typography variant="body2">
                          Payment <br />
                          {payment.method.name}
                          <br /> {paymentDate}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">
                          ${financial(payment.totalPaid)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              <TableRow>
                <TableCell>Total Payments</TableCell>
                <TableCell align="right">
                  {order.orderPaymentTotal ? (
                    <>${financial(order.orderPaymentTotal)}</>
                  ) : (
                    "$0.00"
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Due</TableCell>
                <TableCell align="right">
                  ${financial(order.orderBalanceDue)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {order.orderBalanceDue > 0 && (
          // <Box className={classes.buttonBox}>
          //   <Button fullWidth variant="contained" color="primary">
          //     Make a Payment
          //   </Button>
          // </Box>
          <BigBlackButton buttonText="Make a Payment" />
        )}
      </Card>
    </>
  )
}

const styles = theme => ({
  root: {
    minWidth: 270,
    // margin: theme.spacing(0, 0.5, 0.5, 0.5),
    // minWidth: "300px",
    // width: "100%",
  },
  title: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(18),
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    // boxShadow: "none",
  },
  buttonBox: {
    width: "100%",
  },
  root: {},
  table: {
    padding: theme.spacing(0),
  },
})

export default withStyles(styles)(OrderSummaryBox)
