import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
// import { useDispatch } from 'react-redux';
import {
  Box,
  Checkbox,
  Hidden,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
  SvgIcon,
} from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import RefreshIcon from "@material-ui/icons/Refresh"
import MoreIcon from "@material-ui/icons/MoreVert"
import ClearIcon from "@material-ui/icons/Clear"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import ForumIcon from "@material-ui/icons/Forum"
import { Search as SearchIcon, Menu as MenuIcon } from "react-feather"
import MessagesContext from "../context/MessagesContext"

// import { openSidebar } from 'src/actions/mailActions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // padding: theme.spacing(2),
    height: 68,
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexGrow: 1,
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },
}))

const Toolbar = ({
  className,
  conversations,
  selectedConversations,
  onDeselectAll,
  onSelectAll,
  ...rest
}) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(MessagesContext)
  const searchState = state.search
  const page = state.page
  const [searchBar, setSearchBar] = useState(searchState)
  const pageSize = state.pageSize
  //   const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 1 })
  }
  const handleClearSearch = () => {
    setSearchBar("")
    dispatch({ type: "SET_SEARCH", payload: "" })
  }
  const handleOpenSidebar = () => {
    dispatch({ type: "OPEN_SIDEBAR" })
  }
  const handlePageChange = (event, value) => {
    dispatch({ type: "SET_PAGE", payload: value })
  }

  const handleCheckboxChange = event =>
    event.target.checked ? onSelectAll() : onDeselectAll()

  const selectedAllConversations =
    selectedConversations === pageSize && pageSize > 0
  const selectedSomeConversations =
    selectedConversations > 0 && selectedConversations < pageSize

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Hidden mdUp>
        <IconButton onClick={handleOpenSidebar}>
          {/* <SvgIcon fontSize="small"> */}
          <ForumIcon />
          {/* </SvgIcon> */}
        </IconButton>
        <Paper
          component="form"
          variant="outlined"
          onSubmit={event => {
            event.preventDefault()
            handleSearch(event)
          }}
        >
          <Input
            className={classes.searchInput}
            disableUnderline
            placeholder="Search"
            value={searchBar}
            onChange={event => setSearchBar(event.target.value)}
          />
          <IconButton type="submit">
            <SvgIcon color="action" fontSize="small">
              <SearchIcon />
            </SvgIcon>
          </IconButton>
          <IconButton onClick={handleClearSearch}>
            <SvgIcon color="action" fontSize="small">
              <ClearIcon />
            </SvgIcon>
          </IconButton>
        </Paper>
      </Hidden>

      <Hidden smDown>
        {/* Save below for later.*/}
        {/* <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectedAllConversations}
            className={classes.checkbox}
            indeterminate={selectedSomeConversations}
            onChange={handleCheckboxChange}
          />
          <Typography variant="h6" color="textPrimary">
            Select all
          </Typography>
        </Box> */}
        <Box flexGrow={1} />
        <Paper
          component="form"
          variant="outlined"
          onSubmit={event => {
            event.preventDefault()
            handleSearch(event)
          }}
          // component={Box}
          // display="flex"
          // alignItems="center"
          // variant="outlined"
          // py={0.5}
          // px={1}
        >
          <Input
            className={classes.searchInput}
            disableUnderline
            // Maybe A/B test this placeholder string later
            placeholder="Search Chats"
            // placeholder="Search Conversations"
            value={searchBar}
            onChange={event => setSearchBar(event.target.value)}
          />
          <IconButton type="submit">
            <SvgIcon color="action" fontSize="small">
              <SearchIcon />
            </SvgIcon>
          </IconButton>
          <IconButton onClick={handleClearSearch}>
            <SvgIcon color="action" fontSize="small">
              <ClearIcon />
            </SvgIcon>
          </IconButton>
        </Paper>
        <Tooltip title="Refresh">
          {/* <IconButton onClick={() => handleRefresh}> */}
          <IconButton>
            <RefreshIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <Box display="flex" alignItems="center">
          <Pagination
            count={conversations.pages}
            page={page}
            onChange={handlePageChange}
            size="small"
          />
          <Tooltip title="More options">
            <IconButton onClick={handleOpenMenu}>
              <MoreIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Menu
            id="page-options"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem
              onClick={() => dispatch({ type: "SET_PAGE_SIZE", payload: 10 })}
            >
              10
            </MenuItem>
            <MenuItem
              onClick={() => dispatch({ type: "SET_PAGE_SIZE", payload: 25 })}
            >
              25
            </MenuItem>
            <MenuItem
              onClick={() => dispatch({ type: "SET_PAGE_SIZE", payload: 50 })}
            >
              50
            </MenuItem>
          </Menu>
          {/* {count > 0 && (
            <>
              <Typography noWrap variant="body2" color="textSecondary">
                1 - {count} of {count}
              </Typography>
              <Tooltip title="Previous page">
                <IconButton>
                  <KeyboardArrowRightIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          )} */}
        </Box>
      </Hidden>
    </div>
  )
}

Toolbar.propTypes = {
  className: PropTypes.string,
  onDeselectAll: PropTypes.func,
  onSelectAll: PropTypes.func,
  selectedConversations: PropTypes.number.isRequired,
}

Toolbar.defualtProps = {
  onDeselectAll: () => {},
  onSelectAll: () => {},
}

export default Toolbar
