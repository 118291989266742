import React, { useState, useContext, useEffect } from "react"

import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import Loading from "../../Shared/Loading"
import Error from "../../Shared/Error"
import SignBackIn from "../../SignBackIn"
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  X as CancelIcon,
} from "react-feather"
import Pagination from "@material-ui/lab/Pagination"

import UserItemCard from "./UserItemCard"
import UserOrderItemCard from "./UserOrderItemCard"

import SortContext from "../../../context/SortContext"

import NoPrivateItems from "./NoPrivateItems"
import NoOrderItems from "./NoOrderItems"

const UserItemList = ({
  classes,
  setCurrentTab,
  myPrivateItems,
  setMyPrivateItems,
  myOrderItems,
  setMyOrderItems,
}) => {
  const { state, dispatch } = useContext(SortContext)
  const searchContext = state.search
  const page = state.page
  const pageSize = state.pageSize
  const itemCount = myPrivateItems.count ? myPrivateItems.count : undefined
  const orderItemCount = myOrderItems.count ? myOrderItems.count : undefined
  const [searchBar, setSearchBar] = useState(searchContext)

  useEffect(() => {
    dispatch({ type: "SET_PAGE", payload: 1 })
    dispatch({ type: "SET_PAGE_SIZE", payload: 10 })
    dispatch({ type: "SET_SEARCH", payload: "" })
  }, [])

  const handleChange = (event, value) => {
    dispatch({ type: "SET_PAGE", payload: value })
  }
  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
    setSearchBar("")
  }

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3} justify="space-between">
          <Grid item xs={12} sm={2}>
            {/* <Box mb={1}>
              <Typography variant="caption">
                {!itemCount && !orderItemCount ? (
                  "no items found"
                ) : (
                  <>
                    {itemCount >= 1 ||
                      (orderItemCount >= 1 &&
                        `Found ${itemCount >= 1 && itemCount} `)}
                    {itemCount !== 1
                      ? "private items"
                      : itemCount === 1
                      ? "private item"
                      : ""}{" "}
                    {itemCount >= 1 && "and "}
                    {orderItemCount >= 1 && orderItemCount}{" "}
                    {orderItemCount !== 1
                      ? "order items"
                      : orderItemCount === 1
                      ? "order item"
                      : ""}
                    .
                  </>
                )}
              </Typography>
            </Box> */}
          </Grid>
          <Grid item xs={12} sm={7}>
            {myPrivateItems.pages > 1 && (
              <Pagination
                count={myPrivateItems.pages}
                page={myPrivateItems.page}
                onChange={handleChange}
                hideNextButton={!myPrivateItems.hasNext}
                hidePrevButton={!myPrivateItems.hasPrev}
                shape="rounded"
                variant="outlined"
                size="medium"
                color="secondary"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            {myPrivateItems.pages > 1 && (
              <Paper
                variant="outlined"
                square
                component="form"
                onSubmit={event => {
                  event.preventDefault()
                  handleSearch(event)
                }}
                className={classes.searchField}
              >
                <InputBase
                  fullWidth
                  className={classes.input}
                  placeholder="Search Items"
                  inputProps={{ "aria-label": "search items" }}
                  onChange={event => setSearchBar(event.target.value)}
                  value={searchBar}
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="clear"
                  onClick={handleClearSearchResults}
                >
                  <CancelIcon />
                </IconButton>
              </Paper>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {myPrivateItems.count > 0 && (
            // <NoPrivateItems setCurrentTab={setCurrentTab} />
            <Typography variant="h6" gutterBottom>
              Exclusive, private and customized items
            </Typography>
          )}
        </Grid>
        <Grid container spacing={3}>
          {myPrivateItems.count > 0 &&
            myPrivateItems.objects.map(pi => (
              <Grid item xs={12} sm={4} md={6} key={pi.item.id}>
                <UserItemCard pi={pi} />
              </Grid>
            ))}

          <Grid item xs={12}>
            {myOrderItems.count > 0 && (
              <Typography>Items from your orders</Typography>
            )}
          </Grid>
          {myOrderItems.count > 0 &&
            myOrderItems.objects.map(oi => (
              <Grid item xs={12} sm={4} md={6} key={oi.item.id}>
                <UserOrderItemCard oi={oi} />
              </Grid>
            ))}
        </Grid>
        {/* </Grid> */}
      </div>
    </>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "grid",
  },
  pagination: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "spaceBetween",
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
})

export default withStyles(styles)(UserItemList)
