import React, { useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Button,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  PlusCircle as PlusCircleIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
} from "react-feather"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import CommentsContext from "./context"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  action: {
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}))

const Header = ({ sampleProof, previewChanges, className, ...rest }) => {
  const classes = useStyles()
  const itemId = sampleProof.item.id
  const { state, dispatch } = useContext(CommentsContext)
  return (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={3} justify="space-between" {...rest}>
        <Grid item>
          {/* <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link variant="body1" color="inherit" to="/my" component={Link}>
            My Account
          </Link>
          <Link variant="body1" color="inherit" to="/my/items" component={Link}>
            Items
          </Link>
          <Typography variant="body1" color="textPrimary">
            Item #
          </Typography>
          <Typography variant="body1" color="textPrimary">
            Proofs and Samples
          </Typography>
        </Breadcrumbs> */}

          <Typography variant="h5" color="inherit">
            Proofs and Samples
          </Typography>
          <Box pl={2}>
            <Typography variant="body1" color="inherit">
              <strong>
                Item {itemId}
                {sampleProof.item && sampleProof.item.name
                  ? `: ${sampleProof.item.name}`
                  : ""}
              </strong>
            </Typography>{" "}
            <Typography variant="body2" color="inherit">
              Version {sampleProof.version}
            </Typography>
            <Typography variant="body2" color="inherit" gutterBottom>
              Status: {sampleProof.status.name}
            </Typography>
            {state.reviewChanges ? (
              <></>
            ) : state.discrepancy ? (
              <Typography variant="body2" color="inherit">
                <strong>Directions:</strong> Please wait while we review your
                comments
              </Typography>
            ) : (
              <Typography variant="body2" color="inherit">
                <strong>Directions:</strong> Check below to approve this image.
                Or, use the form and visual tools at the bottom of this page to
                request changes.
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            className={classes.action}
            size="small"
            component={Link}
            to={`/my/items/pi/${itemId}`}
            state={{ id: itemId }}
            startIcon={<ArrowBackIcon />}
          >
            Back to Item
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
