import React, { useState } from "react"
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core"

import withStyles from "@material-ui/core/styles/withStyles"

import AddCircleIcon from "@material-ui/icons/AddCircle"
import RoomIcon from "@material-ui/icons/Room"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import PaletteIcon from "@material-ui/icons/Palette"
import CreateIcon from "@material-ui/icons/Create"

import HelpIcon from "@material-ui/icons/Help"

const CommentInstructions = ({ classes }) => {
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <div className={classes.root}>
      <Divider className={classes.divider} />
      <Grid container>
        <Grid item container justify="space-between">
          <Grid item>
            <Typography variant="h5">Request Changes</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              startIcon={<HelpIcon />}
              onClick={handleExpandClick}
            >
              {expanded ? "Hide Help" : "Help"}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Card>
                <CardContent>
                  <Typography variant="body1">
                    Please take a close look at the above image. If this is not
                    what you expected, you may request a change by adding a
                    comment in the box below. First, select a color for your
                    comment. It can be any color from the palette. Then, pic the
                    pointer, circle, or pen tool to point out the part of the
                    image you're writing about in your comment. If possible,
                    create a new comment with a different color for each issue
                    to help us stay organized.
                  </Typography>
                  <Typography variant="body1">
                    Move the tool around on the image after you click "Add
                    Comment".
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <PaletteIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="The Palette"
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              Choose a color for your comment. This is important
                              if you see multiple issues and want to use more
                              than one arrow or circle to point out the issue.
                              Pick a contrasting color that won't blend in with
                              the image.
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <RoomIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Pointer"
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              It might help to point out the issue on the image.
                              Use the pointer tool to point to the problem. If
                              you have more than one arrow, make sure they are
                              different colors and that the arrow colors match
                              the corresponding comment color.
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <RadioButtonUncheckedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Circle"
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              It might make sense to circle the issue. Drag and
                              resize the circle so we can see what part of the
                              artwork needs to change.
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CreateIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Pen"
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              Choose the pen tool to use your mouse or finger to
                              draw a shape. Please type any comments instead of
                              scribbling them on the image with this tool.
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon>
                        <AddCircleIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Add Now"
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              Select this button to add your comment. This will
                              also activate the corresponding pointer, circle or
                              pen tool over the image.
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  </List>

                  <Typography paragraph variant="body1">
                    If you choose a pointer or circle tool, it will appear on
                    the image after you add your comment.
                  </Typography>
                  <Typography paragraph variant="body1">
                    The circle, pointer and pen tools are optional. Depending on
                    the issue, it might make sense to simply add a text comment.
                  </Typography>
                  <Typography paragraph variant="body1">
                    Add as many comments as necessary. When you're done, choose
                    the "Review and Submit Changes" button at the bottom of this
                    page.
                  </Typography>
                  <Typography paragraph variant="body1">
                    Warning: Leaving or refreshing this page before submitting
                    your request will reset your comments!
                  </Typography>
                </CardContent>
              </Card>
            </Collapse>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(1, 0, 2),
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
})
export default withStyles(styles)(CommentInstructions)
