import React, { useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Hidden,
  IconButton,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  Menu as MenuIcon,
  Layers as LayersIcon,
  PlusCircle as PlusCircleIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
} from "react-feather"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import UserContext from "../../../context/UserContext"
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

function Header({ className, ...rest }) {
  const classes = useStyles()
  const { state, dispatch } = useContext(UserContext)

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <SvgIcon fontSize="small" color="secondary" className={classes.icon}>
          <LayersIcon />
        </SvgIcon>
        <Typography variant="h5" color="inherit" display="inline">
          Orders
        </Typography>
      </Grid>
      <Hidden mdUp>
        <Grid item>
          <IconButton onClick={() => dispatch({ type: "TOGGLE_MOBILE_NAV" })}>
            <SvgIcon
              fontSize="small"
              color="secondary"
              className={classes.icon}
            >
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Grid>
      </Hidden>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
