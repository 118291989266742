import React from "react"

const CommentsContext = React.createContext({
  proofId: null,
  requestChanges: false,
  reviewChanges: false,
  // reviewDialogOpen: false,
  comments: [
    // { id: 1, text: "Change Color", color: "#0693E3", canvasTool: "circle" },
    // { id: 2, text: "Fix this spot", color: "#FCB900", canvasTool: "none" },
    // { id: 3, text: "Change border", color: "#9900EF", canvasTool: "none" },
  ],
  currentComment: {},
  editing: false,
  canvasTool: "arrow",
  color: "#ff0000",
  myName: "",
  previewImage: "",
  idString: "",
  imageApproved: false,
  discrepancy: false,
})

export default CommentsContext
