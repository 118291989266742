import React, { useState, useEffect, useContext, useRef } from "react"

import { Link } from "gatsby"

import moment from "moment"
// import Markdown from "react-markdown/with-html"
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  makeStyles,
  Hidden,
  Input,
  Paper,
  SvgIcon,
} from "@material-ui/core"

// import { useSnackbar } from "notistack"

import AddPhotoIcon from "@material-ui/icons/AddPhotoAlternate"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import { Send as SendIcon } from "react-feather"

import PerfectScrollbar from "react-perfect-scrollbar"
import MoreIcon from "@material-ui/icons/MoreVert"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ReplyIcon from "@material-ui/icons/ReplyOutlined"
import ReplyAllIcon from "@material-ui/icons/ReplyAllOutlined"
import DeleteIcon from "@material-ui/icons/DeleteOutlined"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
import getInitials from "../../../utils/getInitials"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import SortContext from "../../../../context/SortContext"
import Loading from "../../../Shared/Loading"
import Error from "../../../Shared/Error"
import ConversationPersonSearch from "./ConversationPersonSearch"
import MessagesContext from "../context/MessagesContext"
import { useSiteMetadata } from "../../../utils/hooks/useSiteMetadata"
// import FirstMessage from "./FirstMessage"
// import MessageAdd from "./MessageAdd"
// import { getMail } from 'src/actions/mailActions';
import Toolbar from "./Toolbar"
import { SettingsBluetoothSharp } from "@material-ui/icons"
// import MailReply from './MailReply';

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    // display: "flex",
    // height: "100%",
    // flexDirection: "column",
    // overflowY: "auto",
  },
  avatar: {
    height: 35,
    width: 35,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.accountLink,
  },
  date: {
    whiteSpace: "nowrap",
  },
  message: {
    color: theme.palette.text.secondary,
    "& > p": {
      ...theme.typography.body1,
      marginBottom: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 24,
  },
  fileInput: {
    display: "none",
  },
  topic: {
    margin: theme.spacing(0, 3, 0, 3),
  },
  makeMessage: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    marginTop: "30px",
    marginBottom: "40px",
  },
}))

const ConversationNew = () => {
  const classes = useStyles()
  const { state, dispatch } = useContext(MessagesContext)
  const [addPeople, setAddPeople] = useState(false)
  const [inviteList, setInviteList] = useState([])
  const [categories, setCategories] = useState([])
  const [sbus, setSbus] = useState([])
  const { sbuId } = useSiteMetadata()
  const [invitedListIds, setInvitedListIds] = useState([])
  const [category, setCategory] = useState(categories[0])
  const [conversationId, setConversationId] = useState("")
  const [conversation, setConversation] = useState("")
  const [okGo, setOkGo] = useState(false)
  const [topic, setTopic] = useState("")
  const messagesRef = useRef(null)
  const categoryId = category ? category.id : 1

  // const { enqueueSnackbar } = useSnackbar()
  const fileInputRef = useRef(null)
  const [body, setBody] = useState("")
  const [preview, setPreview] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [fileError, setFileError] = useState("")
  const [file, setFile] = useState("")

  const { data, loading, error } = useQuery(INFO_QUERY, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      setCategories(data.serviceCategories)
      setSbus(data.sbus)
    },
  })

  const [createConversation] = useMutation(NEW_CONVERSATION_MUTATION, {
    variables: {
      categoryId: categoryId,
      topic: topic,
      invitedListIds: invitedListIds,
      sbuId: sbuId,
      topic: topic,
    },
    onCompleted: data => {
      setConversation(data.createConversation.conversation)
      setConversationId(data.createConversation.conversation.uuid)

      dispatch({
        type: "SET_SELECTED_CONVERSATION_ID",
        payload: data.createConversation.conversation.uuid,
      })
      setOkGo(true)
    },
  })
  //

  const [sendNewMessage] = useMutation(CREATE_CONVERSATION_MESSAGE, {
    variables: {
      conversationId: conversationId,
      messageFile: file,
      messageBody: body,
    },
    onCompleted: data => {
      const message = data.createConversationMessage.message
      console.log(message)
      setConversationId("")
      dispatch({ type: "SET_PANEL", payload: "conversationSelected" })
      setOkGo(false)
      setBody("")
      setPreview("")
      setFile("")
      setSubmitting(false)
    },
  })

  useEffect(() => {
    setInvitedListIds([])
    {
      inviteList.map(invited =>
        setInvitedListIds(ids => [...ids, parseInt(invited.id)])
      )
      console.log(invitedListIds)
    }
  }, [inviteList])

  useEffect(() => {
    if (okGo && conversationId.length > 5) {
      sendNewMessage()
    }
  }, [okGo])

  function scrollMessagesToBottom() {
    if (messagesRef.current) {
      // eslint-disable-next-line no-underscore-dangle
      messagesRef.current._container.scrollTop =
        messagesRef.current._container.scrollHeight
    }
  }
  // useEffect(() => {
  //   if (conversation) {
  //     scrollMessagesToBottom()
  //   }
  //   // eslint-disable-next-line
  // }, [messageList])

  const handleBack = () => {
    dispatch({ type: "SET_SELECTED_CONVERSATION_ID", payload: "" })
    dispatch({ type: "SET_PANEL", payload: "conversationList" })
  }

  const handleFirstMessage = () => {
    console.log("first message handler")
  }
  if (loading) return <Loading />
  if (error) return <Error />

  const handleRemoveInvite = invited => {
    let filteredArray = inviteList.filter(x => x.id !== invited.id)
    setInviteList(filteredArray)
  }

  const handleChange = event => {
    event.persist()
    setBody(event.target.value)
  }

  const handleAttach = () => {
    fileInputRef.current.click()
  }

  const handleImageChange = event => {
    const selectedFile = event.target.files[0]
    const fileSizeLimit = 10000000 // 10mb

    const imgPreview = URL.createObjectURL(selectedFile)

    if (selectedFile && selectedFile.size > fileSizeLimit) {
      setFileError(`${selectedFile.name}: File size too large`)
    } else {
      setPreview(imgPreview)
      setFile(selectedFile)
      setFileError("")
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12}>
          <Toolbar />
          <Divider />
        </Grid>

        {/* Todo: If user is in an organization, allow user to copy org members by name. */}

        {/* <Grid item container xs={12}>
          <PerfectScrollbar>
            <Box p={1} display="flex" justifyContent="flex-start">
             

              {inviteList.map(invited => (
                <Box key={invited.id} ml={1}>
                  <Chip
                    label={`${invited.person.firstName} ${invited.person.lastName}`}
              
                    size="small"
                    clickable
                    variant="outlined"
               
                    color="secondary"
                    clickable
                  />
                </Box>
              ))}
            </Box>
          </PerfectScrollbar>
        </Grid> */}
        <Grid item container spacing={3} className={classes.topic}>
          <Grid item xs={12}>
            <Typography>
              Have a question about an order? Curious about a new product? Start
              a new conversation with us by sending a message here.
            </Typography>
            <Typography>
              1. Enter a short topic name (like an email "subject")
            </Typography>
            <Typography>
              2. Select an appropriate department (ie "Customer Service")
            </Typography>
            <Typography>
              3. Start off the conversation by leaving a message with your
              question, idea, or comment.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <TextField
              label="Topic (30 chars max)"
              value={topic}
              onChange={event => setTopic(event.target.value)}
              fullWidth
              inputProps={{ maxLength: 30 }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              select
              fullWidth
              label="Category"
              variant="outlined"
              value={category}
              SelectProps={{ native: true }}
              onChange={event => setCategory(event.target.value)}
            >
              {categories &&
                categories.map(cat => (
                  <option key={cat.id} value={cat}>
                    {cat.name}
                  </option>
                ))}
            </TextField>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box className={classes.makeMessage}>
              <Hidden smDown>
                <Avatar
                  alt="Person"
                  // src={user.avatar}
                />
              </Hidden>
              <Paper
                variant="outlined"
                component={Box}
                flexGrow={1}
                ml={2}
                p={1}
              >
                <Input
                  className={classes.input}
                  disableUnderline
                  fullWidth
                  multiline
                  value={body}
                  onChange={handleChange}
                  placeholder="Leave a message"
                />
              </Paper>
              <Tooltip title="Send">
                <span>
                  <IconButton color="secondary" onClick={createConversation}>
                    <SvgIcon fontSize="small">
                      <SendIcon />
                    </SvgIcon>
                  </IconButton>
                </span>
              </Tooltip>
              <Divider className={classes.divider} />
              <Tooltip title="Attach photo">
                <span>
                  <IconButton edge="end" onClick={handleAttach}>
                    <AddPhotoIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <input
                className={classes.fileInput}
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </Box>
            {/* <FirstMessage
        okGo={okGo}
        setOkGo={setOkGo}
        conversationId={conversationId}
        setConversationId={setConversationId}
        conversation={conversation}
        createConversation={createConversation}
        setSelectedConversation={setSelectedConversation}
        setPanel={setPanel}
      /> */}
          </Grid>
        </Grid>
      </Grid>
      {/* <ConversationPersonSearch
        addPeople={addPeople}
        setAddPeople={setAddPeople}
        inviteList={inviteList}
        setInviteList={setInviteList}
      /> */}
    </div>
  )
}

const INFO_QUERY = gql`
  query {
    serviceCategories {
      id
      name
      description
    }
    sbus {
      id
      name
    }
  }
`

const NEW_CONVERSATION_MUTATION = gql`
  mutation (
    $topic: String
    $categoryId: Int
    $sbuId: Int
    $invitedListIds: [Int]
  ) {
    createConversation(
      topic: $topic
      categoryId: $categoryId
      sbuId: $sbuId
      invitedListIds: $invitedListIds
    ) {
      conversation {
        uuid
        topic
        category {
          id
          name
        }
        startedBy {
          id
        }
        archived
        archivedDate
        startDate
        participants {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        invited {
          id
          email
          person {
            uid
            firstName
            lastName
          }
        }
      }
    }
  }
`
const CREATE_CONVERSATION_MESSAGE = gql`
  mutation (
    $conversationId: String!
    $messageBody: String!
    $messageFile: Upload
  ) {
    createConversationMessage(
      conversationId: $conversationId
      messageFile: $messageFile
      messageBody: $messageBody
    ) {
      message {
        uuid
        messageFile
        messageFrom {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        sent
        message
        messageFileUrl
        viewedMessage {
          id
          viewedDate
          viewer {
            id
            person {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`
export default ConversationNew
