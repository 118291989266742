import React, { useState, useContext } from "react"
import {
  Card,
  CardContent,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core"
import Toolbar from "./Toolbar"
import ConversationItem from "./ConversationItem"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import MessagesContext from "../context/MessagesContext"
import Loading from "../../../Shared/Loading"
import Error from "../../../Shared/Error"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    backgroundColor: theme.palette.background.dark,
    height: "100%",
  },
}))

const InvitesList = () => {
  const { state, dispatch } = useContext(MessagesContext)

  const classes = useStyles()
  const page = state.page ? state.page : 1
  const pageSize = state.pageSize ? state.pageSize : 15
  const search = state.search ? state.search : ""

  const [selectedConversations, setSelectedConversations] = useState([])
  const [conversations, setConversations] = useState([])

  const { data, loading, error } = useQuery(NEW_INVITES_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      page: page,
      pageSize: pageSize,
      search: search,
      newInvitesFilter: true,
    },
    onCompleted: data => {
      setConversations(data.unseenInvites)
      dispatch({ type: "INVITES_COUNT", payload: data.unseenInvites.count })
    },
  })

  const handleSelectOneConversation = convId => {
    setSelectedConversations(prevSelectedConversations => {
      if (!prevSelectedConversations.includes(convId)) {
        return [...prevSelectedConversations, convId]
      }
      return prevSelectedConversations
    })
  }

  const handleDeselectOneConversation = convId => {
    setSelectedConversations(prevSelectedConversations =>
      prevSelectedConversations.filter(c => c.uuid !== convId)
    )
  }

  const handleSelectAllConversations = () => {
    setSelectedConversations(conversations.objects.map(c => c.uuid))
  }
  const handleDeselectAllConversations = () => {
    setSelectedConversations([])
  }

  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <div className={classes.root}>
      <Toolbar
        onDeselectAll={handleDeselectAllConversations}
        onSelectAll={handleSelectAllConversations}
        selectedConversations={selectedConversations.length}
        conversations={conversations}
      />
      <Divider />
      {conversations &&
        conversations.objects &&
        conversations.objects.map(c => {
          const conv = c
          const convId = c.uuid
          return (
            <ConversationItem
              conv={conv}
              key={convId}
              onDeselect={() => handleDeselectOneConversation(convId)}
              onSelect={() => handleSelectOneConversation(convId)}
              selected={selectedConversations.includes(convId)}
            />
          )
        })}
      {conversations.count === 0 && (
        <Card>
          <CardContent>
            <Typography variant="body1">No new invites</Typography>
          </CardContent>
        </Card>
      )}
    </div>
  )
}

const NEW_INVITES_QUERY = gql`
  query($page: Int, $pageSize: Int, $search: String) {
    unseenInvites(page: $page, pageSize: $pageSize, search: $search) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        uuid
        topic
        startDate
        conversationMessage {
          uuid
        }
        category {
          id
          name
        }
        startedBy {
          person {
            firstName
            lastName
          }
        }
      }
    }
  }
`
export default InvitesList
