import React, { useState, useContext, useEffect } from "react"
import {
  Grid,
  Box,
  Button,
  ButtonBase,
  Card,
  ClickAwayListener,
  Container,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import GetShipping from "../../GetShipping"
import CalcTaxShip from "../CalcTaxShip"
import CheckoutContext from "../context/CheckoutContext"
import { useCart } from "../../Cart"
import { useSiteMetadata } from "../../../../utils/hooks/useSiteMetadata"
import { useSalesUseTaxData } from "../../../../utils/hooks/useSalesUseTaxData"
import useDidMountEffect from "../../../../utils/hooks/useDidMountEffect"

function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const SummaryTotals = ({ classes }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const postalCode = state.spc
  const total = state.total
  const [shipping, setShipping] = useState(null)
  const [shippingIsUpdated, setShippingIsUpdated] = useState(false)
  // const [total, setTotal] = useState(0)
  const { sbuId } = useSiteMetadata()
  const { edges } = useSalesUseTaxData()
  const [taxRate, setTaxRate] = useState(0.0)
  const [tax, setTax] = useState(0)
  const [enterCode, setEnterCode] = useState(false)
  const [taxDescription, setTaxDescription] = useState("")
  const [taxName, setTaxName] = useState("")

  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    totalItems,
    items,
    totalWeight,
    latestUpdate,
    updateItemQuantity,
    updateItemPrice,
    removeItem,
    emptyCart,
    isOpen,
    closeCart,
  } = useCart()

  const subtotal = cartTotal

  useEffect(() => {
    // stc stands for sales tax code
    let currentTax = edges.find(edge => edge.node.id == state.stc)
    console.log(currentTax)
    setTaxRate(currentTax.node.rate)
    setTaxDescription(currentTax.node.description)
    setTaxName(currentTax.node.name)
    console.log(taxRate)
    calcTotal()
  }, [cartTotal, postalCode])

  useDidMountEffect(() => {
    let currentTax = edges.find(edge => edge.node.id == state.stc)
    console.log(currentTax)
    setTaxRate(currentTax.node.rate)
    setTaxDescription(currentTax.node.description)
    setTaxName(currentTax.node.name)
    console.log(taxRate)
    calcTotal()
  }, [cartTotal, postalCode])

  useEffect(() => {
    let taxDue = (cartTotal + shipping) * taxRate
    let shipDue = shipping
    let totalDue = cartTotal + taxDue + shipDue
    dispatch({ type: "SET_TOTAL", payload: totalDue })
  }, [taxRate])

  const calcTotal = () => {
    let subtotalPlusShipping = subtotal + state.sp
    if (taxRate > 0) {
      var totalValue = (subtotal + state.sp) * (1 + taxRate)
      var totalTax = (subtotal + state.sp) * taxRate
      setTax(totalTax)
    } else {
      var totalValue = subtotalPlusShipping
    }
    return dispatch({ type: "SET_TOTAL", payload: totalValue })
    // return setTotal(totalValue)
  }
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Order Summary</Typography>

      <TableContainer>
        <Table
          className={classes.table}
          aria-label="spanning table"
          size="small"
        >
          <TableBody>
            <TableRow>
              <TableCell>Subtotal</TableCell>
              <TableCell align="right">${financial(subtotal)}</TableCell>
            </TableRow>
            {postalCode.length > 0 && (
              <>
                <ClickAwayListener onClickAway={() => setEnterCode(false)}>
                  <TableRow>
                    <TableCell>
                      <Typography>Postage</Typography>
                      <Typography variant="caption">{state.loc}</Typography>
                      {/* <Typography>{state.loc}</Typography> */}
                    </TableCell>
                    <TableCell align="right">
                      <CalcTaxShip
                        totalWeight={totalWeight}
                        shipping={shipping}
                        setShipping={setShipping}
                        shippingIsUpdated={shippingIsUpdated}
                        setShippingIsUpdated={setShippingIsUpdated}
                        setTaxRate={setTaxRate}
                      />
                      {/* <GetShipping
                  totalWeight={totalWeight}
                  shipping={shipping}
                  setShipping={setShipping}
                  enterCode={enterCode}
                  setEnterCode={setEnterCode}
                  shippingIsUpdated={shippingIsUpdated}
                  setShippingIsUpdated={setShippingIsUpdated}
                  setTaxRate={setTaxRate}
                /> */}
                    </TableCell>
                  </TableRow>
                </ClickAwayListener>
                <TableRow>
                  <TableCell>
                    <Typography>Sales Tax</Typography>
                    {taxRate > 0 ? (
                      <Typography variant="caption">
                        {taxRate * 100}%
                      </Typography>
                    ) : (
                      ""
                    )}
                  </TableCell>

                  <TableCell align="right">
                    {total && `$${financial(total * taxRate)}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell align="right">
                    <strong>{total && `$${financial(total)}`}</strong>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 270,
  },
  title: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  paper: {
    backgroundColor: "#ECECEC",
    border: "0px",
    padding: theme.spacing(2),
    boxShadow: "none",
    elevation: 0,
    maxWidth: 400,
  },
  postalInput: {
    maxWidth: 80,
  },
  componentCell: {
    alignItems: "right",
  },
  table: {
    padding: theme.spacing(0),
  },
})
export default withStyles(styles)(SummaryTotals)
