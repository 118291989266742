import React, { useContext, useState, useRef, useEffect } from "react"
import {
  Line,
  Text,
  Stage,
  Layer,
  Circle,
  Shape,
  Image,
  Transformer,
} from "react-konva"
import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import CommentsContext from "../context"
import withStyles from "@material-ui/core/styles/withStyles"

import useImage from "use-image"
import CommentInstructions from "./Changes/CommentInstructions"
import CommentForm from "./Changes/CommentForm"
import CommentList from "./CommentList"
import ReviewChanges from "./Changes/ReviewChanges"
import ConfirmationNotice from "./ConfirmationNotice"
import Discrepancy from "./Discrepancy"

const Circular = ({ shapeProps, isSelected, onSelect, onChange }) => {
  const shapeRef = useRef()
  const trRef = useRef()

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current])
      trRef.current.getLayer().batchDraw()
    }
  }, [isSelected])

  return (
    <>
      <Circle
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragEnd={e => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          })
        }}
        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current
          const scaleX = node.scaleX()
          const scaleY = node.scaleY()

          // we will reset it back
          node.scaleX(1)
          node.scaleY(1)
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          })
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      )}
    </>
  )
}

// This component is really tricky. I was stuck on it for some time, so these notes are important:
// You need three different image instances here. It is true. The first is the background for the stage.
// The 2nd is the file that is rendered on the screen as the "preview image" before the user sends the change.
// The 3rd file is the one that goes in the mutation. It is in a 'File' format, which does not render on the screen.
// The Blob renders on the screen
// Never mess with
const ImageStage = ({ classes, sampleProof, view, setView }) => {
  const { state, dispatch } = useContext(CommentsContext)
  const [newPreview, setNewPreview] = useState("")
  const [originalApproved, setOriginalApproved] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [warningAlert, setWarningAlert] = useState(false)
  const [stageWidth, setStageWidth] = useState(window.innerWidth * 0.8)
  const stageHeight = sampleProof.isVertical
    ? stageWidth * 1.25
    : stageWidth * 0.75
  const sampleProofUrl = sampleProof.sampleProofUrl
  const idString = state.idString ? state.idString : "no_id_no_name"
  const proofIdentity = idString + "_user_change_request"

  // Draw
  // const [lines, setLines] = useState([])
  // const isDrawing = React.useRef(false)

  // const handleMouseDown = e => {
  //   isDrawing.current = true
  //   const pos = e.target.getStage().getPointerPosition()
  //   setLines([...lines, { tool, points: [pos.x, pos.y] }])
  // }

  // const handleMouseMove = e => {
  //   // no drawing - skipping
  //   if (!isDrawing.current) {
  //     return
  //   }
  //   const stage = e.target.getStage()
  //   const point = stage.getPointerPosition()
  //   let lastLine = lines[lines.length - 1]
  //   // add point
  //   lastLine.points = lastLine.points.concat([point.x, point.y])

  //   // replace last
  //   lines.splice(lines.length - 1, 1, lastLine)
  //   setLines(lines.concat())
  // }

  // const handleMouseUp = () => {
  //   isDrawing.current = false
  // }
  // end draw

  const [circleState, setCircleState] = useState({
    x: 100,
    y: 100,
    width: 100,
    height: 100,
  })
  var circleTools = state.comments
    .filter(function (tool) {
      return tool.canvasTool == "circle"
    })
    .map(function (el) {
      var o = Object.assign({}, el)
      o.x = circleState.x
      o.y = circleState.y
      o.width = circleState.width
      o.height = circleState.height
      o.stroke = el.color
      o.strokeWidth = 5
      return o
    })

  const [circles, setCircles] = useState(circleTools)

  const [selectedId, selectShape] = useState(null)
  const container = useRef(null)

  var arrows = state.comments.filter(function (tool) {
    return tool.canvasTool == "arrow"
  })

  useEffect(() => {
    setCircles(circleTools)
  }, [state])

  const flexWidth = () => {
    {
      window.innerWidth < 400
        ? setStageWidth(window.innerWidth * 0.9)
        : setStageWidth(window.innerWidth * 0.75)
    }
  }

  const checkDeselect = e => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage()
    if (clickedOnEmpty) {
      selectShape(null)
    }
  }

  useEffect(() => {
    flexWidth()
  }, [])

  const scale = Math.min(
    window.innerWidth / stageWidth,
    window.innerHeight / stageHeight
  )

  // This one works but is not CORS friendly
  const ProofImage = () => {
    const [image] = useImage(sampleProofUrl, "Anonymous")
    return <Image image={image} width={stageWidth} height={stageHeight} />
  }

  const stage = useRef()

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  const handlePreviewImage = () => {
    // necessary
    var imgData = stage.current.toDataURL()
    // just commented the below out. Is it necessary? I never call that variable.
    // var strDataURI = imgData.substr(22, imgData.length)
    var blob = dataURLtoBlob(imgData)
    var objurl = URL.createObjectURL(blob)

    var file = new File([blob], "image.png", {
      type: "image/png",
    })
    var file = new File([blob], proofIdentity, {
      type: "image/png",
    })

    console.log(file)
    setNewPreview(objurl)
    console.log(`objurl is ${objurl}`)
    dispatch({ type: "SET_PREVIEW_IMAGE", payload: file })
    dispatch({ type: "TOGGLE_REVIEW_CHANGES" })
  }

  const handleCheck = event => {
    event.persist()
    setOriginalApproved(event.target.checked)
  }

  const [approveImage, { data, loading, error }] = useMutation(
    APPROVE_SAMPLE_MUTATION,
    {
      variables: {
        sampleProofId: sampleProof.id,
      },
      onCompleted: data => {
        setIsSubmitting(false)
        // dispatch({ type: "SHOW_CONFIRMATION" })
        if (sampleProof.isPrototypePhoto) {
          setView("protoApproved")
        } else setView("artApproved")
      },
    }
  )

  const handleApproveImage = () => {
    if (originalApproved) {
      setIsSubmitting(true)
      approveImage()
    } else {
      setWarningAlert(true)
    }
  }
  // if (state.imageApproved) return <ConfirmationNotice />
  // if (state.discrepancy) return <Discrepancy />

  return (
    <>
      {/* {state.imageApproved ? (
        <ConfirmationNotice />
      ) : state.discrepancy ? (
        <Discrepancy />
      ) : ( */}
      <div
        style={{
          width: "80%",
          marginLeft: 10,
        }}
        ref={container}
      >
        {/* <Collapse in={!state.reviewChanges} timeout={800}> */}
        {!state.reviewChanges && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={originalApproved}
                  onChange={handleCheck}
                  name="approved"
                  color="primary"
                />
              }
              label={
                <Typography>
                  I approve this sample. No changes are necessary. Please move
                  to the next step.
                </Typography>
              }
            />
            <Collapse in={originalApproved}>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                onClick={handleApproveImage}
                disabled={isSubmitting}
              >
                Approve this image
              </Button>
              {warningAlert && (
                <Alert
                  severity="error"
                  variant="outlined"
                  onClose={() => setWarningAlert(false)}
                >
                  I approve this file. Please move to the next step in the
                  production process.
                </Alert>
              )}
            </Collapse>
            <Stage
              width={stageWidth}
              height={stageHeight}
              onMouseDown={checkDeselect}
              onTouchStart={checkDeselect}
              // onMouseDown={handleMouseDown}
              // onMousemove={handleMouseMove}
              // onMouseup={handleMouseUp}
              ref={stage}
            >
              <Layer
                onMouseDown={() => selectShape(null)}
                onTouchStart={() => selectShape(null)}
              >
                <ProofImage />
              </Layer>
              <Layer>
                {circles.map((circ, i) => {
                  return (
                    <Circular
                      key={i}
                      shapeProps={circ}
                      isSelected={circ.id === selectedId}
                      onSelect={() => {
                        selectShape(circ.id)
                      }}
                      onChange={newAttrs => {
                        const circs = circles.slice()
                        circs[i] = newAttrs
                        setCircles(circs)
                        setCircleState(newAttrs)
                      }}
                    />
                  )
                })}
              </Layer>

              <Layer>
                {arrows.map(arrow => (
                  <Shape
                    key={arrow.id}
                    scale={scale}
                    draggable
                    sceneFunc={(context, shape) => {
                      context.beginPath()
                      context.moveTo(30, 50)
                      context.bezierCurveTo(10, 10, 90, 10, 70, 50)
                      context.lineTo(50, 80)
                      context.closePath()
                      // (!) Konva specific method, it is very important
                      context.fillStrokeShape(shape)
                    }}
                    fill={arrow.color}
                    stroke="black"
                    strokeWidth={1}
                  />
                ))}
              </Layer>
              {/* <Layer>
                {lines.map((line, i) => (
                  <Line
                    key={i}
                    points={line.points}
                    stroke="#df4b26"
                    strokeWidth={5}
                    tension={0.5}
                    lineCap="round"
                    globalCompositeOperation={
                      line.tool === "eraser" ? "destination-out" : "source-over"
                    }
                  />
                ))}
              </Layer> */}
            </Stage>
          </>
        )}
        {/* </Collapse> */}

        {/* <Collapse in={!originalApproved}> */}
        {!originalApproved && (
          <>
            <div>
              <CommentInstructions />
              {state.reviewChanges ? (
                <div>
                  <Typography variant="h6">Review</Typography>
                  <Typography variant="body1">
                    Confirm below to <strong>request these changes</strong>. Or,{" "}
                    <strong>return to edit</strong> if you'd like to make
                    additional changes.
                  </Typography>
                  <img src={newPreview} alt="preview" />
                  <ReviewChanges view={view} setView={setView} />
                </div>
              ) : (
                <>
                  <CommentForm />
                  <CommentList />
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={10}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handlePreviewImage}
                      >
                        Review Changes
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() =>
                          dispatch({ type: "TOGGLE_REVIEW_CHANGES" })
                        }
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
          </>
        )}
        {/* </Collapse> */}
      </div>
      {/* )} */}
    </>
  )
}

const styles = theme => ({
  root: {
    width: "100vw",
    flexGrow: 1,
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
  },
  container: {
    display: "grid",
  },
  itemImage: {
    height: 420,
  },
})

const APPROVE_SAMPLE_MUTATION = gql`
  mutation ($sampleProofId: Int!) {
    approveSampleProof(sampleProofId: $sampleProofId) {
      sampleProof {
        id
        status {
          id
          name
        }
        version
        isPrototypePhoto
        item {
          id
        }
      }
    }
  }
`

export default withStyles(styles)(ImageStage)
