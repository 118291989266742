import React, { useState, useEffect, useContext } from "react"
import { Box, Grid, Paper, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"
import gql from "graphql-tag"
import CheckoutContext from "./context/CheckoutContext"
import OrderSummaryBox from "../../UserOrders/UserOrderDetails/OrderSummaryBox"
import OrderShippingBox from "../../UserOrders/UserOrderDetails/OrderShippingBox"
import OrderItemCard from "../../UserOrders/UserOrderDetails/OrderItemCard"
const SuccessDetails = ({ classes, getDetails, setGetDetails }) => {
  const { state } = useContext(CheckoutContext)
  const [orderDetails, setOrderDetails] = useState(null)

  //   const orderPayment =
  //     orderDetails && orderDetails.orderPayment ? orderDetails.orderPayment : ""
  //   const billingAddress =
  //     orderPayment && orderPayment.billingAddress
  //       ? orderPayment.billingAddress
  //       : ""
  //   const orderShipping =
  //     orderDetails && orderDetails.orderShipping ? orderDetails.orderShipping : ""
  //   const shipTo =
  //     orderShipping && orderShipping.shipTo ? orderShipping.shipTo : ""
  //   const orderItems = orderDetails && orderDetails.orderWithItem
  const orderId = parseInt(state.orderId)

  useEffect(() => {
    if (getDetails) getOrderDetails()
  }, [getDetails])

  const [getOrderDetails, { loading, data, error }] = useLazyQuery(
    ORDER_DETAILS_QUERY,
    {
      variables: {
        id: orderId,
      },
      onCompleted: data => {
        console.log("success order query completed")
        console.log(data.order)
        setOrderDetails(data.order)
        setGetDetails(false)
      },
    }
  )
  if (loading) return <Loading />
  if (error) return <Error error={error} />
  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          alignItems="flex-start"
          spacing={3}
          className={classes.orderGrid}
        >
          {orderDetails ? JSON.stringify(orderDetails) : ""}
          <Grid item xs={12} sm={6}>
            <OrderSummaryBox orderDetails={orderDetails} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <OrderShippingBox orderDetails={orderDetails} />
          </Grid>
          {orderDetails && orderDetails.orderWithItem ? (
            <>
              <Grid item xs={12}>
                {orderDetails &&
                  orderDetails.orderWithItem &&
                  orderDetails.orderWithItem.map(oi => (
                    <Box className={classes.orderItemBCard} key={oi.id}>
                      <OrderItemCard oi={oi} />
                    </Box>
                  ))}
              </Grid>
            </>
          ) : !loading ? (
            <Grid item xs={12}>
              <Typography>There are no items in this order.</Typography>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
    </>
  )
  //   return (
  //     <>
  //       {orderDetails ? (
  //         <Paper square className={classes.paper}>
  //           <Grid container spacing={3}>
  //             <Grid item xs={12} md={6}>
  //               <Typography className={classes.title}>
  //                 Shipping Address
  //               </Typography>
  //               <Box mt={1} pl={1}>
  //                 <Typography variant="caption">
  //                   {shipTo ? (
  //                     <>
  //                       {shipTo.name && shipTo.name}
  //                       {shipTo.name && <br />}
  //                       {shipTo.address.attention &&
  //                         `attn: ${shipTo.address.attention}`}
  //                       {shipTo.address.attention && <br />}
  //                       {shipTo.address.addressLine1 &&
  //                         shipTo.address.addressLine1}
  //                       {shipTo.address.addressLine1 && <br />}
  //                       {shipTo.address.addressLine2 &&
  //                         shipTo.address.addressLine2}
  //                       {shipTo.address.addressLine2 && <br />}
  //                       {shipTo.address.addressLine3 &&
  //                         shipTo.address.addressLine3}
  //                       {shipTo.address.addressLine3 && <br />}
  //                       {shipTo.address.city}, {shipTo.address.zone}{" "}
  //                       {shipTo.address.postalCode} <br />
  //                       {shipTo.address.region.name}
  //                     </>
  //                   ) : (
  //                     ""
  //                   )}
  //                 </Typography>
  //               </Box>
  //             </Grid>
  //             <Grid item xs={12} md={6}>
  //               <Typography className={classes.title}>Billing Address</Typography>
  //               <Box mt={1} pl={1}>
  //                 <Typography variant="caption">
  //                   {billingAddress ? (
  //                     <>
  //                       {billingAddress.name && billingAddress.name}
  //                       {billingAddress.name && <br />}
  //                       {billingAddress.address.attention &&
  //                         `attn: ${billingAddress.address.attention}`}
  //                       {billingAddress.address.attention && <br />}
  //                       {billingAddress.address.addressLine1 &&
  //                         billingAddress.address.addressLine1}
  //                       {billingAddress.address.addressLine1 && <br />}
  //                       {billingAddress.address.addressLine2 &&
  //                         billingAddress.address.addressLine2}
  //                       {billingAddress.address.addressLine2 && <br />}
  //                       {billingAddress.address.addressLine3 &&
  //                         billingAddress.address.addressLine3}
  //                       {billingAddress.address.addressLine3 && <br />}
  //                       {billingAddress.address.city},{" "}
  //                       {billingAddress.address.zone}{" "}
  //                       {billingAddress.address.postalCode} <br />
  //                       {billingAddress.address.region.name}
  //                     </>
  //                   ) : (
  //                     ""
  //                   )}
  //                 </Typography>
  //               </Box>
  //             </Grid>
  //           </Grid>
  //         </Paper>
  //       ) : (
  //         ""
  //       )}
  //       {/* <Typography>Order {orderId ? orderId : " confirmation"}</Typography>
  //       {orderDetails && JSON.stringify(orderDetails)} */}
  //     </>
  //   )
}

const styles = theme => ({
  root: {},
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
  },
  title: {
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: "bold",
  },
})
// const PAYMENT_DETAILS_QUERY = gql``

const ORDER_DETAILS_QUERY = gql`
  query($id: Int) {
    order(id: $id) {
      id
      customer {
        uid
        firstName
        lastName
      }
      orderDate
      completionDate
      businessUnit {
        id
        name
        homepage
      }

      taxAmt
      discountAmt
      postageAmt
      orderTotal
      orderSubtotal
      orderPaymentTotal
      orderBalanceDue
      balanceDue
      balanceDueDate
      notes
      fromCart
      cartId
      inProgress
      status {
        id
        orderStatus
      }
      orderPayment {
        id
        paymentDate
        paidBy {
          uid
          firstName
          lastName
        }
        method {
          id
          name
        }
        code

        taxPaid
        shippingPaid
        totalPaid
        pmtException {
          id
          name
        }
      }
      orderShipping {
        id
        carrier {
          ouid
          name
        }
        tracking
        shipDate
        shipTo {
          id
          name
          thirdPartyAddress
          address {
            uid
            attention
            addressLine1
            addressLine2
            addressLine3
            isOrg
            zone
            city
            postalCode
            region {
              id
              name
            }
          }
        }
      }
      orderWithItem {
        id
        customFields
        item {
          id
          name
          category {
            name
          }
          isPrivate
          featuredImageThumbnailUrl
        }
        qty
        priceEach
        itemSub
        itemTax
        itemTotal
        itemDiscount
        taxAmt
        discountAmt
      }
    }
  }
`
export default withStyles(styles)(SuccessDetails)
