import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Link } from "gatsby"

import withStyles from "@material-ui/core/styles/withStyles"

import { Card, CardContent, Typography, colors } from "@material-ui/core"

const NoOrderItems = ({ setCurrentTab, classes, className, ...rest }) => {
  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          <Typography component="p" gutterBottom>
            We couldn't find any items from your previous orders.
          </Typography>
          <Typography component="p" gutterBottom>
            Why not{" "}
            <Link to="#" onClick={() => setCurrentTab(3)}>
              request a quote{" "}
            </Link>
            for something new?
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}

NoOrderItems.propTypes = {
  className: PropTypes.string,
}

const styles = theme => ({
  root: {},
  media: {
    height: 200,
    backgroundColor: theme.palette.background.dark,
  },
  likedButton: {
    color: colors.red[600],
  },
  subscribersIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  square: {
    // color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: theme.palette.secondary.light,
  },
})

export default withStyles(styles)(NoOrderItems)
