import React, { useState, useContext } from "react"
import gql from "graphql-tag"
import { useMutation, useQuery } from "@apollo/client"

import { makeStyles } from "@material-ui/core/styles"

import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  SvgIcon,
  Typography,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  instructions: {
    margin: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  mainCard: {
    width: "100%",
    padding: theme.spacing(2),
  },
}))

const PaymentOptionSelect = ({ paymentOption, setPaymentOption }) => {
  const classes = useStyles()
  const [newBillingAddress, setNewBillingAddress] = useState(false)

  const handleChange = event => {
    setPaymentOption(event.target.value)
  }
  return (
    <>
      <Box mb={2}>
        <Card square variant="outlined" className={classes.mainCard}>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                variant={paymentOption === "stripe" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setPaymentOption("stripe")}
              >
                Visa / MC / Amex
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                variant={paymentOption === "google" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setPaymentOption("google")}
              >
                Google Pay
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                variant={paymentOption === "apple" ? "contained" : "outlined"}
                color="primary"
                onClick={() => setPaymentOption("apple")}
              >
                Apple Pay
              </Button>
            </Grid>
          </Grid>

          {/* <FormControl component="fieldset">
            <FormLabel component="legend">Select a payment method</FormLabel>
            <RadioGroup
              aria-label="payment"
              name="paymentOptions"
              value={paymentOption}
              onChange={handleChange}
            >
              <FormControlLabel
                value="stripe"
                control={<Radio />}
                label="Visa or MasterCard"
              />
              <FormControlLabel
                value="google"
                control={<Radio />}
                label="Google Pay"
              />
              <FormControlLabel
                value="amazon"
                control={<Radio />}
                label="Amazon Pay"
              />
            </RadioGroup>
          </FormControl> */}
        </Card>
      </Box>
    </>
  )
}

export default PaymentOptionSelect
