import React from "react"
import { Link } from "gatsby"
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

const NoItemImages = ({ classes }) => {
  return (
    <>
      <Card>
        <CardContent>
          <Typography component="p" gutterBottom>
            No item images are currently on file.
          </Typography>
          <Typography component="p" gutterBottom>
            Why not request a quote for a great custom item?
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "grid",
  },
})
export default withStyles(styles)(NoItemImages)
