import React, { useState } from "react"
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
} from "@material-ui/core"

import withStyles from "@material-ui/core/styles/withStyles"

import moment from "moment"

import { Link, navigate } from "gatsby"
import {
  Edit as EditIcon,
  Search as SearchIcon,
  ArrowRight as ArrowRightIcon,
  X as CancelIcon,
} from "react-feather"
function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const ShippingListItem = ({ shipment, classes }) => {
  const shipmentId = parseInt(shipment.id)

  return (
    <Card variant="outlined" className={classes.cardButton}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={3}>
          <Box mb={2} className={classes.id}>
            <Typography variant="body2" color="textSecondary">
              Shipped {moment(shipment.shipDate).format("MMM DD, YYYY")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Carrier:{" "}
              {shipment.carrier ? shipment.carrier.name : "Unspecified"}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              Tracking:{" "}
              {shipment.tracking ? shipment.tracking : "Tracking not available"}
            </Typography>

            {shipment.shipException && shipment.shipException.length > 0 && (
              <>
                {shipment.shipException.map(exception => (
                  <Box key={exception.id}>Exception: {exception.name}</Box>
                ))}
                <Typography variant="body2" color="textSecondary">
                  Tracking: {shipment.tracking}
                </Typography>
              </>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box mb={2} className={classes.id}>
            <Link
              style={{
                textDecoration: "none",
                color: "textPrimary",
              }}
              variant="subtitle2"
              color="textPrimary"
              to={`/my/orders/${shipment.order.id}`}
              state={{ id: `${shipment.order.id}` }}
            >
              Order {shipment.order.id}
            </Link>

            <>
              <Typography variant="body2" color="textSecondary"></Typography>
            </>
          </Box>
        </Grid>

        <Grid item xs={12} sm={5}>
          <Box>
            {shipment.shipTo && (
              <>
                <Typography variant="body2" color="textSecondary">
                  Shipped to:
                </Typography>

                <Box pl={1}>
                  <Typography variant="body2" color="textSecondary">
                    {shipment.shipTo.attention && (
                      <>
                        {shipment.shipTo.attention}
                        <br />
                      </>
                    )}
                    {shipment.shipTo.addressLine1 && (
                      <>
                        {shipment.shipTo.addressLine1}
                        <br />
                      </>
                    )}
                    {shipment.shipTo.addressLine2 && (
                      <>
                        {shipment.shipTo.addressLine2}
                        <br />
                      </>
                    )}
                    {shipment.shipTo.addressLine3 && (
                      <>
                        {shipment.shipTo.addressLine3}
                        <br />
                      </>
                    )}
                    {shipment.shipTo.city && shipment.shipTo.city},
                    {shipment.shipTo.zone && shipment.shipTo.zone}{" "}
                    {shipment.shipTo.postalCode && shipment.shipTo.postalCode}
                    <br />
                    {shipment.shipTo.region && shipment.shipTo.region.name}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
    // flexGrow: 1,
    // margin: 1,
  },
  id: {
    textAlign: "left",
  },
  cardButton: {
    // width: "100%",
    minWidth: 225,
    maxWidth: 950,
    padding: theme.spacing(2),
    borderRadius: "15px",
  },
})

export default withStyles(styles)(ShippingListItem)
