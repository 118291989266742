import React, { useState, useEffect, useContext } from "react"
import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import { Page, Cart } from "./PageCart"
import { useCart } from "./Cart"
import { Grid, Box, Container, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

import Loading from "../../Shared/Loading"
import Error from "../../Shared/Error"
import SignBackIn from "../../SignBackIn"
import Header from "./Header"
import CheckOutBox from "./CheckOutBox"
import UserCartDetails from "./UserCartDetails"
import SaveCart from "./SaveCart"
import UserContext from "../../../context/UserContext"
import Checkout from "./Checkout"
import { useSiteMetadata } from "../../utils/hooks/useSiteMetadata"

const UserCart = ({ classes }) => {
  const { state, dispatch } = useContext(UserContext)
  const [checkout, setCheckout] = useState(false)
  const isLoggedIn = state.isLoggedIn
  const cart = JSON.parse(localStorage.getItem("ie-cart"))
  const cartId = cart.id
  const items = JSON.stringify(cart.items)
  const started = cart.started
  const latestChange = cart.latestUpdate
  const total = cart.cartTotal
  const totalItems = cart.totalItems
  const uniqueItems = cart.totalUniqueItems
  const isOpen = cart.isOpen
  const isEmpty = cart.isEmpty
  const [uid, setUid] = useState("")
  const { sbuId } = useSiteMetadata()

  const [data, loading, error] = useQuery(MY_CART_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      sbuId: sbuId,
    },
    onError: error => {
      dispatch({ type: "SET_LOGOUT" })
      return <SignBackIn />
    },
    onCompleted: data => {
      // setUid(data.myCart)
      if (data && data.myCart) {
        let openCart = data.myCart.cartOpen
        let expiredCart = data.myCart.cartExpired
        let lastSaved = data.myCart.latestChange
        if (
          openCart === true &&
          expiredCart === false &&
          lastSaved >= latestChange
        )
          console.log("put server cart in local storage")
      }
    },
  })

  useEffect(() => {
    {
      uid && uid.length > 5 && handleSaveCart()
    }
  }, [uid, total])

  const [handleSaveCart] = useMutation(SAVE_CART_MUTATION, {
    variables: {
      cartId: cartId,
      businessUnitId: sbuId,
      items: items,
      totalItems: totalItems,
      totalUniqueItems: uniqueItems,
      started: started,
      latestChange: latestChange,
      cartOpen: isOpen,
      cartExpired: false,
    },

    onCompleted: data => {
      console.log(data)
    },
  })

  // const handleLogout =()=>{
  //   dispatch({type:"SET_LOGOUT"})

  // }
  if (loading) return <Loading />
  // if (isLoggedIn === false) return <SignBackIn />
  // if (error) return <Error />

  if (checkout)
    return (
      <Checkout cartId={cartId} checkout={checkout} setCheckout={setCheckout} />
    )
  return (
    <div className={classes.root}>
      <Container maxWidth={false}>
        <Header />

        <Grid container spacing={3}>
          <Grid item xxs={12} xs={12} lg={8}>
            <UserCartDetails />
          </Grid>

          <Grid item xxs={12} xs={12} lg={4}>
            <CheckOutBox
              checkout={checkout}
              setCheckout={setCheckout}
              cartId={cartId}
              cartItems={items}
              started={started}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

const styles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexGrow: 1,
  },

  // cartBox: {
  //   width: "100%",
  //   flexGrow: 1,
  // },
  // cartDetails: {
  //   flex: 1,
  // },
})

const MY_CART_QUERY = gql`
  query($sbuId: Int) {
    myCart(sbuId: $sbuId) {
      id
      cartId
      person {
        uid
        firstName
        lastName
      }
      items
      totalItems
      totalUniqueItems
      started
      latestChange
      cartOpen
      expirationDate
      cartExpired
      shippingPostalCode
      cartShippingEstimate
      businessUnit {
        id
        name
      }
    }
  }
`

export const SAVE_CART_MUTATION = gql`
  mutation(
    $cartId: String
    $items: String
    $totalItems: Int
    $totalUniqueItems: Int
    $started: DateTime
    $latestChange: DateTime
    $businessUnitId: Int
    $cartOpen: Boolean
    $expirationDate: DateTime
  ) {
    updateMyCart(
      cartId: $cartId
      items: $items
      totalItems: $totalItems
      totalUniqueItems: $totalUniqueItems
      started: $started
      latestChange: $latestChange
      businessUnitId: $businessUnitId
      cartOpen: $cartOpen
      expirationDate: $expirationDate
    ) {
      cart {
        id
        cartId
        items
        totalItems
        totalUniqueItems
        started
        latestChange
        cartOpen
        expirationDate
        cartExpired
        cartShippingEstimate
      }
    }
  }
`

export default withStyles(styles)(UserCart)
