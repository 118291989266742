import React, { useState, useContext } from "react"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core"
import MessageIcon from "@material-ui/icons/Message"
import ForwardIcon from "@material-ui/icons/Forward"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"

import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation, useEffect } from "@apollo/client"
import CommentsContext from "../../context"
import gql from "graphql-tag"
import Loading from "../../../../../utils/Loading"
import Error from "../../../../../utils/Error"

const ReviewChanges = ({ classes, view, setView }) => {
  const { state, dispatch } = useContext(CommentsContext)
  const proofId = state.proofId
  const previewImage = state.previewImage
  const allComments = JSON.stringify(state.comments)
  const [imageFile, setImageFile] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  //   const [fileLoaded, setFileLoaded] = useState(false)

  const handleGoBack = () => {
    // setPreviewChanges(false)
    dispatch({ type: "HIDE_REVIEW_CHANGES" })
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    handleUploadComment()
  }

  const [handleUploadComment, { data, loading, error }] = useMutation(
    UPLOAD_PROOF_COMMENTS,
    {
      variables: {
        sampleProofId: proofId,
        comments: allComments,
        // image: hockeyguy,
        image: previewImage,
      },
      onCompleted: data => {
        setIsSubmitting(false)
        setView("discrepancy")
        console.log("changes and image were uploaded")
        console.log(data)
      },
      onError: error => {
        console.log(error)
      },
    }
  )
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <div className={classes.main}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">Your comments:</Typography>
          <ul>
            {state.comments.map(comment => (
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <Avatar
                      aria-label="comment type"
                      style={{ color: "white", backgroundColor: comment.color }}
                    >
                      {comment.canvasTool === "circle" && (
                        <RadioButtonUncheckedIcon />
                      )}
                      {comment.canvasTool === "arrow" && <ForwardIcon />}
                      {comment.canvasTool === "none" && <MessageIcon />}
                    </Avatar>
                  }
                  title={<>{state.myName}</>}
                />

                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {comment.text}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </ul>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Button
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Request these changes
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={handleGoBack}
            disabled={isSubmitting}
          >
            Return to Edit
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  root: {},
  card: {
    width: "100%",
    margin: theme.spacing(1, 0, 0),
  },
  main: {
    marginBottom: theme.spacing(3),
  },
})

const UPLOAD_PROOF_COMMENTS = gql`
  mutation ($sampleProofId: Int!, $comments: String, $image: Upload) {
    createProofImageComments(
      sampleProofId: $sampleProofId
      comments: $comments
      image: $image
    ) {
      sampleProofComment {
        id
        comment
        commentImage {
          id
        }
      }
      commentImage {
        id
        commentImage
        timestamp
      }
      sampleProof {
        id
        status {
          id
        }
      }
    }
  }
`
export default withStyles(styles)(ReviewChanges)
