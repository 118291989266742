import React, { useState } from "react"
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardHeader,
  ClickAwayListener,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import ControlPointIcon from "@material-ui/icons/ControlPoint"

import { Alert } from "@material-ui/lab"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"
import useDidMountEffect from "../../../utils/hooks/useDidMountEffect"
import EmailIcon from "@material-ui/icons/Email"

const UserAddEditEmail = ({ classes, uid, emails }) => {
  const [addEmail, setAddEmail] = useState(false)
  const [newEmail, setNewEmail] = useState("")
  const [message, setMessage] = useState("")
  const [emailList, setEmailList] = useState(emails)
  const [success, setSuccess] = useState(false)
  const [activeEmail, setActiveEmail] = useState(null)
  const [activeEmailAddress, setActiveEmailAddress] = useState("")
  const [activeEmailId, setActiveEmailId] = useState(null)
  const [deleteEmailId, setDeleteEmailId] = useState(null)

  const [addNewEmail, { loading, error }] = useMutation(ADD_EMAIL_MUTATION, {
    variables: {
      uid: uid,
      emailAddress: newEmail,
    },
    onCompleted: data => {
      const createdEmail = data.createPersonEmailAccount.emailAccount
      setMessage(data.createPersonEmailAccount.message)
      setEmailList(prevSelected => [...prevSelected, createdEmail])
      setSuccess(true)
      setAddEmail(false)
      setNewEmail("")
    },
  })

  const [updateEmail] = useMutation(UPDATE_EMAIL_MUTATION, {
    variables: {
      emailId: activeEmailId,
      emailAddress: activeEmailAddress,
      uid: uid,
    },
    onCompleted: data => {
      console.log(data)
      setMessage(data.updatePersonEmailAccount.message)
      const updatedEmail = data.updatePersonEmailAccount.emailAccount
      const updatedEmailIndex = emailList.findIndex(
        em => em.id === updatedEmail.id
      )
      const updatedEmails = [
        ...emailList.slice(0, updatedEmailIndex),
        updatedEmail,
        ...emailList.slice(updatedEmailIndex + 1),
      ]
      setEmailList(updatedEmails)
      setSuccess(true)
    },
  })

  const [deleteEmail] = useMutation(DELETE_EMAIL_MUTATION, {
    variables: {
      emailId: deleteEmailId,
    },

    onCompleted: data => {
      setMessage(data.deletePersonEmailAccount.message)
      const filteredEmails = emailList.filter(em => em.id != deleteEmailId)
      setActiveEmailId(null)
      setEmailList(filteredEmails)
      setSuccess(true)
    },
  })

  useDidMountEffect(() => {
    deleteEmail()
  }, [deleteEmailId])

  const handleSubmitNewEmail = event => {
    addNewEmail(event)
  }

  const handleUpdateEmail = event => {
    updateEmail(event)
  }
  const handleSwitchAdd = e => {
    e.preventDefault()
    setAddEmail(!addEmail)
  }

  const handleEditActive = em => {
    console.log(em.id)
    setActiveEmail(em)
    setActiveEmailId(em.id)
    setActiveEmailAddress(em.emailAddress)
  }

  const handleRemoveEmail = em => {
    setDeleteEmailId(em.id)
  }
  const handleClickAway = () => {
    setActiveEmail(null)
    setActiveEmailId(null)
    setActiveEmailAddress("")
  }
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <Card square square variant="outlined" className={classes.card}>
      <CardHeader
        avatar={<EmailIcon />}
        title="Other Email Accounts"
        subheader="(Optional)"
      />

      {emailList &&
        emailList.map(em => {
          const activeId = em.id

          return (
            <Box key={em.id} className={classes.editBox} width="100%">
              {em === activeEmail ? (
                <>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box mt={1} width="100%">
                      <form
                        className={classes.editBox}
                        onSubmit={event => {
                          event.preventDefault()
                          handleUpdateEmail(event)
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={9}>
                            <TextField
                              label="Email Address"
                              id="email"
                              type="email"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={activeEmailAddress}
                              onChange={event =>
                                setActiveEmailAddress(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box ml={2} display="inline">
                              <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                size="small"
                                aria-label="confirm email"
                                onClick={e => handleEditActive(em)}
                              >
                                Update
                              </Button>
                            </Box>

                            <Box ml={2} display="inline">
                              <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                                size="small"
                                onClick={e => handleRemoveEmail(em)}
                                aria-label="remove email"
                              >
                                Remove
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </ClickAwayListener>
                </>
              ) : (
                <>
                  <ButtonBase
                    focusRipple
                    className={classes.menuButton}
                    style={{
                      width: "100%",
                      display: "block",
                      padding: 10,
                    }}
                    onClick={e => handleEditActive(em)}
                  >
                    <Typography className={classes.buttonText}>
                      {em.emailAddress}{" "}
                      {em.isVerified ? "(verified)" : "(not verified)"}
                    </Typography>
                  </ButtonBase>
                </>
              )}
            </Box>
          )
        })}
      {addEmail ? (
        <ClickAwayListener onClickAway={event => setAddEmail(!addEmail)}>
          <Box mt={1} width="100%">
            <form
              onSubmit={event => {
                event.preventDefault()
                handleSubmitNewEmail(event)
              }}
              id="addEmailForm"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9}>
                  <FormControl fullWidth>
                    <TextField
                      label="New Email Address"
                      id="email"
                      type="email"
                      variant="outlined"
                      size="small"
                      value={newEmail}
                      onChange={event => setNewEmail(event.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Box ml={2} display="inline">
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      size="small"
                      aria-label="confirm email"
                    >
                      Add Email
                    </Button>
                  </Box>
                  <Box ml={2} display="inline">
                    <Button
                      color="secondary"
                      variant="outlined"
                      type="submit"
                      size="small"
                      onClick={event => setAddEmail(!addEmail)}
                      aria-label="remove email"
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </ClickAwayListener>
      ) : (
        <Box p={1}>
          <Button
            color="primary"
            variant="outlined"
            aria-label="add new email"
            size="small"
            onClick={handleSwitchAdd}
            startIcon={<ControlPointIcon />}
          >
            Add an email address
          </Button>
        </Box>
      )}

      {success && message && (
        <Box width="100%" mt={2}>
          <Alert
            onClose={() => {
              setSuccess(false)
            }}
          >
            {message}
          </Alert>
        </Box>
      )}
      <Grid item xs={12}>
        <Typography variant="caption" gutterBottom>
          ** Email verification required
        </Typography>
      </Grid>
    </Card>
  )
}
const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
    boxShadow: "none",
  },
  buttons: {
    right: 0,
  },
  buttonText: {
    textAlign: "left",
  },
  card: {
    width: "100%",
    padding: theme.spacing(1),
  },
  menuButton: {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.action.hoverLight,

      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },

  displayEmailBox: {
    width: "100%",
  },
  editBox: {
    display: "flex",
    width: "100%",
  },
  field: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    // width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    fontSize: "20px",
  },
  divider: {
    height: 28,
    margin: 4,
  },
  disabled: {
    color: theme.palette.common.black,
    opacity: 1,
  },
  paper: {
    padding: theme.spacing(1),
    minHeight: 220,
  },
  title: {
    // fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

const ADD_EMAIL_MUTATION = gql`
  mutation($emailAddress: String!, $uid: String) {
    createPersonEmailAccount(emailAddress: $emailAddress, uid: $uid) {
      emailAccount {
        id
        emailAddress
        isVerified
      }
      message
    }
  }
`

const UPDATE_EMAIL_MUTATION = gql`
  mutation($emailId: Int!, $emailAddress: String!, $uid: String) {
    updatePersonEmailAccount(
      emailId: $emailId
      emailAddress: $emailAddress
      uid: $uid
    ) {
      emailAccount {
        id
        emailAddress
        isVerified
      }
      message
    }
  }
`

const DELETE_EMAIL_MUTATION = gql`
  mutation($emailId: Int!) {
    deletePersonEmailAccount(emailId: $emailId) {
      message
    }
  }
`
export default withStyles(styles)(UserAddEditEmail)
