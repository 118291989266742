import React, { useContext, useState, useReducer, useEffect } from "react"
import { Router } from "@reach/router"
// import clsx from "clsx"
import DashboardLayout from "../components/Layout/DashboardLayout"

// import { CartProvider } from "../components/ReactCart/ReactCart"
// import { makeStyles } from "@material-ui/core"
import PrivateRoute from "../components/Sign/PrivateRoute"
import gql from "graphql-tag"
import { useLazyQuery } from "@apollo/client"
// import Loading from "../components/Shared/Loading"
// import Error from "../components/Shared/Error"

// import ErrorBoundary from "../components/utils/ErrorBoundary"

import UserAccount from "../components/User/UserAccount"
import UserHome from "../components/User/UserHome"
import UserCart from "../components/User/UserCart"
import UserFiles from "../components/User/UserFiles"
import UserFileDetails from "../components/User/UserFiles/UserFileDetails"

import UserItems from "../components/User/UserItems"
import UserPrivateItemDetails from "../components/User/UserItems/UserPrivateItemDetails"
import UserOrderItemDetails from "../components/User/UserItems/UserOrderItemDetails"
import UserPublicItemDetails from "../components/User/UserItems/UserPublicItemDetails"

import ItemSampleProof from "../components/User/UserItems/ItemSampleProof"

import ItemSampleTest from "../components/User/UserItems/ItemSampleTest"

import UserOrders from "../components/User/UserOrders"
import UserOrderDetails from "../components/User/UserOrders/UserOrderDetails"
import Checkout from "../components/User/UserCart/Checkout"
import UserMessages from "../components/User/UserMessages"
// import VerifyMe from "../components/Sign/VerifyMe"
// import UserShippingList from "../components/User/UserOrders/UserShippingList"
// import UserPaymentList from "../components/User/UserOrders/UserPaymentList"
import UserContext from "../context/UserContext"

// import { useCart } from "../components/User/UserCart/Cart"
// import UiLocalStorageHook from "../components/Sign/UiLocalStorageHook"
// import { SettingsSystemDaydreamOutlined } from "@material-ui/icons"

// const useStyles = makeStyles(theme => ({
//   root: {
//     // backgroundColor: "#cccccc",
//     display: "flex",
//     height: "100%",
//     overflow: "hidden",
//     width: "100%",
//   },
//   wrapper: {
//     display: "flex",
//     flex: "1 1 auto",
//     overflow: "hidden",
//     paddingTop: 64,
//     [theme.breakpoints.up("lg")]: {
//       paddingLeft: 256,
//     },
//   },
//   contentContainer: {
//     display: "flex",
//     flex: "1 1 auto",
//     overflow: "hidden",
//   },
//   content: {
//     flex: "1 1 auto",
//     height: "100%",
//     overflow: "hidden",
//   },
// }))
// Do something with this
// Maybe put the cart provider here, too
// export const UserConducer = props => {
//   const initialState = useContext(UserContext)
//   const [state, dispatch] = useReducer(UserReducer, initialState)

//   return (
//     <UserContext.Provider value={{ state, dispatch }}>
//       {props.children}
//     </UserContext.Provider>
//   )
// }

// class ErrorBoundary extends Component {
//   constructor(props) {
//     super(props)
//     this.state = { hasError: false }
//   }

//   componentDidCatch(error, info) {
//     // Display fallback UI
//     this.setState({ hasError: true })
//     // You can also log the error to an error reporting service
//     // logErrorToMyService(error, info)
//     console.log(error, info)
//   }

//   render() {
//     if (this.state.hasError) {
//       // You can render any custom fallback UI
//       return <h1>Something went wrong.</h1>
//     }
//     return this.props.children
//   }
// }

const My = () => {
  // const classes = useStyles()
  const { state, dispatch } = useContext(UserContext)

  const isVerified = state.isVerified
  const isLoggedIn = state.isLoggedIn
  // const [myUid, setMyUid] = useState("")

  // const { loading, error, data } = useQuery(MY_VERIFICATION_QUERY, {
  //   onCompleted: data => {
  //     let name = data.me.person.firstName
  //     let initial = name[0]
  //     let verified = data.me.isVerified
  //     // checkLogin()
  //     dispatch({ type: "SET_INITIAL", payload: initial })
  //     dispatch({ type: "SET_VERIFIED", payload: verified })
  //     setMyUid(data.me.person.uid)
  //   },
  // })

  // Maybe put back
  // const [getCart] = useLazyQuery(MY_CART_QUERY, {
  //   variables: {
  //     sbuId: 1,
  //   },
  //   onCompleted: data => {
  //     console.log(data)
  //     !data && console.log("no data")
  //   },
  // })

  // Use this useEffect for shopping cart updates on login/logout
  // useEffect(() => {
  //     getCart()
  // }, [isLoggedIn])

  return (
    <DashboardLayout>
      <Router>
        <PrivateRoute exact path="/my" component={UserHome} />
        <PrivateRoute path="/my/account" component={UserAccount} />
        <PrivateRoute path="/my/cart" component={UserCart} />
        <PrivateRoute path="/my/files" component={UserFiles} />
        <PrivateRoute path="/my/files/:id" component={UserFileDetails} />
        <PrivateRoute path="/my/items" component={UserItems} />
        <PrivateRoute
          path="/my/items/pi/:id"
          component={UserPrivateItemDetails}
        />
        <PrivateRoute
          path="/my/items/oi/:id"
          component={UserOrderItemDetails}
        />
        <PrivateRoute
          path="/my/items/pub/:id"
          component={UserPublicItemDetails}
        />
        <PrivateRoute
          path="/my/items/pi/:id/proof/:proofId"
          component={ItemSampleProof}
        />
        <PrivateRoute path="/my/items/sampletest" component={ItemSampleTest} />
        <PrivateRoute path="/my/orders" component={UserOrders} />
        <PrivateRoute path="/my/payments" component={UserOrders} />
        <PrivateRoute path="/my/shipping" component={UserOrders} />
        <PrivateRoute path="/my/orders/checkout/:id" component={Checkout} />
        <PrivateRoute path="/my/orders/:id" component={UserOrderDetails} />
        <PrivateRoute path="/my/service" component={UserMessages} />
      </Router>
    </DashboardLayout>
  )
}
// const MY_VERIFICATION_QUERY = gql`
//   query {
//     me {
//       isVerified
//       person {
//         uid
//         firstName
//         lastName
//       }
//     }
//   }
// `

const MY_CART_QUERY = gql`
  query ($sbuId: Int) {
    myCart(sbuId: $sbuId) {
      id
      cartId
      items
      totalItems
      totalUniqueItems
      started
      latestChange
      cartOpen
      expirationDate
      cartExpired
      businessUnit {
        id
        name
      }
    }
  }
`
export default My
