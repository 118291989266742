import React, { useContext } from "react"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  IconButton,
  Hidden,
  Tooltip,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core"
import StarIcon from "@material-ui/icons/Star"
import StarBorderIcon from "@material-ui/icons/StarBorder"
import LabelImportantIcon from "@material-ui/icons/LabelImportant"
import LabelImportantOutlinedIcon from "@material-ui/icons/LabelImportantOutlined"
import getInitials from "../../../utils/getInitials"
import MessagesContext from "../context/MessagesContext"

function getTo(params, convId) {
  const { systemLabel, customLabel } = params
  const baseUrl = "/manager/messages/"

  if (systemLabel) {
    return `${baseUrl}/${systemLabel}/${convId}`
  }

  if (customLabel) {
    return `${baseUrl}/label//${convId}`
    // return `${baseUrl}/label/${customLabel}/${mailId}`;
  }

  return baseUrl
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  unread: {
    position: "relative",
    "&:before": {
      content: '" "',
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      width: 4,
      backgroundColor: theme.palette.error.main,
    },
    "& $name, & $subject": {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  selected: {
    backgroundColor: theme.palette.action.selected,
  },
  filterActive: {
    color: colors.amber[400],
  },
  content: {
    cursor: "pointer",
    textDecoration: "none",
  },
  details: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },
  },
  name: {
    [theme.breakpoints.up("md")]: {
      minWidth: 180,
      flexBasis: 180,
    },
  },
  latest: {
    [theme.breakpoints.up("md")]: {
      minWidth: 180,
      flexBasis: 180,
    },
  },
  subject: {
    maxWidth: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  message: {
    maxWidth: 800,
    flexGrow: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: "auto",
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.white,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 2,
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  date: {
    whiteSpace: "nowrap",
  },
  avatar: {
    height: 32,
    width: 32,
  },
}))

function ConversationItem({
  className,
  conv,
  onDeselect,
  onSelect,
  selected,
  ...rest
}) {
  const classes = useStyles()
  const { state, dispatch } = useContext(MessagesContext)
  let messages = conv.conversationMessage
  const lastMessage = messages[messages.length - 1]
  //   const params = useParams();
  //   const { labels } = useSelector((state) => state.mail);
  const [openInvite, { loading, error }] = useMutation(
    OPEN_CONVERSATION_INVITE,
    {
      variables: {
        conversationId: conv.uuid,
      },
      onCompleted: data => {
        dispatch({
          type: "SET_SELECTED_CONVERSATION_ID",
          payload: conv.uuid,
        })
        dispatch({ type: "SET_PANEL", payload: "conversationSelected" })
      },
    }
  )

  const handleCheckboxChange = event =>
    event.target.checked ? onSelect() : onDeselect()

  const handleStarToggle = () => {
    // dispatch action
  }

  const handleImportantToggle = () => {
    // dispatch action
  }

  //   const to = getTo(params, mail.id);
  // const handleSelectConversation = conv => {
  //   dispatch({
  //     type: "SET_SELECTED_CONVERSATION_ID",
  //     payload: conv.uuid,
  //   })
  //   dispatch({ type: "SET_PANEL", payload: "conversationSelected" })
  // }
  const handleSelectConversation = conv => {
    openInvite()
  }

  return (
    <div
      className={clsx(
        classes.root,
        {
          //   [classes.unread]: !mail.isUnread,
          //   [classes.selected]: selected
        },
        className
      )}
      {...rest}
    >
      <Hidden smDown>
        <Box mr={1} display="flex" alignItems="center">
          <Checkbox checked={selected} onChange={handleCheckboxChange} />
          {/* <Tooltip title="Starred">
            <IconButton onClick={handleStarToggle}>
              {mail.isStarred ? (
                <StarIcon className={classes.filterActive} />
              ) : (
                <StarBorderIcon />
              )}
            </IconButton>
          </Tooltip> */}
          {/* <Tooltip title="Important">
            <IconButton onClick={handleImportantToggle}>
              {mail.isImportant ? (
                <LabelImportantIcon className={classes.filterActive} />
              ) : (
                <LabelImportantOutlinedIcon />
              )}
            </IconButton>
          </Tooltip> */}
        </Box>
      </Hidden>
      <Box
        minWidth="1px"
        display="flex"
        flexGrow={1}
        justifyContent="space-between"
        component={ButtonBase}
        onClick={() => handleSelectConversation(conv)}
        className={classes.content}
      >
        <Avatar className={classes.avatar} src={conv.category.name}>
          {getInitials(conv.category.name)}
        </Avatar>
        <Box minWidth="1px" ml={1} className={classes.details}>
          <Typography
            variant="body2"
            color="textPrimary"
            className={classes.name}
          >
            {conv.topic}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.subject}
          >
            {conv.startedBy.person.firstName} {conv.startedBy.person.lastName}
          </Typography>

          <Hidden smDown>
            {/* <Typography
              variant="body2"
              color="textSecondary"
              className={classes.message}
            >
           
            </Typography> */}
            <Box flexGrow={1} />
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.latest}
            >
              {lastMessage &&
                `L: ${moment(lastMessage.sent).format("DD MMM YYYY, h:mm a")}`}
            </Typography>
            {/* {mail.labelIds.length > 0 && (
              <Box
                display="flex"
                mx={2}
              >
                {mail.labelIds.map((labelId) => {
                  const label = labels.find((_label) => _label.id === labelId);

                  if (!label) return null;

                  return (
                    <span
                      style={{ backgroundColor: label.color }}
                      key={label.id}
                      className={classes.label}
                    >
                      {label.name}
                    </span>
                  );
                })}
              </Box>
            )} */}
          </Hidden>
          <Typography
            className={classes.date}
            color="textSecondary"
            variant="caption"
          >
            S: {moment(conv.startDate).format("DD MMM YYYY, h:mm a")}
          </Typography>
        </Box>
      </Box>
    </div>
  )
}
const OPEN_CONVERSATION_INVITE = gql`
  mutation($conversationId: String!) {
    openConversationInvite(conversationId: $conversationId) {
      joinConversation {
        id
        conversation {
          uuid
        }
      }
      message
    }
  }
`
ConversationItem.propTypes = {
  className: PropTypes.string,
  conv: PropTypes.object.isRequired,
  onDeselect: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.bool.isRequired,
}

export default ConversationItem
