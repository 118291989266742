import React, { useState, useContext, useEffect } from "react"
import SortContext from "../../../../context/SortContext"

import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import Loading from "../../../Shared/Loading"
import Error from "../../../Shared/Error"

import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core"

import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  X as CancelIcon,
} from "react-feather"

import Pagination from "@material-ui/lab/Pagination"
import withStyles from "@material-ui/core/styles/withStyles"

import ImageCard from "./ImageCard"
import NoItemImages from "./NoItemImages"

const ItemImages = ({ classes }) => {
  const { state, dispatch } = useContext(SortContext)
  const searchContext = state.search
  const [searchBar, setSearchBar] = useState(searchContext)

  const { loading, error, data } = useQuery(MY_ITEM_IMAGES_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      page: state.page,
      pageSize: state.pageSize,
      search: state.search,
    },
  })

  useEffect(() => {
    dispatch({ type: "SET_PAGE", payload: 1 })
    dispatch({ type: "SET_PAGE_SIZE", payload: 10 })
    dispatch({ type: "SET_SEARCH", payload: "" })
  }, [])

  const handleChange = (event, value) => {
    dispatch({ type: "SET_PAGE", payload: value })
  }

  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
    setSearchBar("")
  }

  if (loading) return <Loading />
  if (error) return <Error />

  const myItemImages = data.myItemImages
  const imageCount = myItemImages.count ? myItemImages.count : undefined

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item container justify="space-between" xs={12}>
          <Grid item xs={12} sm={2}>
            <Box mb={1}>
              <Typography variant="caption">
                Found {imageCount} {imageCount !== 1 ? "images" : "image"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7}>
            {myItemImages.pages > 1 && (
              <Pagination
                count={myItemImages.pages}
                page={state.page}
                onChange={handleChange}
                variant="outlined"
                size="medium"
                color="secondary"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            {myItemImages.pages > 1 && (
              <Paper
                square
                component="form"
                onSubmit={event => {
                  event.preventDefault()
                  handleSearch(event)
                }}
                className={classes.searchField}
              >
                <InputBase
                  fullWidth
                  className={classes.input}
                  placeholder="Search Items"
                  inputProps={{ "aria-label": "search items" }}
                  onChange={event => setSearchBar(event.target.value)}
                  value={searchBar}
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="clear"
                  onClick={handleClearSearchResults}
                >
                  <CancelIcon />
                </IconButton>
              </Paper>
            )}
          </Grid>
        </Grid>
        <>
          {myItemImages.count > 0 &&
            myItemImages.objects.map(itemImage => (
              <Grid item xs={12} sm={6} lg={4} key={itemImage.id}>
                <ImageCard itemImage={itemImage} />
              </Grid>
            ))}
        </>
        <>{myItemImages.count === 0 && <NoItemImages />}</>
        <Grid item container xs={12} justify="flex-end" alignItems="flex-end">
          <Grid item xs={12} sm={4}>
            <Box className={classes.pagination}>
              {myItemImages.pages > 1 && (
                <Pagination
                  count={myItemImages.pages}
                  page={state.page}
                  onChange={handleChange}
                  variant="outlined"
                  size="medium"
                  color="secondary"
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 4,
  },
  tabs: {},
})

const MY_ITEM_IMAGES_QUERY = gql`
  query($page: Int, $pageSize: Int, $search: String) {
    myItemImages(page: $page, pageSize: $pageSize, search: $search) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        image
        item {
          id
          name
        }
        createdBy {
          staff
          person {
            firstName
            lastName
          }
        }
        title
        note
        featured
        proof
        uploadedAt
        imageUrl
        thumbnailUrl
        carouselImageUrl
      }
    }
  }
`
export default withStyles(styles)(ItemImages)
