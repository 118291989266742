import React, { useContext, Component } from "react"
import Sign from "./index.js"
import UserContext from "../../context/UserContext"
import VerifyAccount from "./VerifyAccount"
// import SignBackIn from "../SignBackIn/index.js"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { state, dispatch } = useContext(UserContext)
  const isLoggedIn = state.isLoggedIn
  const isVerified = state.isVerified

  if (isLoggedIn === true) {
    if (isVerified === true) return <Component {...rest} />
    else return <VerifyAccount />
  } else return <Sign />
  // } else return <SignBackIn />
}

export default PrivateRoute
