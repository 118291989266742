import React, { useState, useContext, Fragment } from "react"
import { navigate } from "gatsby"
import withStyles from "@material-ui/core/styles/withStyles"
import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import Typography from "@material-ui/core/Typography"
import FolderIcon from "@material-ui/icons/Folder"
import Card from "@material-ui/core/Card"
import Button from "@material-ui/core/Button"
import { green, purple } from "@material-ui/core/colors"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Box from "@material-ui/core/Box"
import BigBlackButton from "../../../DropIn/BigBlackButton"
import moment from "moment"

// import UserContext from "../../../context/UserContext"

function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const OrderShippingBox = ({ classes, orderDetails }) => {
  //   const { state, dispatch } = useContext(UserContext)
  const order = orderDetails

  return (
    <>
      <Card square className={classes.paper}>
        <Typography variant="body1" className={classes.title}>
          Shipping
        </Typography>
        <Box ml={1} mt={2}>
          <Typography variant="body1">
            Shipping details will appear when the order is ready to ship.
          </Typography>
        </Box>
        {/* {JSON.stringify(order.orderShipping)} */}
        {/* <Box ml={1}>
          <Typography variant="body2" className={classes.title}>
            status
          </Typography>
        </Box>
        <TableContainer className={classes.paper}>
          <Table className={classes.table} aria-label="summary table">
            <TableBody>
              <TableRow>
                <TableCell>Number of packages</TableCell>
                <TableCell align="right">1</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Tax</TableCell>
                <TableCell align="right">34</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Shipping</TableCell>
                <TableCell align="right">34</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Tax</TableCell>
                <TableCell align="right">34</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell align="right">34</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer> */}
      </Card>
    </>
  )
}

const styles = theme => ({
  root: {
    minWidth: 270,
    // margin: theme.spacing(0, 0.5, 0.5, 0.5),
    // minWidth: "300px",
    // width: "100%",
  },
  title: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(18),
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  buttonBox: {
    width: "100%",
  },
  root: {},
  table: {
    padding: theme.spacing(0),
  },
})

export default withStyles(styles)(OrderShippingBox)
