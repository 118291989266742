import React, { useState } from "react"
import Link from "gatsby"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { green } from "@material-ui/core/colors"

const ConfirmationNotice = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Card className={classes.roundedCard}>
        <Box style={{ alignItems: "center" }}>
          <CheckCircleIcon style={{ fontSize: 79, color: green[500] }} />
        </Box>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Success!
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="body1"
            gutterBottom
          >
            Thanks for your feedback. Your approval has been sent to our team.
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="body1"
            gutterBottom
          >
            We haved moved to the next step in the process. You will receive a
            notification if we need more from you.
          </Typography>
        </CardContent>

        <CardActions>
          <Button component={<Link />} to="/my" size="small" color="secondary">
            Main Menu
          </Button>
          <Button
            component={<Link />}
            to="/my/items"
            size="small"
            color="secondary"
          >
            My Items
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(5),
  },
  roundedCard: {
    borderRadius: "15px",
  },
})
export default withStyles(styles)(ConfirmationNotice)
