import React, { useState } from "react"

import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

import moment from "moment"
import { Link, navigate } from "gatsby"
import {
  Edit as EditIcon,
  Search as SearchIcon,
  ArrowRight as ArrowRightIcon,
  X as CancelIcon,
} from "react-feather"

function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const OrderListItem = ({ order, classes }) => {
  const orderId = parseInt(order.id)
  const firstOrderItem = order.orderWithItem[0]
  return (
    <>
      <Card
        variant="outlined"
        component="button"
        cursor="pointer"
        onClick={() =>
          navigate(`./${order.id}`, {
            state: { id: { orderId } },
          })
        }
        className={classes.cardButton}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={3}>
            <Box mb={2} className={classes.id}>
              {/* <Link
                style={{ textDecoration: "none", color: "textPrimary" }}
                variant="subtitle2"
                color="textPrimary"
                to={`./${order.id}`}
                state={{ id: orderId }}
              > */}
              <Button
                variant="outlined"
                color="primary"
                component={Link}
                to={`./${order.id}`}
                state={{ id: orderId }}
              >
                {order.id > 99 ? `Id: ${order.id}` : `Order Id: ${order.id}`}
              </Button>
              {/* <Typography variant="body1" color="textPrimary">
                  {order.id}
                </Typography> */}
              {/* <Typography variant="h5">{order.id}</Typography> */}
              {/* </Link> */}
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box mb={2} className={classes.id}>
              <Typography variant="body2" color="textSecondary">
                Order Placed: {moment(order.orderDate).format("MMM DD, YYYY")}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {order.inProgress && "Order in Progress"}
                {/* {moment(order.orderDate).format("MMM DD, YYYY")} */}
              </Typography>
              {order.status && (
                <>
                  {/* <Label color={statusColors[order.status]}> */}
                  <Typography variant="body2" color="textSecondary">
                    Order Status: {order.status.orderStatus}
                  </Typography>
                  {order.completed && order.completionDate && (
                    <Typography variant="body2" color="textSecondary">
                      Order Completed:{" "}
                      {moment(order.completionDate).format("MMM DD, YYYY")}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Grid>

          <Grid item xs={11} sm={4}>
            <Box mb={1} className={classes.id}>
              <Typography variant="body2" color="textSecondary">
                {order.orderWithItem && (
                  <>
                    {order.orderWithItem.length}{" "}
                    {order.orderWithItem.length === 1
                      ? "item in this order"
                      : "items in this order"}
                  </>
                )}
              </Typography>
            </Box>
            <Box mb={1} className={classes.id}>
              <Typography variant="body2" color="textSecondary">
                {order.orderPaymentTotal > 0 && (
                  <>${financial(order.orderPaymentTotal)} paid</>
                )}
              </Typography>
            </Box>
            <Box mb={2} className={classes.id}>
              <Typography variant="body2" color="textSecondary">
                {order.orderBalanceDue > 0
                  ? `$${financial(order.orderBalanceDue)} due by ${moment(
                      order.balanceDueDate
                    ).format("MMM DD, YYYY")}`
                  : "No balance due"}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={1} sm={1}>
            <Box className={classes.arrowButton}>
              <IconButton
                component={Link}
                to={`/my/orders/${order.id}`}
                state={{ id: orderId }}
              >
                <SvgIcon fontSize="small">
                  <ArrowRightIcon />
                </SvgIcon>
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        {/* </Grid> */}
      </Card>

      {/* <Divider /> */}
    </>
  )
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
    flexGrow: 1,
    margin: 1,
  },
  id: {
    textAlign: "left",
  },
  cardButton: {
    width: "100%",
    padding: theme.spacing(2),
    minWidth: 225,
    maxWidth: 950,
    borderRadius: "15px",
  },
  arrowButton: {
    textAlign: "right",
  },
  container: {
    display: "grid",
  },
})

export default withStyles(styles)(OrderListItem)
