import React from "react"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"

import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core"

import { withStyles } from "@material-ui/styles"

const DisableAccount = ({ classes }) => {
  const handleSubmit = async event => {
    event.preventDefault()
    // Make API request
    // await wait(500);
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Card square elevation={0} variant="outlined" className={classes.root}>
          <CardHeader title="Settings" />
          <Divider />
          <CardContent>
            <Grid container spacing={6} wrap="wrap">
              <Grid item xxs={12} xs={12} sm={6}>
                <Button variant="outlined" color="secondary">
                  Disable My Account
                </Button>
                <Box mt={2}>
                  <Typography
                    gutterBottom
                    variant="body2"
                    color="textSecondary"
                  >
                    Close my account, but keep my data. Essential information,
                    including order history and files, will be saved in case I
                    decide to reopen my account at a later time.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xxs={12} xs={12} sm={6}>
                <Button variant="outlined" color="secondary">
                  Delete My Account
                </Button>
                <Box mt={2}>
                  <Typography
                    gutterBottom
                    variant="body2"
                    color="textSecondary"
                  >
                    Delete all data.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </>
  )
}

DisableAccount.propTypes = {
  className: PropTypes.string,
}
const styles = theme => ({
  root: {},
})

export default withStyles(styles)(DisableAccount)
