import React, { useContext, useState } from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import clsx from "clsx"
import {
  Avatar,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@material-ui/core"

import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import ForwardIcon from "@material-ui/icons/Forward"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import RoomIcon from "@material-ui/icons/Room"
import CreateIcon from "@material-ui/icons/Create"
import { red } from "@material-ui/core/colors"
import CommentsContext from "../context"
import MessageIcon from "@material-ui/icons/Message"
import CommentEditForm from "./CommentEditForm"
import Loading from "../../../../Shared/Loading"

const CommentCard = ({ comment, classes }) => {
  const { state, dispatch } = useContext(CommentsContext)
  const [editing, setEditing] = useState(false)

  const handleSetEdit = () => {
    dispatch({ type: "SET_CURRENT_COMMENT", payload: comment })
    setEditing(!editing)
    // dispatch({ type: "TOGGLE_EDITING" })
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            aria-label="comment type"
            style={{ color: "white", backgroundColor: comment.color }}
          >
            {comment.canvasTool === "circle" && <RadioButtonUncheckedIcon />}
            {comment.canvasTool === "arrow" && <RoomIcon />}
            {comment.canvasTool === "pen" && <CreateIcon />}
            {comment.canvasTool === "none" && <MessageIcon />}
          </Avatar>
        }
        action={
          <>
            <IconButton aria-label="edit comment">
              <EditIcon color="primary" onClick={handleSetEdit} />
            </IconButton>
            <IconButton
              aria-label="remove comment"
              onClick={() =>
                dispatch({ type: "REMOVE_COMMENT", payload: comment })
              }
            >
              <DeleteIcon color="secondary" />
            </IconButton>
          </>
        }
        title={<>{state.myName}</>}
      />

      <CardContent>
        {editing ? (
          <CommentEditForm editing={editing} setEditing={setEditing} />
        ) : (
          <Typography variant="body2" color="textSecondary" component="p">
            {comment.text}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}

const styles = theme => ({
  root: {
    width: "100%",
  },
})

export default withStyles(styles)(CommentCard)
