import React, { useState, useEffect, useContext } from "react"
import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useLazyQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"
import { Link, navigate } from "gatsby"
import { useCart } from "../Cart"
import { useSiteMetadata } from "../../../utils/hooks/useSiteMetadata"
import CheckoutContext from "./context/CheckoutContext"
import UserOrderDetails from "../../UserOrders/UserOrderDetails"
import SuccessDetails from "./SuccessDetails"
import moment from "moment"
const SuccessPage = ({ classes }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const [cartUpdated, setCartUpdated] = useState(false)
  const [cart, setCart] = useState("")
  const [cartLoaded, setCartLoaded] = useState(false)
  const [orderDetails, setOrderDetails] = useState("")
  const [getDetails, setGetDetails] = useState(false)
  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    totalItems,
    items,
    totalWeight,
    latestUpdate,
    updateItemQuantity,
    updateItemPrice,
    removeItem,
    emptyCart,
    updateCartId,
    isOpen,
    closeCart,
  } = useCart()
  // const cart = JSON.parse(localStorage.getItem("ie-cart"))
  // setNewCart(cart)
  // const cartId = cart.id
  // const started = cart.started
  const { sbuId } = useSiteMetadata()
  const orderId = parseInt(state.orderId)
  const paymentData = state.paymentData

  console.log("success page")
  console.log(orderId)

  console.log("payment data")
  console.log(paymentData)

  useEffect(() => {
    emptyCart()
    updateCartId()
    setCartUpdated(true)
  }, [])

  useEffect(() => {
    if (cartUpdated) {
      const newCart = JSON.parse(localStorage.getItem("ie-cart"))
      setCart(newCart)
      setCartLoaded(true)
    }
  }, [cartUpdated])

  useEffect(() => {
    if (cartUpdated) {
      if (cartLoaded) {
        addOrUpdateCart()
      }
    }
  }, [cartLoaded])

  const [addOrUpdateCart, { data, loading, error }] = useMutation(
    ADD_OR_UPDATE_CART_MUTATION,
    {
      variables: {
        cartId: cart.id,
        businessUnitId: sbuId,
        items: items,
        totalItems: totalItems,
        totalUniqueItems: totalUniqueItems,
        started: cart.started,
        latestChange: latestUpdate,
        cartOpen: true,
        cartExpired: false,
      },

      onCompleted: data => {
        console.log("cart created or updated")
        console.log(data.createOrUpdateMyCart)
        setGetDetails(true)
      },
    }
  )
  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }
  if (loading) return <Loading />
  if (error) return "error"
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Card square variant="outlined" className={classes.headerCard}>
            <Typography className={classes.headline}>
              Order and Payment Confirmation
            </Typography>
            <Box mt={2}>
              <Typography variant="body1" gutterBottom>
                Keep track of your order via the{" "}
                <Link to={"/my/orders"} className={classes.link}>
                  Orders
                </Link>{" "}
                tab on the main menu.
              </Typography>
              <Typography>
                {" "}
                We'll update you regarding order status according to your{" "}
                <Link to={"/my/account"} className={classes.link}>
                  contact preferences
                </Link>
                .
              </Typography>
            </Box>
          </Card>
        </Grid>

        <Grid item container xs={12} sm={12} md={12}>
          <Card square variant="outlined" className={classes.headerCard}>
            <Grid item container justify="space-between">
              <Grid item>
                <Typography className={classes.orderHeadline}>
                  Order {orderId ? orderId : "Confirmation"}
                </Typography>
                <Typography variant="body2">
                  {paymentData &&
                    paymentData.paymentDate &&
                    `Paid ${moment(paymentData.paymentDate).format(
                      "MMM Do, YYYY"
                    )} with ${paymentData.method.name}`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <Link to="/my/orders" className={classes.link}>
                    View Order Details
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              {paymentData && (
                <Table size="small" aria-label="payment confirmation data">
                  <TableBody>
                    {paymentData && paymentData.taxPaid && (
                      <TableRow>
                        <TableCell>Tax Paid</TableCell>
                        <TableCell>${financial(paymentData.taxPaid)}</TableCell>
                      </TableRow>
                    )}
                    {paymentData && paymentData.shippingPaid && (
                      <TableRow>
                        <TableCell>Shipping Paid</TableCell>
                        <TableCell>
                          ${financial(paymentData.shippingPaid)}
                        </TableCell>
                      </TableRow>
                    )}
                    {paymentData && paymentData.totalPaid && (
                      <TableRow>
                        <TableCell>Total Paid</TableCell>
                        <TableCell>
                          ${financial(paymentData.totalPaid)}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </Grid>
            <Grid></Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

const styles = theme => ({
  root: {},
  headerCard: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    width: "100%",
  },
  headline: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: "bold",
    textAlign: "center",
  },
  orderHeadline: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "bold",
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold",
    color: theme.palette.link,
  },
})

const ADD_OR_UPDATE_CART_MUTATION = gql`
  mutation (
    $cartId: String
    $items: String
    $totalItems: Int
    $totalUniqueItems: Int
    $started: DateTime
    $latestChange: DateTime
    $businessUnitId: Int
    $cartOpen: Boolean
    $expirationDate: DateTime
  ) {
    createOrUpdateMyCart(
      cartId: $cartId
      items: $items
      totalItems: $totalItems
      totalUniqueItems: $totalUniqueItems
      started: $started
      latestChange: $latestChange
      businessUnitId: $businessUnitId
      cartOpen: $cartOpen
      expirationDate: $expirationDate
    ) {
      cart {
        id
        cartId
        items
        totalItems
        totalUniqueItems
        started
        latestChange
        cartOpen
        expirationDate
        cartExpired
      }
    }
  }
`

export default withStyles(styles)(SuccessPage)
