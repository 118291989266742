import React, { useState } from "react"
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardHeader,
  ClickAwayListener,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  Paper,
  SvgIcon,
  TextField,
  Typography,
} from "@material-ui/core"

import { withStyles } from "@material-ui/styles"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import { Alert } from "@material-ui/lab"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"
import {
  Edit3 as EditIcon,
  Check as CheckIcon,
  Plus as PlusIcon,
  X as RemoveIcon,
} from "react-feather"

const UserEditName = ({ classes, uid, firstName, lastName }) => {
  const [editName, setEditName] = useState(false)
  const [fName, setFName] = useState(firstName)
  const [lName, setLName] = useState(lastName)
  const [submitting, setSubmitting] = useState(false)
  const [name, setName] = useState(firstName + " " + lastName)
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState("")

  const [updateName, { loading, error }] = useMutation(UPDATE_NAME_MUTATION, {
    variables: {
      uid: uid,
      firstName: fName,
      lastName: lName,
    },
    onCompleted: data => {
      console.log(data)
      const newMessage = data.updatePersonName.message
      const newFirstName = data.updatePersonName.person.firstName
      const newLastName = data.updatePersonName.person.lastName
      setName(newFirstName + " " + newLastName)
      setMessage(newMessage)
      setSubmitting(false)
      setEditName(!editName)
      setSuccess(true)
    },
  })

  const handleSubmit = event => {
    updateName(event)
  }

  const handleSwitch = e => {
    e.preventDefault()
    setEditName(!editName)
  }
  const handleClickAway = () => {
    setEditName(false)
    setFName(firstName)
    setLName(lastName)
  }
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <Card square variant="outlined" className={classes.card}>
      <CardHeader
        avatar={<AccountCircleIcon />}
        title="Your name"
        // subheader=""
      />
      {editName ? (
        <>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box mt={1} width="100%">
              <form
                id="changeName"
                onSubmit={event => {
                  event.preventDefault()
                  setSubmitting(true)
                  handleSubmit(event)
                }}
                className={classes.form}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <FormControl required fullWidth>
                      <TextField
                        label="First Name"
                        id="firstName"
                        variant="outlined"
                        size="small"
                        value={fName}
                        onChange={event => setFName(event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <FormControl required fullWidth>
                      <TextField
                        label="Last Name"
                        id="lastName"
                        variant="outlined"
                        size="small"
                        value={lName}
                        onChange={event => setLName(event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Box className={classes.iconBox}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        fullWidth
                        className={classes.iconButton}
                        aria-label="update name"
                        startIcon={
                          <SvgIcon className={classes.icon}>
                            <CheckIcon />
                          </SvgIcon>
                        }
                      >
                        Update
                      </Button>
                      {/* <IconButton
                        size="small"
                        edge="right"
                        color="primary"
                        className={classes.iconButton}
                        aria-label="confirm name"
                        type="submit"
                      >
                        <SvgIcon className={classes.icon}>
                          <CheckIcon />
                        </SvgIcon>
                      </IconButton> */}
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </ClickAwayListener>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <ButtonBase
              focusRipple
              className={classes.menuButton}
              // focusVisibleClassName={classes.focusVisible}
              style={{
                width: "100%",
                display: "block",
                padding: 10,
              }}
              onClick={handleSwitch}
            >
              <Typography className={classes.buttonText}>{name}</Typography>
            </ButtonBase>
            {/* <Typography gutterBottom>{name}</Typography> */}
            {/* <Typography gutterBottom>{message}</Typography> */}
            {success && message && (
              <Alert
                onClose={() => {
                  setSuccess(false)
                }}
              >
                {message}
              </Alert>
            )}
          </Grid>
          {/* <Grid item xs={1}>
            <IconButton
              size="small"
              edge="right"
              color="primary"
              className={classes.iconButton}
              aria-label="edit name"
              onClick={handleSwitch}
            >
              <SvgIcon>
                <EditIcon />
              </SvgIcon>
            </IconButton>
          </Grid> */}
        </>
      )}
    </Card>
  )
}
const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
    boxShadow: "none",
  },
  buttonText: {
    textAlign: "left",
  },
  card: {
    width: "100%",
    padding: theme.spacing(1),
  },
  field: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    color: "primary",
    variant: "outlined",
    // width: 400,
  },
  iconBox: {
    alignItems: "right",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  icon: {
    size: "20px",
  },
  iconButton: {
    padding: 5,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  disabled: {
    color: theme.palette.common.black,
    opacity: 1,
  },
  menuButton: {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.action.hoverLight,

      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  paper: {
    padding: theme.spacing(1),
    minHeight: 220,
  },
  title: {
    // fontSize: 14,
  },
  form: {
    display: "flex",
    width: "100%",
  },
  pos: {
    marginBottom: 12,
  },
  focusVisible: {},
})

const UPDATE_NAME_MUTATION = gql`
  mutation($uid: String!, $firstName: String!, $lastName: String!) {
    updatePersonName(uid: $uid, firstName: $firstName, lastName: $lastName) {
      message
      person {
        firstName
        lastName
      }
    }
  }
`
export default withStyles(styles)(UserEditName)
