import React, { useState } from "react"
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardHeader,
  ClickAwayListener,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import { Alert } from "@material-ui/lab"

import ShareIcon from "@material-ui/icons/Share"

import gql from "graphql-tag"
import { useMutation, useQuery } from "@apollo/client"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"
import useDidMountEffect from "../../../utils/hooks/useDidMountEffect"

import ControlPointIcon from "@material-ui/icons/ControlPoint"

const UserAddEditSocial = ({ classes, uid, socials }) => {
  const [socialsList, setSocialsList] = useState(socials)
  const [success, setSuccess] = useState(false)

  const [addSocial, setAddSocial] = useState(false)
  const [deleteSocialId, setDeleteSocialId] = useState(null)
  const [activeSocial, setActiveSocial] = useState(null)
  const [activeSocialId, setActiveSocialId] = useState(null)

  const [socialMediaTypes, setSocialMediaTypes] = useState("")
  const [socialMediaType, setSocialMediaType] = useState("")
  const [socialMediaTypeId, setSocialMediaTypeId] = useState(1)
  const [username, setUsername] = useState("")
  const [url, setUrl] = useState("")
  const [message, setMessage] = useState("")

  const { data, loading, error } = useQuery(GET_FORM_INFO, {
    fetchPolicy: "cache-first",
    onCompleted: data => {
      setSocialMediaTypes(data.socialMediaTypes)
    },
  })

  const [addSocialAccount] = useMutation(ADD_SOCIAL_MEDIA_MUTATION, {
    variables: {
      personUid: uid,
      socialMediaTypeId: socialMediaTypeId,
      username: username,
      url: url,
    },
    onCompleted: data => {
      let newAccount =
        data.createSocialMediaAccountPerson.socialMediaAccountPerson
      setSuccess(true)
      setAddSocial(false)
      setMessage(data.createSocialMediaAccountPerson.message)
      setSocialsList(prevSelected => [...prevSelected, newAccount])
      setUsername("")
      setUrl("")
      setSocialMediaType("")
      setSocialMediaTypeId(1)
    },
  })

  const [updateSocialAccount] = useMutation(UPDATE_SOCIAL_MEDIA_MUTATION, {
    variables: {
      id: activeSocialId,
      socialMediaTypeId: socialMediaType,
      username: username,
    },
    onCompleted: data => {
      console.log(data)
      let updatedAccount = data.updateMySocialMedia.socialMediaAccountPerson

      let updatedSocialIndex = socialsList.findIndex(
        sm => sm.id === updatedAccount.id
      )
      const updatedAccounts = [
        ...socialsList.slice(0, updatedSocialIndex),
        updatedAccount,
        ...socialsList.slice(updatedSocialIndex + 1),
      ]
      setSocialsList(updatedAccounts)
      setMessage(data.updateMySocialMedia.message)
      setSuccess(true)
      setActiveSocial(null)
      setActiveSocialId(null)
      setUsername("")
      setSocialMediaType("")
      setSocialMediaTypeId("")
    },
  })

  const [deleteSocialMedia] = useMutation(DELETE_SOCIAL_MEDIA_MUTATION, {
    variables: {
      id: deleteSocialId,
    },
    onCompleted: data => {
      setMessage(data.deleteSocialMediaAccountPerson.message)
      const filteredSocials = socialsList.filter(sm => sm.id != deleteSocialId)
      setSocialsList(filteredSocials)
      setDeleteSocialId(null)
      setUsername("")
      setSuccess(true)
    },
  })

  useDidMountEffect(() => {
    if (deleteSocialId > 0) deleteSocialMedia()
  }, [deleteSocialId])

  const handleClickAway = () => {
    setUsername("")
    setActiveSocial(null)
    setSocialMediaType("")
  }
  const handleUpdateSocialMedia = () => {
    updateSocialAccount()
  }

  const handleSwitchAdd = e => {
    e.preventDefault()
    setAddSocial(!addSocial)
  }

  const handleSubmitNewSocial = event => {
    addSocialAccount(event)
  }

  const handleRemoveSocial = sm => {
    setDeleteSocialId(sm.id)
  }

  const handleEditActive = (sm, event) => {
    console.log(sm.id)
    setUsername(sm.username)
    setActiveSocial(sm)
    setActiveSocialId(sm.id)
    setSocialMediaTypeId(1)
    // setSocialMediaType(1)
    // setSocialMediaTypeId(sm.socialMediaType.id)
    setSocialMediaType(sm.socialMediaType.id)
  }

  const handleSocialMediaTypeChange = event => {
    let value = 0
    if (event.target.value !== 0) {
      value = event.target.value
    }
    // const smtype = socialMediaTypes.filter(x => x.id == value).pop()

    // setSocialMediaTypeUrl(smtype.url)
    setSocialMediaTypeId(value)
    setSocialMediaType(value)
  }

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Card square variant="outlined" className={classes.card}>
      <CardHeader avatar={<ShareIcon />} title="Social Media" />

      {socialsList &&
        socialsList.map(sm => {
          const activeId = sm.id

          return (
            <Box key={sm.id} className={classes.editBox} width="100%">
              {sm === activeSocial ? (
                <>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box mt={1} width="100%">
                      <form
                        className={classes.editBox}
                        onSubmit={event => {
                          event.preventDefault()
                          handleUpdateSocialMedia(event)
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={5}>
                            <TextField
                              label="Username / handle"
                              id="Social Id Name"
                              type="text"
                              variant="outlined"
                              fullWidth
                              size="small"
                              value={username}
                              onChange={event =>
                                setUsername(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Social Media Type"
                              id="SocialMediaTypeList"
                              type="text"
                              select
                              variant="outlined"
                              fullWidth
                              size="small"
                              SelectProps={{ native: true }}
                              value={socialMediaType}
                              onChange={handleSocialMediaTypeChange}
                            >
                              {socialMediaTypes.map(st => (
                                <option key={st.id} value={st.id}>
                                  {st.name}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box ml={2} display="inline">
                              <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={classes.iconButton}
                                aria-label="update social media"
                              >
                                Update
                              </Button>
                            </Box>
                            <Box ml={2} display="inline">
                              <Button
                                color="secondary"
                                variant="contained"
                                className={classes.iconButton}
                                aria-label="remove social media"
                                size="small"
                                onClick={e => handleRemoveSocial(sm)}
                              >
                                Remove
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </ClickAwayListener>
                </>
              ) : (
                <>
                  <ButtonBase
                    focusRipple
                    className={classes.menuButton}
                    style={{
                      width: "100%",
                      display: "block",
                      padding: 10,
                    }}
                    onClick={event => handleEditActive(sm)}
                  >
                    <Typography className={classes.buttonText}>
                      {`${sm.username} (${sm.socialMediaType.name})`}
                    </Typography>
                  </ButtonBase>
                </>
              )}
            </Box>
          )
        })}
      {addSocial ? (
        <ClickAwayListener onClickAway={event => setAddSocial(!addSocial)}>
          <Box mt={1} width="100%">
            <form
              onSubmit={event => {
                event.preventDefault()
                handleSubmitNewSocial(event)
              }}
              id="addSocialForm"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                  <TextField
                    label="Username / handle"
                    id="Social Username"
                    type="text"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={username}
                    onChange={event => setUsername(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Social Media Type"
                    id="SocialMediaType"
                    type="text"
                    select
                    variant="outlined"
                    fullWidth
                    size="small"
                    SelectProps={{ native: true }}
                    value={socialMediaTypeId}
                    onChange={handleSocialMediaTypeChange}
                  >
                    {socialMediaTypes.map(st => (
                      <option key={st.id} value={st.id}>
                        {st.name ? st.name : ""}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box ml={2} display="inline">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={classes.iconButton}
                      aria-label="update phone"
                    >
                      Add Account
                    </Button>
                  </Box>
                  <Box ml={2} display="inline">
                    <Button
                      color="secondary"
                      variant="outlined"
                      className={classes.iconButton}
                      aria-label="remove phone"
                      size="small"
                      onClick={event => setAddSocial(!addSocial)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </ClickAwayListener>
      ) : (
        <Box p={1} width="100%">
          <Button
            color="primary"
            variant="outlined"
            aria-label="add new account"
            size="small"
            onClick={handleSwitchAdd}
            startIcon={<ControlPointIcon />}
          >
            Add a social media account
          </Button>
        </Box>
      )}

      {success && message && (
        <Box width="100%" mt={2}>
          <Alert
            onClose={() => {
              setSuccess(false)
            }}
          >
            {message}
          </Alert>
        </Box>
      )}
    </Card>
  )
}
const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
    boxShadow: "none",
  },
  card: {
    width: "100%",
    padding: theme.spacing(1),
  },
  field: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    // width: 400,
  },
  buttonText: {
    textAlign: "left",
  },
  menuButton: {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.action.hoverLight,

      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 5,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  disabled: {
    color: theme.palette.common.black,
    opacity: 1,
  },

  title: {
    // fontSize: 14,
  },
})

const GET_FORM_INFO = gql`
  query {
    socialMediaTypes {
      id
      name
    }
  }
`

const ADD_SOCIAL_MEDIA_MUTATION = gql`
  mutation(
    $personUid: String!
    $socialMediaTypeId: Int!
    $username: String!
    $url: String
  ) {
    createSocialMediaAccountPerson(
      personUid: $personUid
      socialMediaTypeId: $socialMediaTypeId
      username: $username
      url: $url
    ) {
      socialMediaAccountPerson {
        id
        username
        socialMediaType {
          id
          name
          url
        }
        url
      }
      message
    }
  }
`
const UPDATE_SOCIAL_MEDIA_MUTATION = gql`
  mutation($id: Int!, $socialMediaTypeId: Int!, $username: String!) {
    updateMySocialMedia(
      id: $id
      socialMediaTypeId: $socialMediaTypeId
      username: $username
    ) {
      socialMediaAccountPerson {
        id
        username
        socialMediaType {
          id
          name
          url
        }
        url
      }
      message
    }
  }
`

const DELETE_SOCIAL_MEDIA_MUTATION = gql`
  mutation($id: Int!) {
    deleteSocialMediaAccountPerson(id: $id) {
      message
    }
  }
`
export default withStyles(styles)(UserAddEditSocial)
