import React, { useState } from "react"
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardHeader,
  ClickAwayListener,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import MuiPhoneNumber from "mui-phone-input-ssr"
import ControlPointIcon from "@material-ui/icons/ControlPoint"
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid"
import IconButton from "@material-ui/core/IconButton"
import SvgIcon from "@material-ui/core/SvgIcon"

import { Alert } from "@material-ui/lab"
import gql from "graphql-tag"
import { useMutation, useQuery } from "@apollo/client"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"
import useDidMountEffect from "../../../utils/hooks/useDidMountEffect"

import {
  Edit3 as EditIcon,
  Check as CheckIcon,
  Plus as PlusIcon,
  X as RemoveIcon,
} from "react-feather"

const phoneTypes = [
  {
    id: 1,
    name: "Mobile",
  },
  {
    id: 4,
    name: "Phone App (i.e. WhatsApp)",
  },
  {
    id: 3,
    name: "Home Landline",
  },
  {
    id: 6,
    name: "Office / Work",
  },
]
const UserAddEditPhone = ({ classes, uid, phones }) => {
  const [addPhone, setAddPhone] = useState(false)
  const [phoneList, setPhoneList] = useState(phones)
  const [editPhone, setEditPhone] = useState(false)
  const [phoneType, setPhoneType] = useState(null)
  const [phoneTypeId, setPhoneTypeId] = useState(1)
  const [message, setMessage] = useState("")
  const [newPhone, setNewPhone] = useState("")
  const [activePhone, setActivePhone] = useState(null)
  const [activePhoneId, setActivePhoneId] = useState(null)
  const [activePhoneNumber, setActivePhoneNumber] = useState("")
  const [activePhoneType, setActivePhoneType] = useState(null)
  const [deletePhoneId, setDeletePhoneId] = useState(null)
  const [success, setSuccess] = useState(false)

  const [addNewPhone, { data, loading, error }] = useMutation(
    ADD_PHONE_MUTATION,
    {
      variables: {
        uid: uid,
        phoneTypeId: phoneTypeId,
        phoneNumber: newPhone,
      },
      onCompleted: data => {
        const createdPhone = data.createPhoneNumberPerson.phoneNumber
        setMessage(data.createPhoneNumberPerson.message)
        setPhoneList(prevSelected => [...prevSelected, createdPhone])
        setSuccess(true)
        setAddPhone(false)
        setActivePhone(null)
        setActivePhoneId(null)
        setActivePhoneNumber("")
        setActivePhoneType(null)
        setNewPhone("")
        setPhoneTypeId(1)
      },
    }
  )
  const [updatePhone] = useMutation(UPDATE_PHONE_MUTATION, {
    variables: {
      phoneNumberId: activePhoneId,
      uid: uid,
      phoneTypeId: phoneTypeId,
      number: newPhone,
    },
    onCompleted: data => {
      // const updatedPhone = data.updatePhoneNumberPerson.phoneNumber

      let updatedPhone = data.updatePhoneNumberPerson.phoneNumber
      let updatedPhoneIndex = phoneList.findIndex(
        ph => ph.id === updatedPhone.id
      )
      const updatedPhones = [
        ...phoneList.slice(0, updatedPhoneIndex),
        updatedPhone,
        ...phoneList.slice(updatedPhoneIndex + 1),
      ]
      setPhoneList(updatedPhones)
      setSuccess(true)
      setNewPhone("")
      setActivePhone(null)
      setActivePhoneId(null)
      setActivePhoneNumber("")
      setPhoneTypeId(1)
      setActivePhoneType(null)
      setMessage(data.updatePhoneNumberPerson.message)
    },
  })
  const [deletePhone] = useMutation(DELETE_PHONE_MUTATION, {
    variables: {
      phoneNumberId: deletePhoneId,
    },
    onCompleted: data => {
      setMessage(data.deletePhoneNumberPerson.message)
      const filteredPhones = phoneList.filter(ph => ph.id != deletePhoneId)
      setActivePhoneId(null)
      setPhoneList(filteredPhones)
      setSuccess(true)
    },
  })

  useDidMountEffect(() => {
    if (deletePhoneId > 0) deletePhone()
  }, [deletePhoneId])

  const handleSwitchAdd = e => {
    e.preventDefault()
    setAddPhone(!addPhone)
  }

  const handleEditActive = ph => {
    console.log(ph.id)
    setActivePhone(ph)
    setActivePhoneId(ph.id)
    setNewPhone(ph.phoneNumber)
    setActivePhoneNumber(ph.phoneNumber)
    setPhoneTypeId(ph.phoneType.id)
    setActivePhoneType(ph.phoneType)
  }
  function handlePhoneChange(value) {
    setNewPhone(value)
  }

  const handleUpdatePhone = event => {
    updatePhone(event)
  }
  const handleClickAway = () => {
    setActivePhone(null)
    setActivePhoneId(null)
    setActivePhoneType(null)
    setPhoneTypeId(1)
  }
  const handleRemovePhone = ph => {
    setDeletePhoneId(ph.id)
  }
  const handleSubmitNewPhone = event => {
    addNewPhone(event)
  }
  return (
    <Card variant="outlined" className={classes.card}>
      <CardHeader
        avatar={<PhoneAndroidIcon />}
        title="Phone"
        // subheader=""
      />

      {phoneList &&
        phoneList.map(ph => {
          const activeId = ph.id
          return (
            <Box key={ph.id} className={classes.editBox} width="100%">
              {ph === activePhone ? (
                <>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box mt={1} width="100%">
                      <form
                        className={classes.editBox}
                        onSubmit={event => {
                          event.preventDefault()
                          handleUpdatePhone(event)
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={5}>
                            <MuiPhoneNumber
                              label="Phone Number"
                              id="phone"
                              value={activePhoneNumber}
                              fullWidth
                              variant="outlined"
                              defaultCountry="us"
                              regions={["america", "europe", "asia"]}
                              disableAreaCodes
                              onChange={handlePhoneChange}
                            />
                            {/* <MuiPhoneInput
                              label="Phone Number"
                              id="phone"
                              value={activePhoneNumber}
                              variant="outlined"
                              size="small"
                              fullWidth
                              defaultCountry="us"
                              regions={["america", "europe", "asia"]}
                              disableAreaCodes
                              onChange={handlePhoneChange}
                            /> */}
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="Phone Type"
                              id="PhoneType"
                              type="text"
                              select
                              variant="outlined"
                              fullWidth
                              size="small"
                              SelectProps={{ native: true }}
                              value={phoneTypeId}
                              onChange={event =>
                                setPhoneTypeId(event.target.value)
                              }
                            >
                              {phoneTypes.map(pt => (
                                <option key={pt.id} value={pt.id}>
                                  {pt.name}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box ml={2} display="inline">
                              <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={classes.iconButton}
                                aria-label="update phone"
                              >
                                Update
                              </Button>
                            </Box>
                            <Box ml={2} display="inline">
                              <Button
                                color="secondary"
                                variant="contained"
                                className={classes.iconButton}
                                aria-label="remove phone"
                                size="small"
                                onClick={e => handleRemovePhone(ph)}
                              >
                                Remove
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </ClickAwayListener>
                </>
              ) : (
                <>
                  <ButtonBase
                    focusRipple
                    className={classes.menuButton}
                    style={{
                      width: "100%",
                      display: "block",
                      padding: 10,
                    }}
                    onClick={e => handleEditActive(ph)}
                  >
                    <Typography className={classes.buttonText}>
                      {`${ph.phoneNumber} (${ph.phoneType.phoneType})`}
                    </Typography>
                  </ButtonBase>
                </>
              )}
            </Box>
          )
        })}
      {addPhone ? (
        <ClickAwayListener onClickAway={event => setAddPhone(!addPhone)}>
          <Box mt={1} width="100%">
            <form
              onSubmit={event => {
                event.preventDefault()
                handleSubmitNewPhone(event)
              }}
              id="addPhoneForm"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                  <MuiPhoneNumber
                    label="Phone Number"
                    id="phone"
                    value={activePhoneNumber}
                    fullWidth
                    margin="dense"
                    size="small"
                    defaultCountry="us"
                    regions={["america", "europe", "asia"]}
                    disableAreaCodes
                    onChange={handlePhoneChange}
                  />
                  {/* <MuiPhoneInput
                    label="Phone Number"
                    id="phone"
                    value={activePhoneNumber}
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultCountry="us"
                    regions={["america", "europe", "asia"]}
                    disableAreaCodes
                    onChange={handlePhoneChange}
                  /> */}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Phone Type"
                    id="PhoneType"
                    type="text"
                    select
                    variant="outlined"
                    fullWidth
                    size="small"
                    SelectProps={{ native: true }}
                    value={phoneTypeId}
                    onChange={event => setPhoneTypeId(event.target.value)}
                  >
                    {phoneTypes.map(pt => (
                      <option key={pt.id} value={pt.id}>
                        {pt.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box ml={2} display="inline">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={classes.iconButton}
                      aria-label="update phone"
                    >
                      Add Phone
                    </Button>
                  </Box>
                  <Box ml={2} display="inline">
                    <Button
                      color="secondary"
                      variant="outlined"
                      className={classes.iconButton}
                      aria-label="remove phone"
                      size="small"
                      onClick={event => setAddPhone(!addPhone)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </ClickAwayListener>
      ) : (
        <Box p={1}>
          <Button
            color="primary"
            variant="outlined"
            aria-label="add new email"
            size="small"
            onClick={handleSwitchAdd}
            startIcon={<ControlPointIcon />}
          >
            Add a phone number
          </Button>
        </Box>
      )}
      {success && message && (
        <Box width="100%" mt={2}>
          <Alert
            onClose={() => {
              setSuccess(false)
            }}
          >
            {message}
          </Alert>
        </Box>
      )}
    </Card>
  )
}
const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
    boxShadow: "none",
  },

  buttonText: {
    textAlign: "left",
  },
  menuButton: {
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.action.hoverLight,

      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  field: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    // width: 400,
  },
  card: {
    width: "100%",
    padding: theme.spacing(1),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 5,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  disabled: {
    color: theme.palette.common.black,
    opacity: 1,
  },

  title: {
    // fontSize: 14,
  },
})

const ADD_PHONE_MUTATION = gql`
  mutation($uid: String, $phoneTypeId: Int, $phoneNumber: String) {
    createPhoneNumberPerson(
      uid: $uid
      phoneTypeId: $phoneTypeId
      phoneNumber: $phoneNumber
    ) {
      phoneNumber {
        id
        phoneType {
          id
          phoneType
        }
        phoneNumber
      }
      message
    }
  }
`
const UPDATE_PHONE_MUTATION = gql`
  mutation(
    $phoneNumberId: Int!
    $uid: String
    $phoneTypeId: Int
    $number: String
  ) {
    updatePhoneNumberPerson(
      phoneNumberId: $phoneNumberId
      uid: $uid
      phoneTypeId: $phoneTypeId
      number: $number
    ) {
      phoneNumber {
        id
        phoneType {
          id
          phoneType
        }
        phoneNumber
      }
      message
    }
  }
`
const DELETE_PHONE_MUTATION = gql`
  mutation($phoneNumberId: Int!) {
    deletePhoneNumberPerson(phoneNumberId: $phoneNumberId) {
      message
    }
  }
`
export default withStyles(styles)(UserAddEditPhone)
