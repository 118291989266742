import React from "react"
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import { Link } from "gatsby"
import { withStyles } from "@material-ui/styles"
import { useCart } from "./Cart"
import UserCartQtySelect from "./UserCartQtySelect"
import CartItemCard from "./CartItemCard"
import { ShoppingCart as CartIcon } from "react-feather"
import BigBlackButton from "../../DropIn/BigBlackButton"
import { navigate } from "gatsby"
export const UserCartDetails = ({ classes }) => {
  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    items,
    updateItemQuantity,
    updateItemPrice,
    removeItem,
    emptyCart,
  } = useCart()
  if (isEmpty)
    return (
      <div className={classes.root}>
        <Typography variant="subtitle1" className={classes.title}>
          My Cart (0 items)
        </Typography>
        <Box className={classes.signInBox}>
          <SvgIcon
            className={classes.iconBox}
            style={{
              height: "100px",
              width: "100px",
              color: "#949494",
            }}
          >
            <CartIcon />
          </SvgIcon>
          <Box style={{ width: "100%" }}>
            <Typography
              align="center"
              style={{
                fontSize: "20px",
                fontWeight: "lighter",
                marginBottom: "15px",
              }}
            >
              Your cart is currently empty.
            </Typography>
            <Typography
              align="center"
              variant="subtitle1"
              style={{
                marginBottom: "30px",
              }}
            >
              Browse your items or request a new custom quote.
            </Typography>
          </Box>
          <BigBlackButton
            buttonText="Browse Items"
            action={() => navigate("/my/items")}
          />
        </Box>
      </div>
    )

  return (
    <div className={classes.root}>
      <Card square className={classes.paper}>
        <CardContent>
          <Grid container direction="row" justify="space-between">
            <Grid item container direction="row" justify="space-between">
              <Grid item>
                <Typography variant="caption" className={classes.title}>
                  {items ? items.length : "No"}{" "}
                  {items.length === 1 ? "item" : "items"} in your cart
                </Typography>
              </Grid>
              <Grid item>
                <Link to="/my/items" className={classes.link}>
                  <Typography variant="caption" className={classes.title}>
                    Browse more items
                  </Typography>
                </Link>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
              {items.map(item => (
                <Box mt={2} key={item.id}>
                  <CartItemCard item={item} />
                </Box>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

const styles = theme => ({
  root: {
    alignItems: "center",
    // margin: theme.spacing(1, 0.5, 0.5, 0.5),
    minWidth: 270,
    boxShadow: "none",
  },
  paper: {
    padding: theme.spacing(1),
    minHeight: 260,
    borderRadius: "15px",
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  title: {
    // fontSize: 14,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.link,
  },
  pos: {
    marginBottom: 12,
  },
  iconBox: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(3),
  },
  signInBox: {
    width: "300px",
    marginTop: theme.spacing(5),
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
})

export default withStyles(styles)(UserCartDetails)
