import React, { useEffect, useContext, useState } from "react"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import MessagesContext from "../context/MessagesContext"
import HomeNav from "./HomeNav"
import {
  Drawer,
  Hidden,
  Box,
  List,
  Button,
  Divider,
  makeStyles,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import {
  MessageSquare as MessageSquareIcon,
  PlusCircle as PlusCircleIcon,
  List as ListIcon,
} from "react-feather"
import LabelItem from "./LabelItem"

const labels = [
  {
    id: "conversations",
    name: "All Conversations",
    type: "system_label",
  },
  {
    id: "invites",
    name: "Invites",
    type: "system_label",
  },

  {
    id: "updates",
    name: "New Messages",
    type: "system_label",
  },
  {
    id: "archived",
    name: "Archived",
    type: "system_label",
  },
  {
    id: "starred",
    name: "Starred",
    type: "system_label",
  },
]

const useStyles = makeStyles(theme => ({
  drawerDesktopRoot: {
    width: 280,
    flexShrink: 0,
  },
  drawerDesktopPaper: {
    position: "relative",
  },
  drawerMobilePaper: {
    position: "relative",
    width: 280,
  },
  drawerMobileBackdrop: {
    position: "absolute",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

function Sidebar() {
  const classes = useStyles()
  const { state, dispatch } = useContext(MessagesContext)

  const sidebarOpen = state.sidebarOpen

  const handleCloseSidebar = () => {
    dispatch({ type: "CLOSE_SIDEBAR" })
  }

  const content = (
    <div>
      <Hidden lgUp>
        <Box mt={2} />
        <HomeNav />
        <Box mt={4} />
      </Hidden>
      <Box px={3} py={2}>
        <SvgIcon fontSize="small" color="secondary" className={classes.icon}>
          <MessageSquareIcon />
        </SvgIcon>
        <Typography variant="h5" color="textPrimary" display="inline">
          Service Chats
        </Typography>
      </Box>
      <Divider />
      <Box px={3} py={2}>
        <Button
          color="secondary"
          fullWidth
          variant="outlined"
          startIcon={
            <SvgIcon
              fontSize="small"
              color="secondary"
              // className={classes.icon}
            >
              <ListIcon />
            </SvgIcon>
          }
          onClick={() =>
            dispatch({ type: "SET_PANEL", payload: "conversationList" })
          }
        >
          My Chats
        </Button>
      </Box>
      <Divider />
      <Box px={3} py={2}>
        <Button
          color="secondary"
          fullWidth
          variant="outlined"
          startIcon={
            <SvgIcon
              fontSize="small"
              color="secondary"
              className={classes.icon}
            >
              <PlusCircleIcon />
            </SvgIcon>
          }
          onClick={() =>
            dispatch({ type: "SET_PANEL", payload: "conversationNew" })
          }
        >
          New Chat
        </Button>
      </Box>

      {/* <Box py={2} pr={2}>
        <List>
          {labels.map(label => {
            return <LabelItem key={label.id} label={label} />
          })}
        </List>
      </Box> */}
    </div>
  )

  return (
    <>
      <Hidden mdDown>
        <Drawer
          variant="permanent"
          classes={{
            root: classes.drawerDesktopRoot,
            paper: classes.drawerDesktopPaper,
          }}
        >
          {content}
        </Drawer>
      </Hidden>

      <Hidden lgUp>
        <Drawer
          variant="temporary"
          open={sidebarOpen}
          onClose={handleCloseSidebar}
          classes={{
            paper: classes.drawerMobilePaper,
          }}
          style={{ position: "absolute", zIndex: 1200 }}
          BackdropProps={{ classes: { root: classes.drawerMobileBackdrop } }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

Sidebar.propTypes = {
  containerRef: PropTypes.any.isRequired,
}

export default Sidebar
