import React, { useState, useContext } from "react"
import { Box, Divider, Grid, makeStyles, Typography } from "@material-ui/core"
import Toolbar from "./Toolbar"
import ConversationItem from "./ConversationItem"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import MessagesContext from "../context/MessagesContext"
import Loading from "../../../Shared/Loading"
import Error from "../../../Shared/Error"
import { useSiteMetadata } from "../../../utils/hooks/useSiteMetadata"
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // flexGrow: 1,
    // display: "flex",
    // width: "100%",
    // overflow: "hidden",

    // marginRight: theme.spacing(2),
  },
}))

const ConversationList = () => {
  const { state, dispatch } = useContext(MessagesContext)
  const classes = useStyles()
  const { sbuId } = useSiteMetadata()
  const page = 1
  const pageSize = 10
  const search = ""
  // const page = state.page ? state.page : 1
  // const pageSize = state.pageSize ? state.pageSize : 15
  // const search = state.search ? state.search : ""
  // const archivedFilter = state.archivedFilter ? state.archivedFilter : false
  // const starredFilter = state.starredFilter ? state.starredFilter : false
  // const followingFilter = state.followingFilter ? state.followingFilter : false
  // const hasUpdateFilter = state.hasUpdateFilter ? state.hasUpdateFilter : false
  // const excludeArchivedFilter = state.excludeArchivedFilter
  // ? state.excludeArchivedFilter
  // : true

  const [selectedConversations, setSelectedConversations] = useState([])
  const [conversations, setConversations] = useState("")
  const [newConversations, setNewConversations] = useState("")

  // const { data, loading, error } = useQuery(USER_CONVERSATIONS_QUERY, {
  //   fetchPolicy: "cache-and-network",
  //   variables: {
  //     page: page,
  //     pageSize: pageSize,
  //     search: search,
  //     starredFilter: starredFilter,
  //     followingFilter: followingFilter,
  //     hasUpdateFilter: hasUpdateFilter,
  //     excludeArchivedFilter: excludeArchivedFilter,
  //     archivedFilter: archivedFilter,
  //   },
  //   onCompleted: data => {
  //     const objects = data.userConversations.objects
  //     setConversations(data.userConversations)
  //     setNewConversations(data.userConversationInvites)
  //     console.log(objects.length)
  //   },
  // })
  const { data, loading, error } = useQuery(USER_CONVERSATION_INVITES_QUERY, {
    // fetchPolicy: "cache-only",
    variables: {
      page: 1,
      pageSize: 10,
      search: "",
      sbuId: 1,
    },
    onCompleted: data => {
      setConversations(data.myConversations)
      setNewConversations(data.myConversations.objects)
    },
  })

  const handleUpdateCount = conversations => {
    // conversations.objects.filter(hasUpdate)
    dispatch({
      type: "UPDATE_COUNT",
      payload: conversations.length,
    })
  }
  const handleSelectOneConversation = convId => {
    setSelectedConversations(prevSelectedConversations => {
      if (!prevSelectedConversations.includes(convId)) {
        return [...prevSelectedConversations, convId]
      }

      return prevSelectedConversations
    })
  }

  const handleDeselectOneConversation = convId => {
    setSelectedConversations(prevSelectedConversations =>
      prevSelectedConversations.filter(id => id !== convId)
    )
  }

  const handleSelectAllConversations = () => {
    setSelectedConversations(conversations.objects.map(c => c.id))
  }
  const handleDeselectAllConversations = () => {
    setSelectedConversations([])
  }

  if (loading) return <Loading />
  if (error) return <Error />
  // const myData = data
  return (
    <>
      <div className={classes.root}>
        {/* {JSON.stringify(myData)} */}
        <Grid container alignItems="stretch">
          <Grid item xs={12}>
            {/* <div>Toolbar</div> */}
            <Toolbar
              onDeselectAll={handleDeselectAllConversations}
              onSelectAll={handleSelectAllConversations}
              selectedConversations={selectedConversations.length}
              conversations={conversations}
            />
            <Divider />
          </Grid>
          {conversations &&
            conversations.count > 0 &&
            conversations.objects.map(c => {
              const conv = c
              const convId = c.id
              return (
                <Grid item xs={12} key={c.id}>
                  {/* <Typography>{JSON.stringify(c)}</Typography> */}
                  <ConversationItem
                    conv={conv}
                    onDeselect={() => handleDeselectOneConversation(convId)}
                    onSelect={() => handleSelectOneConversation(convId)}
                    selected={selectedConversations.includes(convId)}
                  />
                </Grid>
              )
            })}
        </Grid>
      </div>
    </>
  )
}
const USER_CONVERSATION_INVITES_QUERY = gql`
  query ($page: Int, $pageSize: Int, $search: String, $sbuId: Int) {
    myConversations(
      page: $page
      pageSize: $pageSize
      search: $search
      sbuId: $sbuId
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        uuid
        topic
        startedBy {
          id
          staff
          person {
            uid
            firstName
            lastName
          }
        }
        participants {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        invited {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        sbu {
          id
          name
        }
        archived
        startDate
        archivedDate
        category {
          id
          name
        }
        conversationMessage {
          uuid
        }
        userInConversation {
          id
          conversation {
            uuid
          }
          user {
            id
            person {
              uid
              firstName
              lastName
            }
          }
          follow
          star
          starCount
          alerts
          hasUpdate
          withUpdatesCount
        }
      }
    }
  }
`
export default ConversationList
