import React, { useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import { useCart } from "./Cart"
import {
  Button,
  Grid,
  Hidden,
  IconButton,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  Menu as MenuIcon,
  PlusCircle as PlusCircleIcon,
  ShoppingCart as ShoppingCartIcon,
} from "react-feather"
import UserContext from "../../../context/UserContext"
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary.dark,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}))

function Header({ className, ...rest }) {
  const { state, dispatch } = useContext(UserContext)
  const classes = useStyles()
  const { isEmpty } = useCart()

  return (
    <div>
      <Grid
        container
        spacing={3}
        justify="space-between"
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Grid item>
          <SvgIcon fontSize="small" color="secondary" className={classes.icon}>
            <ShoppingCartIcon />
          </SvgIcon>

          <Typography variant="h5" display="inline" className={classes.title}>
            Cart
          </Typography>
        </Grid>

        <Hidden mdUp>
          <Grid item>
            <IconButton onClick={() => dispatch({ type: "TOGGLE_MOBILE_NAV" })}>
              <SvgIcon fontSize="small" color="secondary">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
