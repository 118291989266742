import React, { useContext, useReducer, useState, useEffect } from "react"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Container, Divider } from "@material-ui/core"
import CommentsContext from "./context"
import commentsReducer from "./reducer"
import Header from "./Header"
import ImageStage from "./components/ImageStage"
import withStyles from "@material-ui/core/styles/withStyles"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"

import ArtApproved from "./components/ArtApproved"
import ProtoApproved from "./components/ProtoApproved"
import Discrepancy from "./components/Discrepancy"

const ItemSampleProof = ({ classes, proofId }) => {
  const initialState = useContext(CommentsContext)
  const [state, dispatch] = useReducer(commentsReducer, initialState)
  const [view, setView] = useState("stage")

  const { data, loading, error } = useQuery(SAMPLE_PROOF_QUERY, {
    variables: {
      id: proofId,
    },
    onCompleted: data => {
      console.log(data.sampleProof)
      // idString is used to name the marked up file if the user requests changes
      const statusId = data.sampleProof.status.id
      console.log(data.sampleProof.status)
      if (statusId == 4) {
        setView("artApproved")
      }
      if (statusId == 7) {
        setView("protoApproved")
      }
      if (statusId == 8) {
        setView("discrepancy")
      }
      // if (statusId == 4) {
      //   dispatch({ type: "SHOW_CONFIRMATION" })
      // }
      // if (statusId == 7) {
      //   dispatch({ type: "SHOW_CONFIRMATION" })
      // }
      // if (statusId == 8) {
      //   dispatch({ type: "SHOW_DISCREPANCY" })
      // } else {
      let idString =
        "item_" + data.sampleProof.item.id + "_" + "v" + data.sampleProof.id
      let myName =
        data.sampleProof.customer.firstName +
        " " +
        data.sampleProof.customer.lastName
      dispatch({ type: "SET_PROOF_ID", payload: proofId })
      dispatch({ type: "SET_MY_NAME", payload: myName })
      dispatch({ type: "SET_ID_STRING", payload: idString })
      dispatch({ type: "CLEAR_CONFIRMATION" })
      // }
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />
  const sampleProof = data.sampleProof
  // if (data.sampleProof.status.id == 4)
  //   return <ArtApproved sampleProof={sampleProof} />
  // if (data.sampleProof.status.id == 7)
  //   return <ProtoApproved sampleProof={sampleProof} />
  // if (data.sampleProof.status.id == 8)
  //   return <Discrepancy sampleProof={sampleProof} />

  const pageView = view => {
    switch (view) {
      case "discrepancy":
        return <Discrepancy sampleProof={sampleProof} />
      case "artApproved":
        return <ArtApproved sampleProof={sampleProof} />
      case "protoApproved":
        return <ProtoApproved sampleProof={sampleProof} />
      case "stage":
        return (
          <ImageStage sampleProof={sampleProof} view={view} setView={setView} />
        )
      default:
        return (
          <ImageStage sampleProof={sampleProof} view={view} setView={setView} />
        )
    }
  }
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <CommentsContext.Provider value={{ state, dispatch }}>
          <Header sampleProof={sampleProof} />
          <Divider className={classes.divider} />
          {/* <ImageStage sampleProof={sampleProof} /> */}
          {pageView(view)}
        </CommentsContext.Provider>
      </Container>
    </div>
  )
}

const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(0, 0.5, 0.5, 0.5),

    // margin: theme.spacing(1, 0, 0),
  },
  container: {
    display: "grid",
  },
  itemImage: {
    height: 420,
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
})

const SAMPLE_PROOF_QUERY = gql`
  query ($id: Int) {
    sampleProof(id: $id) {
      id
      item {
        id
        name
      }
      customer {
        uid
        firstName
        lastName
      }
      status {
        id
        name
      }
      version
      image
      isVertical
      timestamp
      closed
      closedTimestamp
      sampleProofUrl
      isPrototypePhoto
      sampleCommentImage {
        id
        commentImage
        commentImageUrl
        uploadedBy {
          uid
          firstName
          lastName
        }
        sampleProofImageComment {
          id
          comment
          commentTool
          timestamp
          commentColor
        }
      }
    }
  }
`

export default withStyles(styles)(ItemSampleProof)
