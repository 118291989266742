import React, { useState } from "react"
import { Link } from "gatsby"
import { Box, Container, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

const ArtApproved = ({ classes, sampleProof }) => {
  return (
    <div className={classes.root}>
      <Container>
        <Grid container>
          <Grid item>
            <Typography variant="h6">{sampleProof.item.name}</Typography>
            <Typography>
              Item: {sampleProof.item.id} - Version: {sampleProof.version}
            </Typography>
            <Typography variant="h5" className={classes.message}>
              Art/Proof Approved
            </Typography>
            <Typography gutterBottom>
              You approved this art file. Your item is ready to proceed to the
              next step.
            </Typography>
            <Typography gutterBottom>
              If you are unclear about what should happen next or if you have
              any other questions, feel free to{" "}
              <Link className={classes.link} to="/my/service">
                send a message
              </Link>
              .
            </Typography>
          </Grid>
          <Grid item>
            <img src={sampleProof.sampleProofUrl} alt={sampleProof.image} />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  link: {
    color: theme.palette.link,
    textDecoration: "none",
  },
  message: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
})
export default withStyles(styles)(ArtApproved)
