import React, { useState, useEffect, useContext } from "react"
import gql from "graphql-tag"
import clsx from "clsx"
import { useQuery } from "@apollo/client"
import { withStyles } from "@material-ui/styles"
import { useCart } from "./Cart"
import {
  Box,
  ButtonBase,
  CardMedia,
  Divider,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
} from "@material-ui/core"

import { Link } from "gatsby"
import { XSquare as RemoveSquareIcon } from "react-feather"
import Loading from "../../utils/Loading"
import Error from "../../utils/Error"

function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const CartItemCard = ({ item, classes }) => {
  const id = item.id
  const quantity = item.quantity
  const [itemQty, setItemQty] = useState(item.quantity)
  const [itemPrice, setItemPrice] = useState(item.price)
  const [removing, setRemoving] = useState(false)
  const {
    items,
    updateItemQuantity,
    updateItemPrice,
    removeItem,
    totalUniqueItems,
  } = useCart()

  const itemIndex = items.indexOf(item)
  const { data, loading, error } = useQuery(CART_ITEM_QUERY, {
    variables: {
      id: id,
    },
    onCompleted: data => {
      console.log(data)
    },
  })

  useEffect(() => {
    setItemQty(item.quantity)
  }, [])

  useEffect(() => {
    updateItemPrice(id, itemPrice)
  }, [itemPrice])

  useEffect(() => {}, [])

  if (loading) return <Loading />
  if (error) return <Error />
  // if (data.item === null) return <div>You are logged out. Please log in.</div>
  const cartItem = data.item
  const priceSet = data.item.itemPrice
  const category = data.item.category.name
  const isPrivate = data.item.isPrivate
  const thumbnail = data.item.featuredImageThumbnailUrl
  const categoryId = data.item.category && data.item.category.id

  const handleRemoveItem = id => {
    if (removing) {
      // handleRemove()
      removeItem(id)
    } else {
      // const filteredArray = orderItems.filter(x => x.id !== orderItemId)
      // setNewArray(filteredArray)
      setRemoving(true)
    }
  }
  const handleUpdate = event => {
    console.log(event.target.value)
    console.log(priceSet)
    let priceSetId = event.target.value
    var set = priceSet.find(ps => {
      return ps.id === priceSetId
    })
    console.log(set)

    // let price = event.target.value.price

    setItemQty(set.minQuantity)
    updateItemQuantity(id, set.minQuantity)

    setItemPrice(set.price)
  }
  return (
    <>
      <Box pb={1} pt={2}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xxs={4} xs={4} sm={2}>
            <CardMedia
              className={classes.media}
              image={thumbnail ? thumbnail : undefined}
              title={item.name}
            />
          </Grid>
          <Grid item xxs={8} xs={8} sm={4}>
            <Box ml={1} mb={2}>
              <Link
                className={classes.link}
                to={
                  isPrivate
                    ? `/my/items/pi/${id}`
                    : item.urlSlug
                    ? `/${item.urlSlug}`
                    : null
                }
                state={{ id: { id } }}
              >
                <Typography variant="body1">{item.name}</Typography>
              </Link>
              <Typography variant="body2">{category}</Typography>
              <Typography variant="caption">Item # {item.id}</Typography>

              {isPrivate && (
                <Typography variant="caption" display="block">
                  Exclusive / Private Item
                </Typography>
              )}
              {item.customItemField &&
                item.customItemField.map(field => (
                  <Box key={field.id}>{field.name}</Box>
                ))}
            </Box>
          </Grid>

          <Grid item xxs={4} xs={4} sm={2}>
            <Box ml={2}>
              <FormControl>
                <TextField
                  id="qty"
                  fullWidth
                  select
                  size="small"
                  // label="Qty"
                  value={quantity}
                  onChange={handleUpdate}
                  // variant="outlined"
                  SelectProps={{ native: true, padding: 1 }}
                  InputLabelProps={{ shrink: true }}
                >
                  <option className={classes.selected} selected>
                    {quantity}
                  </option>
                  {priceSet.map(ps => (
                    <option key={ps.id} value={ps.id}>
                      {ps.minQuantity}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </Box>
          </Grid>

          {/* <Grid item xs={1} sm={false}></Grid> */}

          <Grid item xxs={4} xs={4} sm={2}>
            ${financial(itemPrice)} ea
          </Grid>

          <Grid item xxs={4} xs={4} sm={2}>
            <Box className={classes.totalBox}>
              ${financial(itemQty * itemPrice)}
            </Box>
          </Grid>

          <Grid item xxs={12} xs={12}>
            <ButtonBase
              aria-label="remove"
              className={
                removing
                  ? clsx(classes.redDelete, classes.margin)
                  : classes.margin
              }
              onClick={() => handleRemoveItem(id)}
            >
              <SvgIcon fontSize="small">
                <RemoveSquareIcon />
              </SvgIcon>
              <Typography variant="caption"> remove</Typography>
            </ButtonBase>
          </Grid>

          {/* </Grid> */}
          {/* {item.taxAmt > 0 && (
              <>
                <Grid item xs={9}>
                  Tax
                </Grid>
                <Grid item xs={3}>
                  ${financial(oi.taxAmt)}
                </Grid>
              </>
            )}
            {item.discountAmt > 0 && (
              <>
                <Grid item xs={9}>
                  Discount
                </Grid>
                <Grid item xs={3}>
                  ${financial(oi.discountAmt)}
                </Grid>
              </>
            )} */}

          {/* <Grid item xs={9}>
              Item total
            </Grid> */}
          {/* <Grid item xs={3}>
              total */}
          {/* ${financial(oi.itemSubtotal)} */}
          {/* </Grid> */}
        </Grid>
      </Box>
      {itemIndex + 1 < totalUniqueItems && <Divider />}
    </>
  )
}
const CART_ITEM_QUERY = gql`
  query($id: Int) {
    item(id: $id) {
      id
      name
      isPrivate
      category {
        id
        name
      }
      pricingModel {
        id
        name
      }
      customItemField {
        id
        name
        description
        isActive
      }
      featuredImageThumbnailUrl
      specItem {
        unitWeightEach
        weightUnits
      }
      itemPrice {
        id
        minQuantity
        price
      }
    }
  }
`

const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
    boxShadow: "none",
  },
  selected: {
    display: "none",
  },
  redDelete: {
    color: "#FF0000",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.black,
  },
  media: {
    height: "80px",
    width: "80px",
    margin: theme.spacing(0, 2, 0, 0),
  },
  totalBox: {
    // marginTop: "100%",
  },
})
export default withStyles(styles)(CartItemCard)
