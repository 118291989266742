import React, { useState, useEffect, useContext, useRef } from "react"
import { Link } from "gatsby"
import Message from "./Message"
import moment from "moment"
// import Markdown from "react-markdown/with-html"
import clsx from "clsx"
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core"
import PerfectScrollbar from "react-perfect-scrollbar"
import MoreIcon from "@material-ui/icons/MoreVert"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ReplyIcon from "@material-ui/icons/ReplyOutlined"
import ReplyAllIcon from "@material-ui/icons/ReplyAllOutlined"
import DeleteIcon from "@material-ui/icons/DeleteOutlined"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
import getInitials from "../../../utils/getInitials"
import { useQuery, useMutation } from "@apollo/client"
import { NetworkStatus } from "@apollo/client"
import gql from "graphql-tag"
import SortContext from "../../../../context/SortContext"
import Loading from "../../../Shared/Loading"
import Error from "../../../Shared/Error"
import MessageAdd from "./MessageAdd"

import MessagesContext from "../context/MessagesContext"

import Toolbar from "./Toolbar"
import ConversationPersonSearch from "../ConversationNew/ConversationPersonSearch"
import { useSiteMetadata } from "../../../utils/hooks/useSiteMetadata"
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: "90vh",
    width: "100%",
    // height: "92vh",
    // marginBottom: theme.spacing(-2),
    // display: "flex",
    // flexDirection: "column",
    overflowY: "hidden",

    // [theme.breakpoints.up("md")]: {
    //   // height: "50vh",
    //   // height: "92vh",
    //   height: "96vh",
    //   maxHeight: "96vh",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   maxHeight: "91vh",
    // },
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  avatar: {
    height: 35,
    width: 35,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.accountLink,
  },
  date: {
    whiteSpace: "nowrap",
  },
  invited: {
    // display: "inline",
    height: "40px",
    maxHeight: "40px",
    padding: theme.spacing(1, 2, 1, 2),
  },
  message: {
    color: theme.palette.text.secondary,
    "& > p": {
      ...theme.typography.body1,
      marginBottom: theme.spacing(2),
    },
  },
  ipad: {
    height: "100%",
    maxHeight: "96vh",
  },
  ipadbox: {
    height: "calc(100vh - 280px)",
  },
  scrBox: {
    overflow: "hidden",
    [theme.breakpoints.up("xxs")]: {
      // height: "50vh",
      height: "calc(100vh - 285px)",
    },
    [theme.breakpoints.up("xs")]: {
      height: "calc(100vh - 275px)",
    },
    [theme.breakpoints.up("sm")]: {
      // height: "50vh",

      height: "calc(100vh - 310px)",
    },
    [theme.breakpoints.up("md")]: {
      // height: "50vh",
      height: "calc(100vh - 240px)",
    },
    [theme.breakpoints.up("lg")]: {
      // height: "50vh",
      height: "calc(100vh - 240px)",
    },
    height: "calc(100vh - 200px)",
    // height: "65vh",
  },
  invites: {
    backgroundColor: theme.palette.background.paper,
  },
}))

const ConversationDetails = () => {
  const classes = useStyles()
  const { state, dispatch } = useContext(MessagesContext)
  const uuid = state.selectedConversationId
  const { sbuId } = useSiteMetadata()
  const [messageList, setMessageList] = useState([])
  const [inviteList, setInviteList] = useState([])
  const [invitedListIds, setInvitedListIds] = useState([])
  const [participants, setParticipants] = useState([])
  const [participantIds, setParticipantIds] = useState([])
  const [addPeople, setAddPeople] = useState(false)

  const [conversationId, setConversationId] = useState("")
  const messagesRef = useRef(null)
  const [category, setCategory] = useState("")

  const [topic, setTopic] = useState("")
  const [archived, setArchived] = useState(false)
  const [latestMessage, setLatestMessage] = useState("")
  const { data, loading, error, refetch, networkStatus } = useQuery(
    GET_MY_CONVERSATION_DETAILS,
    {
      fetchPolicy: "network-only",
      // notifyOnNetworkStatusChange: true,
      pollInterval: 30000,
      variables: {
        uuid: uuid,
        sbuId: sbuId,
      },
      onCompleted: data => {
        // console.log(data)
        setMessageList(data.myConversation.conversationMessage)
        setInviteList(data.myConversation.invited)
        setParticipants(data.myConversation.participants)
        setConversationId(data.myConversation.uuid)
        setCategory(data.myConversation.category)
        setTopic(data.myConversation.topic)
        setArchived(data.myConversation.archived)
        let messageList = data.myConversation.conversationMessage
        let lastMessageIndex = messageList.length - 1
        let latestMessageUuid = messageList[lastMessageIndex].uuid
        dispatch({
          type: "SET_LATEST_MESSAGE_UUID",
          payload: latestMessageUuid,
        })
        console.log(latestMessageUuid)
      },
    }
  )

  function scrollMessagesToBottom() {
    if (messagesRef.current) {
      messagesRef.current._container.scrollTop =
        messagesRef.current._container.scrollHeight
    }
  }
  useEffect(() => {
    if (
      data &&
      data.myConversation &&
      data.myConversation.conversationMessage &&
      data.myConversation.conversationMessage.length > 1
    ) {
      scrollMessagesToBottom()
      console.log(messagesRef.current._container.scrollHeight)
      console.log(messagesRef.current)
    }
  }, [messageList])

  useEffect(() => {
    let ids = inviteList.map(a => a.id)
    setInvitedListIds(ids)
  }, [inviteList])

  useEffect(() => {
    if (participants) {
      let ids = participants.map(a => a.id)
      setParticipantIds(ids)
    }
  }, [participants])

  // useEffect(() => {
  //   if (conversationId.length > 5) updateConversation()
  // }, [invitedListIds])

  const handleBack = () => {
    dispatch({ type: "SET_SELECTED_CONVERSATION_ID", payload: "" })
    dispatch({ type: "SET_PANEL", payload: "conversationList" })
  }

  const handleDeleteInvite = invited => {
    console.log(invited)
    let filteredArray = inviteList.filter(x => x.id !== invited.id)
    setInviteList(filteredArray)
  }
  const ipadpro = useMediaQuery(
    "(min-width:1020px) and (max-width: 1060px) and (min-height:1250px)"
  )

  if (networkStatus === NetworkStatus.refetch) return <Loading />
  if (loading) return <Loading />
  if (error) return <Error />

  const myConversation = data.myConversation
  // const userConversation = data.userConversation

  if (!myConversation) {
    return null
  }
  return (
    <div className={ipadpro ? classes.ipad : classes.root}>
      <Grid
        container
        // direction="column"
        // justify="space-between"
        // alignItems="stretch"
      >
        <Grid item xs={12}>
          <Hidden mdUp>
            <Toolbar myConversation={myConversation} />
          </Hidden>
          <Divider />
        </Grid>

        {/* <Box
          p={2}
          display="flex"
          justifyContent="space-between"
          flexShrink={0}
          className={classes.invites}
        >
          <PerfectScrollbar>
            <Box display="flex" alignItems="flex-start">
              <Box mb={1} display="flex" justifyContent="flex-start"> */}
        <Grid item xs={12}>
          <Box className={classes.invited}>
            <Typography
              component="span"
              variant="subtitle2"
              color="textSecondary"
            >
              Viewing:
            </Typography>

            {inviteList
              .filter(x => !participantIds.includes(x.id))
              .map(invited => (
                <Box key={invited.id} ml={1} display="inline">
                  <Chip
                    label={`${invited.person && invited.person.firstName} ${
                      invited.person && invited.person.lastName
                    }`}
                    variant="outlined"
                    size="small"
                    // onDelete={() => handleDeleteInvite(invited)}
                    color="secondary"
                    // clickable
                  />
                </Box>
              ))}
          </Box>
          <PerfectScrollbar>
            <Box className={classes.invited}>
              <Typography
                component="span"
                variant="subtitle2"
                color="textSecondary"
                display="inline"
              >
                Participants:
              </Typography>
              {myConversation.participants.map(participant => (
                <Box key={participant.id} ml={1} display="inline">
                  <Chip
                    label={`${participant.person.firstName} ${participant.person.lastName}`}
                    // component={Link}
                    // to={`/manager/people/${participant.person.uid}`}
                    // state={{ uid: `${participant.person.uid}` }}
                    size="small"
                    // clickable
                    color="secondary"
                  />
                </Box>
              ))}
            </Box>
          </PerfectScrollbar>

          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Box
            className={ipadpro ? classes.ipadbox : classes.scrBox}
            display="flex"
            flexGrow={1}
            flexDirection="column"
            p={6}
            px={3}
            bgcolor="background.dark"
            ref={messagesRef}
            component={PerfectScrollbar}
            options={{ suppressScrollX: true }}
          >
            {messageList.length > 0 &&
              messageList.map(message => (
                <Message key={message.uuid} message={message} />
              ))}
          </Box>

          <Divider />

          <MessageAdd
            myConversation={myConversation}
            messageList={messageList}
            setMessageList={setMessageList}
          />
        </Grid>

        <ConversationPersonSearch
          inviteList={inviteList}
          setInviteList={setInviteList}
          addPeople={addPeople}
          setAddPeople={setAddPeople}
        />
      </Grid>
    </div>
  )
}

const GET_MY_CONVERSATION_DETAILS = gql`
  query ($uuid: String!, $sbuId: Int!) {
    myConversation(uuid: $uuid, sbuId: $sbuId) {
      uuid
      startedBy {
        id
        person {
          firstName
          lastName
        }
      }
      invited {
        id
        person {
          uid
          firstName
          lastName
        }
      }
      participants {
        id
        person {
          uid
          firstName
          lastName
        }
      }
      category {
        id
        name
      }
      sbu {
        id
        name
      }
      topic
      archived
      archivedDate
      startDate
      lastUpdate
      conversationMessage {
        uuid
        messageFile
        messageFrom {
          id
          person {
            uid
            firstName
            lastName
            profileImageUrl
          }
        }
        message
        sent
        removed
        removedDate
        messageFileUrl
      }
    }
  }
`

export default ConversationDetails
