import React, { useState, useContext } from "react"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Avatar from "@material-ui/core/Avatar"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"

import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/styles"
import { Link, navigate } from "gatsby"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"

import Loading from "../../../Shared/Loading"
import Error from "../../../Shared/Error"

import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"

import UserContext from "../../../../context/UserContext"

import { Alert, AlertTitle } from "@material-ui/lab"

import Box from "@material-ui/core/Box"

const UserChangePassword = ({ classes }) => {
  const { state, dispatch } = useContext(UserContext)
  const [password, setPassword] = useState("")
  const [oldPassword, setOldPassword] = useState("")
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [passwordSet, setPasswordSet] = useState(false)
  const [warning, setWarning] = useState("")
  const [myError, setMyError] = useState("")

  const [handleSubmit, { loading, error }] = useMutation(
    CHANGE_PASSWORD_MUTATION,
    {
      variables: {
        oldPassword: oldPassword,
        newPassword: password1,
      },
      onCompleted: data => {
        setOldPassword("")
        setPassword1("")
        setPassword2("")
        setPassword("")
        setPasswordSet(true)
      },
    }
  )

  const checkCharacters = () => {
    var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/

    if (password1.match(decimal)) {
      setPassword(password1)
      return true
    } else {
      setWarning(
        "Please choose a password of 8-15 characters in length that includes a number, a capitalized letter, a lowercase letter, and a symbol character like #$%^&*!."
      )
      return false
    }
  }

  const checkPassword = () => {
    if (password1 == "") {
      setWarning("Please enter a password")
      return false
    } else if (password2 == "") {
      setWarning("Please confirm password")
      return false
    } else if (password1 != password2) {
      setWarning("Passwords do not match. Please try again.")
      return false
    } else {
      setPassword(password1)
      return true
    }
  }

  const validate = () => {
    checkPassword() && checkCharacters() && handleSubmit()
  }

  return (
    <div className={classes.root}>
      <Card square className={classes.paper}>
        <CardContent>
          <Grid container direction="row" justify="space-between">
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
                Change Your Password
              </Typography>
            </Grid>
            <Divider />

            <Grid item container xs={12}>
              <Grid item container justify="center" xs={12} sm={2}>
                <Grid item>
                  <Box pt={3} pb={2}>
                    <Avatar className={classes.avatar}>
                      <LockOutlinedIcon />
                    </Avatar>
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <form
                  className={classes.form}
                  noValidate
                  onSubmit={event => {
                    event.preventDefault()
                    validate(event)
                    // handleChange()
                  }}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                    fullWidth
                    name="oldPassword"
                    label="Current Password"
                    type="password"
                    id="oldPassword"
                    // helperText=""
                    autoComplete="current-password"
                    value={oldPassword}
                    onChange={event => setOldPassword(event.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                    fullWidth
                    name="password2"
                    label="New Password"
                    type="password"
                    id="password1"
                    helperText="Please use a minimum of 8 characters including a number, a capitalized letter, a lowercase letter, and a symbol character like #$%^&*!."
                    autoComplete="current-password"
                    value={password1}
                    onChange={event => setPassword1(event.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                    fullWidth
                    name="password2"
                    label="Confirm New Password"
                    type="password"
                    id="password2"
                    autoComplete="current-password"
                    value={password2}
                    onChange={event => setPassword2(event.target.value)}
                  />
                  <Box mt={2}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={
                        loading ||
                        !oldPassword.trim() ||
                        !password1.trim() ||
                        !password2.trim()
                      }
                    >
                      {loading ? "Just a sec" : "Set New Password"}
                    </Button>
                  </Box>
                </form>
                {/* {error && <Error />} */}
                {warning.length > 3 && (
                  <Alert onClose={() => setWarning("")} severity="warning">
                    {warning}
                  </Alert>
                )}
                {error && (
                  // <Grid item>
                  <div className={classes.alert}>
                    <Alert variant="outlined" severity="warning">
                      {`${error.graphQLErrors[0].message}`}
                    </Alert>
                  </div>
                  // </Grid>
                )}
                {passwordSet && (
                  <>
                    <Alert severity="success">
                      <AlertTitle>Success!</AlertTitle>
                      Your password has changed.
                    </Alert>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
    maxWidth: 950,
    boxShadow: "none",
  },
  paper: {
    padding: theme.spacing(1),
    minHeight: 220,
  },
  title: {
    // fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    // margin: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
})

const CHANGE_PASSWORD_MUTATION = gql`
  mutation($oldPassword: String!, $newPassword: String!) {
    updateUserPassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      user {
        email
      }
    }
  }
`
export default withStyles(styles)(UserChangePassword)
