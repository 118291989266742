import React, { useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Hidden,
  IconButton,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  Menu as MenuIcon,
  Folder as FolderIcon,
  PlusCircle as PlusCircleIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
} from "react-feather"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import UserContext from "../../../context/UserContext"
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

function Header({ className, ...rest }) {
  const classes = useStyles()
  const { state, dispatch } = useContext(UserContext)
  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        {/* <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link variant="body1" color="inherit" to="/my" component={Link}>
            My Account
          </Link>

          <Typography variant="body1" color="textPrimary">
            Files
          </Typography>
        </Breadcrumbs> */}
        <SvgIcon fontSize="small" color="secondary" className={classes.icon}>
          <FolderIcon />
        </SvgIcon>
        <Typography variant="h5" color="textPrimary" display="inline">
          Files
        </Typography>
        {/* <Box mt={2}>
          <Button className={classes.action}>
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <UploadIcon />
            </SvgIcon>
            Import
          </Button>
          <Button className={classes.action}>
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <DownloadIcon />
            </SvgIcon>
            Export
          </Button>
        </Box> */}
      </Grid>
      <Hidden mdUp>
        <Grid item>
          <IconButton onClick={() => dispatch({ type: "TOGGLE_MOBILE_NAV" })}>
            <SvgIcon
              fontSize="small"
              color="secondary"
              className={classes.icon}
            >
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Grid>
      </Hidden>
      {/* <Grid item>
        <Button
          color="secondary"
          variant="contained"
          className={classes.action}
          size="small"
          //   component={Link}
          //   to={"/manager/orders/new"}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <PlusCircleIcon />
          </SvgIcon>
          Test Sample Proof
        </Button>
      </Grid>
      <Grid item>
        <Button
          color="secondary"
          variant="contained"
          className={classes.action}
          size="small"
          //   component={Link}
          //   to={"/manager/orders/new"}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <PlusCircleIcon />
          </SvgIcon>
          Upload a File
        </Button>
      </Grid> */}
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
