import React, { useState } from "react"
import { Link } from "gatsby"
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import withStyles from "@material-ui/core/styles/withStyles"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import MessageIcon from "@material-ui/icons/Message"
import ForwardIcon from "@material-ui/icons/Forward"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"

const Discrepancy = ({ classes, sampleProof }) => {
  //   const { data, loading, error } = useQuery(DISCREPANCY_QUERY, {
  //     variables: {
  //       id: sampleProof.id,
  //     },
  //   })
  //   if (loading) return "loading"
  //   if (error) return "error"
  const commentImages = sampleProof.sampleCommentImage
  return (
    <div className={classes.root}>
      <Container>
        <Typography variant="h6">{sampleProof.item.name}</Typography>
        <Typography>
          Item: {sampleProof.item.id} - Version: {sampleProof.version}
        </Typography>
        <Box mt={2} mb={2}>
          <Alert severity="info" variant="outlined">
            <AlertTitle>Changes Requested</AlertTitle>
            You reported an issue with this file that needs to be resolved. Your
            issue has been submitted and we have received your response on our
            end.
          </Alert>
        </Box>
        <Typography gutterBottom>
          <ul>
            <li>
              If you recently submitted your request for changes, please wait
              while we prepare a response
            </li>
            <li>
              If you have thought of additional changes and you have not yet
              received a response from us, please{" "}
              <Link className={classes.link} to="/my/service">
                send a message
              </Link>
            </li>
            <li>
              If more than three days (excluding weekends) have passed and you
              have not received a response, please{" "}
              <Link className={classes.link} to="/my/service">
                send a message
              </Link>
              .
            </li>
          </ul>
        </Typography>
        {commentImages && (
          <>
            <Typography variant="h6">Your Comments</Typography>
            {commentImages.map(commentImg => (
              <Box key={commentImg.id}>
                <img
                  src={commentImg.commentImageUrl}
                  alt={commentImg.commentImage}
                />
                {commentImg.sampleProofImageComment &&
                  commentImg.sampleProofImageComment.map(comment => (
                    <Card className={classes.card} key={comment.id}>
                      <CardHeader
                        avatar={
                          <Avatar
                            aria-label="comment type"
                            style={{
                              color: "white",
                              backgroundColor: comment.commentColor,
                            }}
                          >
                            {comment.commentTool === "circle" && (
                              <RadioButtonUncheckedIcon />
                            )}
                            {comment.commentTool === "arrow" && <ForwardIcon />}
                            {comment.commentTool === "none" && <MessageIcon />}
                          </Avatar>
                        }
                      />{" "}
                      <CardContent>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {comment.comment}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
              </Box>
            ))}
          </>
        )}
        <Typography variant="h6">Original File</Typography>
        <img src={sampleProof.sampleProofUrl} alt={sampleProof.image} />
      </Container>
    </div>
  )
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  link: {
    color: theme.palette.link,
    textDecoration: "none",
  },
  message: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  card: {
    width: "100%",
    margin: theme.spacing(2, 0, 2, 0),
  },
})
export default withStyles(styles)(Discrepancy)
