import React, { useRef, useContext } from "react"

import { Box, Grid, SvgIcon, Typography, makeStyles } from "@material-ui/core"

import Sidebar from "./Sidebar"
import ConversationList from "./ConversationList"
import ConversationDetails from "./ConversationDetails"
import InviteList from "./InvitesList"
import ConversationNew from "./ConversationNew"
import MessagesContext from "./context/MessagesContext"
// import Page from "./Page"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0.8),

    height: "91vh",
    display: "flex",
    // width: "100%",
    overflow: "hidden",
    overflowY: "hidden",
    overflowX: "hidden",

    // position: "relative",
  },
  panel: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
  },
}))

const UserMessages = () => {
  const classes = useStyles()
  const { state } = useContext(MessagesContext)
  const panel = state.panel ? state.panel : "conversationList"
  // const panel = "conversationList"
  const pageRef = useRef(null)

  const panelSwitch = panel => {
    switch (panel) {
      case "conversationList":
        return <ConversationList />
      case "newInvites":
        return <InviteList />
      case "conversationSelected":
        return <ConversationDetails />
      case "conversationNew":
        return <ConversationNew />
      default:
        return "conversationList"
    }
  }

  return (
    <>
      <Box className={classes.root} title="Service" ref={pageRef}>
        <Sidebar containerRef={pageRef} />
        {/* <Box mt={3}>{state.panel}</Box> */}
        {panelSwitch(panel)}
      </Box>
    </>
  )
}

export default UserMessages
