import React, { useState } from "react"
import { Box, Container, Grid, makeStyles } from "@material-ui/core"
import { useMatch } from "@reach/router"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import Loading from "../../../Shared/Loading"
import Error from "../../../Shared/Error"
import Header from "./Header"
import CheckoutForm from "./CheckoutForm"
import CheckoutProvider from "./context/CheckoutProvider"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: 100,
  },
}))

const Checkout = ({ checkout, setCheckout }) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(1)

  // const match = useMatch("/my/orders/checkout/:orderId")
  // const orderId = parseInt(match.orderId)

  // const { loading, error, data } = useQuery(ORDER_INFO_QUERY, {
  //   variables: {
  //     id: orderId,
  //   },
  // })

  // if (loading) return <Loading />
  // if ({ data } == null) return <Error />
  // if (error) return <Error />
  // const order = data.order
  return (
    <div className={classes.root}>
      <CheckoutProvider>
        <Container maxWidth={false}>
          <Header />

          <Box mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CheckoutForm
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  checkout={checkout}
                  setCheckout={setCheckout}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </CheckoutProvider>
    </div>
  )
}

// const ORDER_INFO_QUERY = gql`
//   query($id: Int!) {
//     order(id: $id) {
//       id
//       status {
//         id
//         orderStatus
//       }
//       orderDate
//       completionDate

//       orderPayment {
//         id
//         totalPaid
//         paidBy {
//           uid
//           firstName
//           lastName
//         }
//         billingName
//         paymentDate
//         method {
//           id
//           name
//         }
//         code
//         pmtException {
//           id
//           name
//         }
//         taxPaid
//         shippingPaid
//         totalPaid
//         pmtStatus {
//           id
//           orderStatus
//         }
//         billingAddress {
//           id
//           name
//           address {
//             uid
//             region {
//               id
//               name
//             }
//             attention
//             addressLine1
//             addressLine2
//             addressLine3
//             zone
//             city
//             postalCode
//           }
//         }
//       }
//       orderWithItem {
//         id
//         order {
//           id
//         }
//         qty
//         priceEach
//         itemSubtotal
//         taxAmt
//         discountAmt

//         item {
//           id
//           name
//           itemPrice {
//             id
//             minQuantity
//             maxQuantity
//             price
//           }
//           pricingModel {
//             id
//             name
//           }
//           category {
//             name
//           }
//         }
//       }
//       businessUnit {
//         id
//         name
//       }

//       taxAmt
//       discountAmt
//       postageAmt
//       balanceDue
//       balanceDueDate
//       notes
//       fromCart
//       cartId
//       customer {
//         uid
//         firstName
//         lastName
//         emailPerson {
//           id
//           emailAddress
//         }
//         phonePerson {
//           id
//           phoneType {
//             id
//             phoneType
//           }
//           phoneNumber
//         }
//         addressPerson {
//           id
//           address {
//             uid
//             summary
//           }
//         }
//         user {
//           id
//           email
//           isVerified
//           active
//           lastLogin
//         }
//       }
//     }
//   }
// `
export default Checkout
