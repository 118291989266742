import React, { useState, useContext } from "react"
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import SortContext from "../../../context/SortContext"
import { useStaticQuery } from "gatsby"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import Loading from "../../Shared/Loading"
import Error from "../../Shared/Error"
import UserItemList from "./UserItemList"
// import UserItemDetails from "./UserItemDetails"
// import NoPrivateItems from "./NoPrivateItems"
// import ItemInquiryForm from "./ItemInquiryForm"
import Header from "./Header"
import SignBackIn from "../../SignBackIn"
import { useSiteMetadata } from "../../utils/hooks/useSiteMetadata"

const UserItems = ({ classes }) => {
  const { state, dispatch } = useContext(SortContext)
  const { sbuId } = useSiteMetadata()
  const page = state.page
  const pageSize = state.pageSize
  const searchContext = state.search
  const [currentTab, setCurrentTab] = useState("myItems")
  const [myPrivateItems, setMyPrivateItems] = useState("")
  const [myOrderItems, setMyOrderItems] = useState("")

  const { data, loading, error } = useQuery(MY_ITEMS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      page: page,
      pageSize: pageSize,
      search: searchContext,
      businessUnitId: sbuId,
    },
    onError: error => {
      dispatch({ type: "SET_LOGOUT" })
      return <SignBackIn />
    },
    onCompleted: data => {
      setMyPrivateItems(data.myPrivateItems)
      setMyOrderItems(data.myOrderItems)
      console.log(sbuId)
    },
  })
  const tabs = [
    { value: "myItems", label: "My Items" },
    { value: "suggested", label: "Suggested" },
    { value: "quote", label: "New Quote" },
  ]
  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }
  if (loading) return <Loading />
  // if (error) return <Error />

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Header />
          <Box mt={2}>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              textColor="secondary"
              className={classes.tabs}
            >
              {tabs.map(tab => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box mt={3}>
            {currentTab === "myItems" && (
              <UserItemList
                setCurrentTab={setCurrentTab}
                myPrivateItems={myPrivateItems}
                setMyPrivateItems={setMyPrivateItems}
                myOrderItems={myOrderItems}
                setMyOrderItems={setMyOrderItems}
              />
            )}
            {currentTab === "suggested" && "suggested"}
            {currentTab === "quote" && "quote"}
          </Box>
        </Container>
      </div>
    </>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: 1,
    width: "100%",
  },

  container: {
    display: "grid",
  },
})
const MY_ITEMS_QUERY = gql`
  query ($page: Int, $pageSize: Int, $search: String, $businessUnitId: Int) {
    myPrivateItems(
      page: $page
      pageSize: $pageSize
      search: $search
      businessUnitId: $businessUnitId
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        item {
          id
          name
          category {
            id
            name
          }
          isPrivate
          dateAdded

          featuredImageCarouselImageUrl
          dateModified
          isActive
          pricingModel {
            id
            name
          }
        }
      }
    }
    myOrderItems(
      page: $page
      pageSize: $pageSize
      search: $search
      businessUnitId: $businessUnitId
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        customFields
        order {
          id
        }
        item {
          id
          name
          category {
            id
            name
          }
          businessUnit {
            id
            name
          }
          isActive
          isPrivate
          pricingModel {
            id
            description
          }

          featuredImageCarouselImageUrl
          specItem {
            specId
            summary
          }
        }
      }
    }
  }
`
export default withStyles(styles)(UserItems)
